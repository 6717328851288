import React from "react"
import moment from "moment"
import { connect } from "react-redux"

import webConfig from "@src/agreement/web"
import { getComponents } from "./components"
import {
  saveSelectedTrack,
  storeConcurrentEnrollmentOption,
  storeEnrollmentAgreementSignature,
} from "@src/actions"
import { initials } from "./initials"
import { determineEndDate } from "@components/applicationForm/trackSelection/trackOptions"

const Agreement = getComponents(webConfig)

class EnrollmentAgreement extends React.Component {
  requestSent = false

  constructor(props) {
    super(props)

    this.state = {
      initials: props.initials,
    }
  }

  storeSignature = async () =>
    this.props
      .storeConcurrentEnrollmentOption(true)
      .then(() => this.props.storeEnrollmentAgreementSignature())

  fillSelectedDates = () => {
    const { selectedTrack } = this.props.applicant
    const { selectedFormat } = selectedTrack
    const endDate = determineEndDate(selectedTrack)
    const format =
      selectedFormat.title && selectedFormat.title.includes("Full-Time")
        ? "Full-Time"
        : "Part-Time"

    if (format == "Part-Time") {
      return this.props.saveSelectedTrack(
        {
          ...selectedTrack,
          selectedFormat: {
            ...selectedFormat,
            selectedStartDate: moment(),
          },
        },
        endDate
      )
    } else {
      return this.props.saveSelectedTrack(selectedTrack, endDate)
    }
  }

  onSign = async () => {
    const { timestamp } = this.props.applicant.enrollmentAgreementSignature

    if (!this.requestSent && !timestamp) {
      this.requestSent = true

      this.fillSelectedDates().then(() => this.storeSignature())
    }
  }

  onInitialsSign = (label) => {
    this.setState({
      initials: {
        ...this.state.initials,
        [`${label}`]: true,
      },
    })
  }

  render() {
    return (
      <div className="enrollment-agreement-doc">
        <Agreement
          applicant={this.props.applicant}
          initials={this.state.initials}
          onInitialsSign={this.onInitialsSign}
          onSign={this.onSign}
          partner={this.props.partner}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const initialsMap = initials(
    state.applicant.applicant.enrollmentAgreementSignature.timestamp
      ? true
      : false
  )

  return {
    applicant: state.applicant.applicant,
    initials: initialsMap,
    partner: state.partner,
  }
}

export default connect(mapStateToProps, {
  saveSelectedTrack,
  storeConcurrentEnrollmentOption,
  storeEnrollmentAgreementSignature,
})(EnrollmentAgreement)
