import React, { Component } from "react"

import { SelectedTrackOption, TrackOption } from "./trackOptions"
import { setSelectedTrack } from "@src/actions"
import Track from "./track"

interface TracksProps {
  className: string
  selectedTrack: SelectedTrackOption
  tracks: TrackOption[]
  setSelectedTrack: typeof setSelectedTrack
}

class Tracks extends Component<TracksProps> {
  render() {
    const { className, selectedTrack, tracks } = this.props
    const selectedIdx = tracks
      .map((t) => t.trackKey)
      .indexOf(selectedTrack.trackKey)

    return (
      <div className={`${className} tracks`}>
        <div className="tracks__tracks">
          {tracks.map((track, idx) => (
            <Track
              key={track.trackKey}
              selected={idx === selectedIdx}
              setSelectedTrack={this.props.setSelectedTrack}
              track={track}
            />
          ))}
        </div>
        <div className={`tracks__line tracks__pos-${selectedIdx + 1}`} />
      </div>
    )
  }
}

export default Tracks
