import React from "react"

import { SelectedTrackOption } from "@components/applicationForm/trackSelection/trackOptions"
import { setSelectedTrackFormat, setSelectedTrackStartDate } from "@src/actions"
import TrackSelectedOptions from "./trackSelectedOptions"

const TitleWithLogo = ({ title, subtitle, logoUrl }) => (
  <div className="track-selected__title-with-logo title-with-logo">
    <img className="title-with-logo__logo" src={`assets${logoUrl}`} />
    <div className="title-with-logo__title">{title}</div>
    <div className="title-with-logo__subtitle">{subtitle}</div>
  </div>
)

const TrackDetails = ({
  preRequisites,
  recommendedCredits,
  description,
  selectedFormat,
}) => (
  <div className="track-selected__track-details track-details">
    <p className="track-details__pre-requisites">
      Pre Requisites:{" "}
      {(selectedFormat && selectedFormat.preRequisites) || preRequisites}
    </p>
    <p className="track-details__recommended-credits">
      Recommended Credits: {recommendedCredits}
    </p>
    {selectedFormat && selectedFormat.price && (
      <p className="track-details__price">
        Price: ${selectedFormat && selectedFormat.price.toLocaleString()}
      </p>
    )}
    <p className="track-details__description">{description}</p>
  </div>
)

interface TrackSelectedProps {
  className: string
  selectedTrack: SelectedTrackOption
  setSelectedTrackFormat: typeof setSelectedTrackFormat
  setSelectedTrackStartDate: typeof setSelectedTrackStartDate
  trackStartDateRequired: boolean
}

const TrackSelected = (props: TrackSelectedProps) => (
  <div className={`${props.className} track-selected`}>
    <div className="track-selected__track-information">
      <TitleWithLogo {...props.selectedTrack} />
      <TrackDetails {...props.selectedTrack} />
    </div>
    <TrackSelectedOptions
      className="track-selected__track-selected-options"
      selectedTrack={props.selectedTrack}
      setSelectedTrackFormat={props.setSelectedTrackFormat}
      setSelectedTrackStartDate={props.setSelectedTrackStartDate}
      trackStartDateRequired={props.trackStartDateRequired}
    />
  </div>
)

export default TrackSelected
