import { reduxForm, Field, InjectedFormProps } from "redux-form"
import React from "react"

import DocumentCollectionCard from "./documentCollectionCard"
import {
  FormDrop,
  FormInput,
  FormFileInput,
  FormButton,
} from "@components/formFields"
import withTopView from "@components/topView"
import {
  InputFile,
  isInputFilePresent,
} from "@components/formFields/formFileInput/formFileInput"

export interface MexicoDocumentCollectionValues {
  rfc?: string | null
  nss?: string | null
  curp?: string | null
  cityOfBirth: string
  countryOfBirth: string
  photoId: InputFile
  officialIDType: string
  officialIDNumber: string
  proofOfResidency: InputFile
}

export type MexicoDocumentCollectionFormValues = Partial<MexicoDocumentCollectionValues>

interface MexicoDocumentCollectionFormProps {
  initialValues?: MexicoDocumentCollectionFormValues
  onSubmit: (data: MexicoDocumentCollectionValues) => void
}

type MexicoDocumentCollectionFormErrors = {
  [key in keyof MexicoDocumentCollectionFormValues]?: string
}

const officialIDTypes = ["Credencial para votar vigente (INE)", "Pasaporte"]

const MexicoDocumentCollectionForm = (
  props: MexicoDocumentCollectionFormProps &
    InjectedFormProps<
      MexicoDocumentCollectionValues,
      MexicoDocumentCollectionFormProps
    >
) => (
  <form onSubmit={props.handleSubmit} className="document-collection-form">
    <DocumentCollectionCard className="document-collection-form__card">
      <Field
        className="document-collection-form__rfc"
        name="rfc"
        type="text"
        title="Registro Federal De Causante (Responsible Federal Register) - RFC"
        placeholder="for example: VECJ880326 XXX"
        component={FormInput}
      />

      <Field
        className="document-collection-form__curp"
        name="curp"
        type="text"
        title="Clave Única de Registro de Población (Unique Population Registry Code) - CURP"
        placeholder="for example: FAMR750115HDFBNC09"
        component={FormInput}
        popup={{
          width: 200,
          content: (
            <div className="">
              Si no tiene un número de RFC o CURP, haga clic en este enlace para
              consulta de CURP:{" "}
              <a
                href="https://consultas.curp.gob.mx/CurpSP/gobmx/inicio.jsp"
                target="_blank"
              >
                https://consultas.curp.gob.mx/CurpSP/gobmx/inicio.jsp
              </a>
              .
            </div>
          ),
        }}
      />

      <Field
        className="document-collection-form__nss"
        name="nss"
        type="text"
        title="Numero de Seguridad Social (Social Security Number) - NSS"
        placeholder="for example: 12345678901"
        component={FormInput}
      />

      <Field
        className="document-collection-form__country_of_birth"
        name="countryOfBirth"
        type="text"
        title="Country of Birth"
        component={FormInput}
      />

      <Field
        className="document-collection-form__city_of_birth"
        name="cityOfBirth"
        type="text"
        title="City of Birth"
        component={FormInput}
      />

      <Field
        className="document-collection-form__photo_id"
        name="photoId"
        title="Identificación oficial (Photo ID), e.g. INE, Pasaporte"
        component={FormFileInput}
      />

      <Field
        className="document-collection-form__official_id_type"
        component={FormDrop}
        data={officialIDTypes}
        name="officialIDType"
        placeholder="select one"
        title="Type of Identificación oficial"
      />

      <Field
        className="document-collection-form__official_id_number"
        name="officialIDNumber"
        type="text"
        title="Number of Identificación oficial"
        placeholder="for example: ABCDEF12345678G123 (INE) or A12345678 (passport)"
        component={FormInput}
      />

      <Field
        className="document-collection-form__proof_of_residency"
        name="proofOfResidency"
        title="Comprobante de Domicilio (proof of residency), e.g. Recibo de luz (light bill), Recibo Telefonico (phone bill), Recibo de Agua (water bill)"
        component={FormFileInput}
      />
    </DocumentCollectionCard>

    <div className="document-collection-form__buttons">
      <Field
        className="document-collection-form__continue"
        title="Continue"
        type="submit"
        name="continue"
        component={FormButton}
      />
    </div>
  </form>
)

const RFC_REGEX = /^([a-z]{4})([^a-z0-9]*)(\d{6})([^a-z0-9]*)([a-z0-9]{3})$/i
const INE_REGEX = /^([a-z]{6})(\d{8})([a-z])(\d{3})$/i
const PASSPORT_REGEX = /^([a-z])(\d{8})$/i
const CURP_REGEX = /^[A-Z][A,E,I,O,U,X][A-Z]{2}([0-9]{2})([0-1][0-9])([0-3][0-9])[M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]$/

const validate = (
  values: MexicoDocumentCollectionFormValues
): MexicoDocumentCollectionFormErrors => {
  const errors: MexicoDocumentCollectionFormErrors = {}

  if (!values.cityOfBirth) {
    errors.cityOfBirth = "*"
  }

  if (!values.countryOfBirth) {
    errors.countryOfBirth = "*"
  }

  if (values.rfc && !RFC_REGEX.test(values.rfc)) {
    errors.rfc = "must match format: ABCD-123456-A1Z"
  }

  if (values.nss && values.nss.length !== 11) {
    errors.nss = "must be 11 digits"
  }

  if (values.curp && !CURP_REGEX.test(values.curp)) {
    errors.curp = "must match format: FAMR750115HDFBNC09"
  }

  if (!values.rfc && !values.nss && !values.curp) {
    errors.nss = errors.rfc = errors.curp = "RFC or NSS or CURP required"
  }

  if (!values.officialIDType) {
    errors.officialIDType = "*"
  } else if (!officialIDTypes.includes(values.officialIDType)) {
    errors.officialIDType = "must be a known type"
  }

  if (!values.officialIDNumber) {
    errors.officialIDNumber = "*"
  } else {
    if (
      values.officialIDType === "Credencial para votar vigente (INE)" &&
      !INE_REGEX.test(values.officialIDNumber)
    ) {
      errors.officialIDNumber = "must match format: ABCDEF12345678G123"
    }

    if (
      values.officialIDType === "Pasaporte" &&
      !PASSPORT_REGEX.test(values.officialIDNumber)
    ) {
      errors.officialIDNumber = "must match format: A12345678"
    }
  }

  if (!values.photoId || !isInputFilePresent(values.photoId)) {
    errors.photoId = "*"
  }

  if (
    !values.proofOfResidency ||
    !isInputFilePresent(values.proofOfResidency)
  ) {
    errors.proofOfResidency = "*"
  }

  return errors
}

const WrappedForm = reduxForm<
  MexicoDocumentCollectionValues,
  MexicoDocumentCollectionFormProps
>({
  form: "MexicoDocumentCollectionForm",
  validate,
})(withTopView(MexicoDocumentCollectionForm))
;(WrappedForm as any).displayName = `reduxForm(${MexicoDocumentCollectionForm.name})`

export { WrappedForm as MexicoDocumentCollectionForm, validate }
