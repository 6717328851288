import React, { Component } from "react"

export const calendlyTestId = "calendly-container"

class Calendly extends Component {
  render() {
    const { className, calendlyLink } = this.props
    return [
      <script
        key="1"
        type="text/javascript"
        src="https://assets.calendly.com/assets/external/widget.js"
      />,
      <div
        key="2"
        className={`calendly calendly-inline-widget ${className}`}
        data-testid={calendlyTestId}
        data-url={calendlyLink}
      />,
    ]
  }

  componentDidMount() {
    const script = document.createElement("script")
    script.src = "https://assets.calendly.com/assets/external/widget.js"
    script.async = true
    document.body.appendChild(script)
  }
}

export default Calendly
