import React, { Fragment } from "react"
import moment from "moment"

import {
  homemadeApple,
  mkDocument,
  mkInfo,
  mkSignature,
  nunitoBold,
  nunitoItalic,
} from "@src/agreement/common"
import { DateFormat } from "@src/helpers"

import { Applicant } from "@common/applicant"
import { Partner } from "@common/partner"
import { PartnerSite } from "@common/partnerSite"

import {
  CheckoutableFormatTitle,
  FormatTitle,
  Pace,
  SelectedTrackOption,
  isCheckoutableTrackFormat,
  determineEndDate,
} from "@components/applicationForm/trackSelection/trackOptions"

export function getComponents({
  Text,
  Br,
  View,
  BasePage,
  BaseDocument,
  PageNumber,
  Image,
  imagePath,
  baseSpacing,
  fontSize,
}) {
  const Document = mkDocument({ BaseDocument })
  const Info = mkInfo({ Text, View })
  const Page = ({
    children,
    page,
    style,
  }: {
    children: JSX.Element | JSX.Element[]
    page?: number
    style?: {}
  }) => (
    <BasePage
      size="A4"
      style={{
        boxSizing: "border-box",
        color: "black",
        display: "flex",
        flexDirection: "column",
        fontFamily: "Nunito Sans",
        fontSize: "10.5pt",
        ...style,
      }}
    >
      <View style={{ width: "100%" }}>
        <Image src={imagePath("header.png")} style={{ width: "100%" }} />
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          padding: "45pt",
          paddingTop: "10pt",
          paddingBottom: "10pt",
        }}
      >
        {children}
      </View>
      <View style={{ width: "100%" }}>
        <PageNumber
          style={{
            ...nunitoBold,
            bottom: "45pt",
            color: "#808080",
            fontSize: "14pt",
            position: "absolute",
            right: "45pt",
          }}
        >
          {page}
        </PageNumber>
        <Image src={imagePath("footer.png")} style={{ width: "100%" }} />
      </View>
    </BasePage>
  )
  const Signature = mkSignature({ Info, Text, View })

  const styles = {
    list: {
      wrapper: {
        display: "flex",
        flexDirection: "row",
        fontSize: fontSize,
      },
      punctation: {
        flexGrow: 0,
        flexShrink: 0,
        marginRight: 5,
      },
    },
    table: {
      wrapper: {
        padding: `${baseSpacing}pt`,
        paddingTop: 0,
      },
      content: {
        borderLeftStyle: "solid",
        borderTopStyle: "solid",
        borderColor: "black",
        borderLeftWidth: "0.8pt",
        borderTopWidth: "0.8pt",
        marginTop: `${baseSpacing * 0.5}pt`,
      },
      row: {
        display: "flex",
        flexDirection: "row",
        minHeight: "15pt",
      },
      smallRow: {
        minHeight: "7pt",
      },
      columnWrapper: {
        borderColor: "black",
        borderBottomStyle: "solid",
        borderRightStyle: "solid",
        borderBottomWidth: "0.8pt",
        borderRightWidth: "0.8pt",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      column: {
        flexDirection: "column",
        padding: "5pt",
      },
      smallColumn: {
        padding: "2pt",
      },
    },
    textSmall: {
      fontSize: "9pt",
    },
    margins: {
      "1/1": {
        margin: `${baseSpacing}pt`,
      },
      vertical: {
        "1/2": {
          marginTop: `${baseSpacing / 2}pt`,
          marginBottom: `${baseSpacing / 2}pt`,
        },
        "1/1": {
          marginTop: `${baseSpacing}pt`,
          marginBottom: `${baseSpacing}pt`,
        },
      },
      top: {
        "1/2": {
          marginTop: `${baseSpacing / 2}pt`,
        },
        "2/1": {
          marginTop: `${baseSpacing * 2}pt`,
        },
      },
      bottom: {
        marginBottom: `${baseSpacing}pt`,
      },
    },
  }

  const H = ({ children, style }: { children: JSX.Element; style: {} }) => (
    <View style={style}>
      <Text
        style={{
          marginBottom: "4.5pt",
          ...nunitoBold,
        }}
      >
        {children}
      </Text>
    </View>
  )
  const H1 = (p) => <H style={{ ...p.style, fontSize: "24pt" }}>{p.children}</H>
  const H2 = (p) => <H style={{ ...p.style, fontSize: "18pt" }}>{p.children}</H>
  const H3 = (p) => <H style={{ ...p.style, fontSize: "15pt" }}>{p.children}</H>
  const SectionHeader = ({
    children,
    style,
  }: {
    children: string
    style?: {}
  }) => (
    <H2 style={{ ...styles.margins.vertical["1/1"], ...style }}>{children}</H2>
  )
  const Header = ({ children }: { children: string }) => (
    <H3 style={styles.margins.vertical["1/2"]}>{children}</H3>
  )

  const List = ({
    children,
    startAfter,
    style,
    toIndex,
  }: {
    children: JSX.Element[]
    startAfter?: number
    style?: {}
    toIndex: (index: number) => string
  }) => (
    <View style={styles.margins["1/1"]}>
      {React.Children.map(children, (child, index) => (
        <View style={styles.list.wrapper}>
          <Text
            style={{
              ...styles.list.punctation,
              ...style,
            }}
          >
            {toIndex((startAfter || 0) + index + 1)}
          </Text>
          <View style={style}>{child}</View>
        </View>
      ))}
    </View>
  )

  const ListItem = (props) => <View style={props.style}>{props.children}</View>

  const IndexedList = ({
    children,
    startAfter,
    style,
  }: {
    children: JSX.Element[]
    startAfter?: number
    style?: {}
  }) => (
    <List
      children={children}
      startAfter={startAfter}
      style={style}
      toIndex={(idx) => `${idx}.`}
    />
  )

  const UnsortedList = ({
    children,
    startAfter,
    style,
  }: {
    children: JSX.Element[]
    startAfter?: number
    style?: {}
  }) => (
    <List
      children={children}
      startAfter={startAfter}
      style={style}
      toIndex={() => "\u2022"}
    />
  )

  const Paragraph = (props) => (
    <View
      style={{
        fontSize: fontSize,
        ...styles.margins.bottom,
        ...props.style,
      }}
    >
      <Text>{props.children}</Text>
    </View>
  )

  const Table = (props) => (
    <View style={styles.table.wrapper}>
      {props.title && (
        <View>
          <Strong>{props.title}</Strong>
        </View>
      )}
      {props.description && (
        <View>
          <Text>{props.description}</Text>
        </View>
      )}
      <View style={styles.table.content}>{props.children}</View>
    </View>
  )

  const Row = (props) => (
    <View
      style={{
        ...styles.table.row,
        ...(props.small ? styles.table.smallRow : {}),
        ...props.style,
      }}
    >
      {React.Children.map(props.children, (child) => (
        <View
          style={{
            ...styles.table.columnWrapper,
            width: `${100.0 / React.Children.count(props.children)}%`,
          }}
        >
          <View
            style={{
              ...styles.table.column,
              ...(props.small ? styles.table.smallColumn : {}),
            }}
          >
            {child}
          </View>
        </View>
      ))}
    </View>
  )

  const Strong = (props) => <Text style={nunitoBold}>{props.children}</Text>

  const determineSelection = (
    track: SelectedTrackOption,
    format: FormatTitle | CheckoutableFormatTitle,
    certification: string
  ) => {
    const formatMatches = track.selectedFormat.title == format
    const certificationMatches = track.certification == certification

    return formatMatches && certificationMatches
  }

  const determineStartDate = ({ selectedFormat }: SelectedTrackOption) => {
    if (selectedFormat.selectedStartDate != null) {
      return moment(selectedFormat.selectedStartDate).format(DateFormat)
    } else if (selectedFormat.pace == Pace.partTime) {
      return moment().format(DateFormat)
    }
  }

  const determineTuition = (track: SelectedTrackOption) => {
    if (isCheckoutableTrackFormat(track.selectedFormat)) {
      return `$${track.selectedFormat.price}`
    } else {
      return track.selectedFormat.title.includes("Full-Time")
        ? "$12,000"
        : "$7,500"
    }
  }

  const DocAcceptance = ({
    applicant,
    initials,
    onSign,
  }: {
    applicant: Applicant
    initials: any
    onSign: any
  }) => {
    const { firstName, lastName } = applicant.applicantInfo
    const signed = applicant.enrollmentAgreementSignature.timestamp !== null
    const date =
      signed &&
      moment.utc(
        applicant.enrollmentAgreementSignature.timestamp!,
        "YYYY-MM-DD HH:mm:ss"
      )
    const dateSigned = date && date.format(DateFormat)

    return (
      <View>
        <SectionHeader>Acceptance</SectionHeader>
        <EnsureAllInitialsSigned initials={initials}>
          <Paragraph>
            <Strong>STUDENT</Strong>
          </Paragraph>
          <Info label="Printed Name:" value={`${firstName} ${lastName}`} />
          <Signature
            label={"Signature:"}
            value={signed ? `${firstName} ${lastName}` : ""}
            onClick={onSign}
          />
          <Info label="Date Signed:" value={dateSigned} />

          <Paragraph>
            <Strong>BOTTEGA UNIVERSITY REPRESENTATIVES</Strong>
          </Paragraph>
          <View>
            <Info
              label="Printed Name of Adminissions Representative"
              value={
                applicant.enrollmentAdvisor &&
                applicant.enrollmentAdvisor.full_name
              }
            />
            <Info label="Printed Name:" value="Fili Ledezma/CEO" />
            <Signature label="Signature:" value="Fili Ledezma" />
            <Info label="Date Signed:" value={dateSigned} />
          </View>
          <View style={styles.margins.top["2/1"]}>
            <Info
              label="Printed Name:"
              value="Dr. Mary Beth Finn, CAO, Bottega, LLC; President, Bottega University"
            />
            <Signature label="Signature:" value="Dr. Mary Beth Finn" />
            <Info label="Date Signed:" value={dateSigned} />
          </View>
        </EnsureAllInitialsSigned>
      </View>
    )
  }

  const EnsureAllInitialsSigned = ({ children, initials }) => {
    const missingInitials = Object.keys(initials).filter(
      (label) => !initials[label]
    )

    if (missingInitials.length > 0) {
      return (
        <View style={styles.margins.bottom}>
          <Paragraph>
            Make sure to acknowledge the following sections before signing the
            agreement:
          </Paragraph>
          <UnsortedList>
            {missingInitials.map((label) => (
              <ListItem key={label}>{label}</ListItem>
            ))}
          </UnsortedList>
        </View>
      )
    } else {
      return <View>{children}</View>
    }
  }

  const InitialsMark = ({
    applicant,
    children,
    initials,
    label,
    onInitialsSign,
  }) => {
    const resetFontModifiers = {
      fontWeight: "normal",
      fontStyle: "normal",
    }

    if (initials[label] === true) {
      const { firstName, lastName } = applicant.applicantInfo
      const initials = firstName[0] + lastName[0]

      return (
        <View style={{ position: "relative" }}>
          {children}
          <View
            style={{
              position: "absolute",
              right: `-25pt`,
              bottom: "0pt",
            }}
          >
            <Text
              style={{
                ...resetFontModifiers,
                ...homemadeApple,
              }}
            >
              {initials}
            </Text>
          </View>
        </View>
      )
    } else if (initials[label] === false) {
      return (
        <View>
          {children}
          <View
            style={{
              textAlign: "right",
              marginRight: `-${baseSpacing}pt`,
            }}
          >
            <Text
              onClick={() => onInitialsSign(label)}
              style={{
                ...styles.textSmall,
                ...resetFontModifiers,
                cursor: "pointer",
                color: "#00CB79",
                ...nunitoBold,
              }}
            >
              click here to acknowledge
            </Text>
          </View>
        </View>
      )
    } else {
      return children
    }
  }

  const StudentInformation = ({
    email,
    info,
    site,
    partner,
  }: {
    email: string
    info: Applicant["applicantInfo"]
    site: PartnerSite
    partner: Partner
  }) => {
    const renderInformation = (label, value, marginBottom = "2pt") => (
      <Paragraph style={{ marginBottom: marginBottom }}>
        <Strong>{label}:</Strong> {value}
      </Paragraph>
    )

    return (
      <View>
        <SectionHeader>Student Information</SectionHeader>
        {renderInformation(
          "Student's Full Legal Name",
          `${info.firstName} ${info.lastName}`
        )}
        {renderInformation("Street Address", info.streetAddress)}
        {(info.state &&
          renderInformation(
            "City, State, Zip",
            `${info.city}, ${info.state}, ${info.zipcode}`
          )) ||
          renderInformation("City, Zip", `${info.city}, ${info.zipcode}`)}
        {info.country && renderInformation("Country", info.country)}
        {info.site &&
          partner.type === "b2b" &&
          renderInformation("Site", site.name)}
        {info.mailingStreetAddress &&
          renderInformation(
            "Mailing Street Address (if different)",
            info.mailingStreetAddress
          )}
        {(info.mailingCity &&
          info.mailingState &&
          info.mailingZipcode &&
          renderInformation(
            "City, State, Zip",
            `${info.mailingCity}, ${info.mailingState}, ${info.mailingZipcode}`
          )) ||
          (info.mailingCity &&
            info.mailingZipcode &&
            renderInformation(
              "City, Zip",
              `${info.mailingCity}, ${info.mailingZipcode}`
            ))}
        {info.mailingCountry &&
          renderInformation("Country", info.mailingCountry)}
        {renderInformation("Email", email)}
        {renderInformation("Phone", info.phoneNumber)}
        {renderInformation(
          "Date of Birth",
          moment(info.dateOfBirth).format(DateFormat)
        )}
        {renderInformation("Citizenship", info.citizenship)}
        {renderInformation("Emergency Contact", info.emergencyContact)}
        {renderInformation(
          "VA File Number and Chapter (if applicable)",
          info.VAFileNumberAndChapter
        )}
        {renderInformation("Social Security Number", info.socialSecurityNumber)}
        {renderInformation(
          "High School of Graduation or Equivalent",
          info.highSchoolOfGraduationOrEquivalent
        )}
        {renderInformation(
          "How Many Terms of College Attended (if applicable)",
          info.howManyTermsOfCollegeAttended
        )}
        {renderInformation(
          "Colleges or Universities Attended Previously (if applicable)",
          info.collegesOrUniversitiesAttendedPreviously
        )}
        {renderInformation("Degree Earned (if applicable)", info.degreeEarned)}

        {info.reference1_name && (
          <View>
            <Paragraph style={{ marginBottom: "2pt", marginTop: "4pt" }}>
              <Text style={nunitoItalic}>Reference 1:</Text>
            </Paragraph>

            {renderInformation("Name", info.reference1_name)}
            {renderInformation("Street Address", info.reference1_streetAddress)}
            {(info.reference1_state &&
              renderInformation(
                "City, State, Zip",
                `${info.reference1_city}, ${info.reference1_state}, ${info.reference1_zipcode}`
              )) ||
              renderInformation(
                "City, Zip",
                `${info.reference1_city}, ${info.reference1_zipcode}`
              )}
            {info.reference1_country &&
              renderInformation("Country", info.reference1_country)}
            {renderInformation("Phone", info.reference1_phoneNumber)}
          </View>
        )}
        {info.reference2_name && (
          <View>
            <Paragraph style={{ marginBottom: "2pt", marginTop: "4pt" }}>
              <Text style={nunitoItalic}>Reference 2:</Text>
            </Paragraph>

            {renderInformation("Name", info.reference2_name)}
            {renderInformation("Street Address", info.reference2_streetAddress)}
            {(info.reference2_state &&
              renderInformation(
                "City, State, Zip",
                `${info.reference2_city}, ${info.reference2_state}, ${info.reference2_zipcode}`
              )) ||
              renderInformation(
                "City, Zip",
                `${info.reference2_city}, ${info.reference2_zipcode}`
              )}
            {info.reference2_country &&
              renderInformation("Country", info.reference2_country)}
            {renderInformation("Phone", info.reference2_phoneNumber)}
          </View>
        )}
      </View>
    )
  }

  const Option = ({
    isSelected,
    title,
  }: {
    isSelected: boolean
    title: string
  }) => (
    <View>
      <Text>
        {isSelected != undefined && (
          <Text>[ {isSelected ? "X" : "\u00A0\u00A0"} ] </Text>
        )}
        {title}
      </Text>
    </View>
  )

  const TrackSelection = ({
    courseCode,
    description,
    isSelected,
    subtitle,
    title,
  }: {
    courseCode?: string
    description: string
    isSelected?: boolean
    subtitle?: string
    title: string
  }) => (
    <Fragment>
      <View style={styles.margins.top["1/2"]}>
        <Strong>
          {isSelected != undefined && (
            <Text>[ {isSelected ? "X" : "\u00A0\u00A0"} ] </Text>
          )}
          {title}
        </Strong>
      </View>
      <View>
        <Text>{subtitle && subtitle + " "}</Text>
        <Text style={nunitoItalic}>{description}</Text>
        {courseCode && (
          <Text style={{ ...nunitoItalic, color: "gray" }}>
            {" "}
            Course Code {courseCode}.
          </Text>
        )}
      </View>
    </Fragment>
  )

  const Separator = ({ text = "***********" }: { text: string }) => (
    <View
      style={{
        ...styles.margins.vertical["1/2"],
        textAlign: "center",
        fontFamily: "Roboto Mono",
      }}
    >
      <Text>
        {`*****************************${text}*****************************`}
      </Text>
    </View>
  )

  const Agreement = ({
    applicant,
    initials,
    onInitialsSign,
    onSign,
    partner,
  }: {
    applicant: Applicant
    initials: { [key: string]: boolean }
    onInitialsSign?: (string) => void
    onSign?: () => Promise<void>
    partner: Partner
  }) => (
    <Document>
      <Page style={{ textAlign: "center" }}>
        <H1
          style={{
            marginTop: "250pt",
            width: "100%",
            textAlign: "center",
          }}
        >
          University
          <Br />
          Enrollment Agreement
        </H1>
      </Page>
      <Page>
        <SectionHeader>Enrollment Agreement:</SectionHeader>
        <Paragraph>
          This document is a legally binding contract between the student and
          Bottega University (formerly New Charter University and Bottega).
          (Throughout this document, Bottega University will be referred to as
          BU.)
        </Paragraph>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Enrollment Agreement"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph>
            By signing this agreement, the student indicates the acceptance of
            all that is written herein and agrees that it will be legally
            binding to him/her. (If the student is under the age of 18, the
            Parental/Guardian Addendum will need to be signed by the
            parent/guardian.)
          </Paragraph>
        </InitialsMark>
        <SectionHeader>
          Admissions Requirements/Ability to Succeed Verification
        </SectionHeader>
        <Paragraph style={nunitoBold}>
          Bottega University (BU) admissions requires the student to submit a
          valid government identification and proof of high school completion or
          equivalent (official high school transcripts or GED record).
          International students must also present a valid TOEFL score of 500
          (61 on internet- based test), or equivalent score.
        </Paragraph>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Admissions Requirements (1)"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph style={nunitoBold}>
            BU reserves the right to refuse any student admission if the student
            lacks the foundation, he/she needs to succeed in the program.
          </Paragraph>
        </InitialsMark>
        <Paragraph>
          Bottega University offers a GED Prep program for those individuals who
          are unable to meet this requirement. Following completion of the GED
          Prep program, the student would be required to pay for and take the
          GED in his/her state or region and submit passing scores to Bottega
          University as proof of completion.
        </Paragraph>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Admissions Requirements (2)"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph>
            Language – I understand that the teaching platform of BU is in the
            English language.
          </Paragraph>
        </InitialsMark>
        <SectionHeader>Time-Frame to Complete the Program</SectionHeader>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Time-Frame to Complete the Program"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph>
            In order to receive a grade for each course all course requirements
            must be completed. All program requirements must be completed within
            the maximum time allotted by accreditation in order to earn the
            certificate or diploma. I understand I must begin classes within
            60-days of the pre-determined start date or this agreement is null
            and void.
          </Paragraph>
        </InitialsMark>
        <SectionHeader>Licensure and Accreditation</SectionHeader>
        <Paragraph>
          Bottega University is accredited by the Distance Education Accrediting
          Commission. The Distance Education Accrediting Commission is listed by
          the U.S. Department of Education as a recognized accrediting agency.
          The Distance Education Accrediting Commission is recognized by the
          Council for Higher Education Accreditation (CHEA). Distance Education
          Accrediting Commission, 1101 17th St., NW, Suite 808, Washington, DC
          20036. (202-234-5100) www.deac.org
        </Paragraph>
      </Page>
      <Page>
        <StudentInformation
          email={applicant.email}
          info={applicant.applicantInfo}
          site={applicant.selectedSite!}
          partner={partner}
        />
      </Page>
      <Page>
        <SectionHeader>
          Bottega University Programs and Information:
        </SectionHeader>
        <Header>Course/Program</Header>
        <Paragraph>
          Program of Study: {applicant.selectedTrack!.title}
          <Br />
          (For example: Associate of Science in Communication; etc.; Non-program
          seeking course-taker)
        </Paragraph>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Bottega University Programs and Information (1)"
          onInitialsSign={onInitialsSign}
        >
          <View
            style={{ ...styles.margins.vertical["1/2"], fontSize: fontSize }}
          >
            <Option
              isSelected={applicant.applicantInfo.diplomaOrGed !== "Yes"}
              title="GED Prep Course Part-time Online. 6 weeks. (This program is no-cost to the student; however, the student is responsible for GED exam fees.)"
            />
            <Option
              isSelected={!applicant.selectedTrack!.isAccredited}
              title="Career Training Division – Bottega LLC (see Addendum)"
            />
          </View>
        </InitialsMark>
        <Header>I understand I have selected the following format:</Header>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Bottega University Programs and Information (2)"
          onInitialsSign={onInitialsSign}
        >
          <View
            style={{ ...styles.margins.vertical["1/2"], fontSize: fontSize }}
          >
            <Option
              isSelected={
                applicant.selectedTrack!.selectedFormat.selectedFormatCredits ==
                "12 or more"
              }
              title="Full-Time On-Line – Degree or Certificate Programs (12 or more credits)"
            />
            <Option
              isSelected={
                applicant.selectedTrack!.selectedFormat.selectedFormatCredits ==
                "9-11"
              }
              title="3⁄4 Time On-Line – Degree or Certificate Programs (9 – 11 credits)"
            />
            <Option
              isSelected={
                applicant.selectedTrack!.selectedFormat.selectedFormatCredits ==
                "below 9"
              }
              title="Part-Time On-Line – Degree or Certificate Programs (below 9 credits)"
            />
          </View>
        </InitialsMark>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Bottega University Programs and Information (3)"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph>
            I understand that if I alter, withdraw, or cancel a course after
            enrollment, that it may delay, or accelerate, my planned graduation
            date.
          </Paragraph>
        </InitialsMark>
        <SectionHeader>Tuition</SectionHeader>
        <Paragraph style={nunitoBold}>
          Tuition: Full Stack Development Certificate - $12,000 for full-time
          students, online twelve weeks, non- degree seeking.
        </Paragraph>
        <Paragraph style={nunitoBold}>
          Full Stack Development Certificate - $7,500 for part-time online 40
          weeks, non-degree seeking.
        </Paragraph>
        <Header>Tuition: UNDERGRADUATE</Header>
        <Paragraph>
          Tuition is $897 per term made in three installments of $299 per month
          for students enrolled in a degree program plus a $50 per term
          technology fee. Tuition for non-degree seeking students is $500 per
          course. The average complete program cost for a Bachelor’s degree
          would be $18,940* plus any applicable fees. The average complete
          program cost for an Associate degree would be $9,470** plus any
          applicable fees.
        </Paragraph>
        <Paragraph style={styles.textSmall}>
          * Average time to completion for an undergraduate student is
          calculated at 48 months or 4 years. This assumes no transfer credit
          was applied. Where transfer credit is accepted, the time to completion
          would be reduced by the amount of transfer credit, thus reducing the
          overall program cost.
        </Paragraph>
        <Paragraph style={styles.textSmall}>
          ** Average time to completion for an Associate’s degree is calculated
          at 24 months or 2 years. This assumes no transfer credit was applied.
          Where transfer credit is accepted, the time to completion would be
          reduced by the amount of transfer credit, thus reducing the overall
          program cost.
        </Paragraph>
      </Page>
      <Page>
        <Header>Tuition: GRADUATE</Header>
        <Paragraph>
          Graduate tuition is $1497 per term made in three installments of $499
          per month for students enrolled in a degree program plus a $50
          technology fee per term. Tuition for non-degree seeking students is
          $1000 per course. The average complete program cost for a Master’s
          degree would be $15,470* plus any applicable fees
        </Paragraph>
        <Paragraph style={styles.textSmall}>
          Average time to completion for a graduate student is calculated at 24
          months or 2 years. This assumes no transfer credit was applied. Where
          transfer credit is accepted, the time to completion would be reduced
          by the amount of transfer credit, thus reducing the overall program
          cost.
        </Paragraph>
        <Paragraph>
          <Strong>SEE ADDENDUM FOR CAREER TRAINING DIVISION TUITION.</Strong>
        </Paragraph>
        <SectionHeader>Fees</SectionHeader>
        <Table>
          <Row small>
            <Text>Technology Fee (assessed each term) non-refundable</Text>
            <Text>$50</Text>
          </Row>
          <Row small>
            <Text>
              Per Course Fee – Individual Undergraduate (retake fee is the same
              cost) (NDS student)
            </Text>
            <Text>$500</Text>
          </Row>
          <Row small>
            <Text>
              Per Course Fee – Individual Graduate (retake fee is the same cost)
              (NDS student)
            </Text>
            <Text>$1000</Text>
          </Row>
          <Row small>
            <Text>Prior Learning Assessment</Text>
            <Text>$300</Text>
          </Row>
          <Row small>
            <Text>Graduation</Text>
            <Text>$50</Text>
          </Row>
          <Row small>
            <Text>
              International Graduation (Additional cost is for international
              postage.)
            </Text>
            <Text>$100</Text>
          </Row>
          <Row small>
            <Text>
              Duplicate Diploma (Any additional shipping cost will be covered by
              the student.)
            </Text>
            <Text>$50</Text>
          </Row>
          <Row small>
            <Text>
              Official Electronic Transcript (Shipping costs for physical
              transcript is incurred at the cost of the student/entity
              requesting the transcript.)
            </Text>
            <Text>$15 per tran script (Parchment)</Text>
          </Row>
          <Row small>
            <Text>Returned Checks/Declined Credit Card Charge </Text>
            <Text>$50</Text>
          </Row>
          <Row small>
            <Text>Late Monthly Payment Charges</Text>
            <Text>$20</Text>
          </Row>
        </Table>

        <SectionHeader>Time-Frame to Complete the Program</SectionHeader>
        <Paragraph>
          In order to receive a grade and have your certificate/degree reflect
          full credit for a class, all course requirements must be completed
          within a designated time-frame.{" "}
          <Strong>
            The student must start within 60-days of the pre-determined start
            date or this agreement is null and void.
          </Strong>
        </Paragraph>

        <View style={styles.margins.bottom}>
          <View>
            <Strong>
              ANTICIPATED PROGRAM START DATE:{" "}
              {determineStartDate(applicant.selectedTrack!)}
            </Strong>
          </View>
          <View>
            <Strong>
              ANTICIPATED PROGRAM END DATE:{" "}
              {determineEndDate(applicant.selectedTrack!)}
            </Strong>
          </View>
        </View>
      </Page>
      <Page>
        <SectionHeader>Termination</SectionHeader>
        <Paragraph>
          Termination dates for this enrollment agreement are 150% the amount of
          time typically allowed for a similar degree at other American
          universities where transfer credits are not under consideration.
          Certificate programs terminate based on the 150% time for the total
          number of credits required to successfully complete the certificate as
          a part time student from the start date of the first term enrolled.
          Associate Degree agreements terminate 3 years from the start date of
          the first term enrolled in the degree program. (e.g. 1st term start
          date 3/2/2018 must be completed by 3/2/2021.) Bachelor’s Degree
          Enrollment Agreements terminate 6 years from the start date of the
          first term enrolled in the degree program. (e.g. 1st term start date
          3/2/2020 must be completed by 3/2/2026.) Master’s Degree Enrollment
          Agreements terminate 3 years from the start date of the first term
          enrolled in the degree program. (e.g. MBA in Business 1st term start
          date 3/2/2020 must be completed by 3/2/2023.) For students who do not
          complete their program by the termination date listed within this
          enrollment agreement or who stop attending for more than 90 days will
          be Administratively Withdrawn and the contract will be terminated.
          Upon return, the student will have a program review and will be
          required to sign a new enrollment agreement after re-applying for
          re-admission.
        </Paragraph>
        <Paragraph>
          <Strong>
            CONTRACT TERMINATION DATE:{" "}
            {applicant.applicantInfo.enrollmentAgreementTerminationDate || "-"}{" "}
            (150% LENGTH OF PROGRAM)
          </Strong>
        </Paragraph>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Termination"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph>
            I release BU from any liability relating to failure to complete the
            program in the time allotted per this agreement.
          </Paragraph>
        </InitialsMark>
        <SectionHeader>
          Obligations, Understanding, Responsibilities
        </SectionHeader>
        <Header>Obligations:</Header>
        <Paragraph>
          The education and services provided under this enrollment agreement
          are as follows:
        </Paragraph>
        <Paragraph>
          <Strong>Education:</Strong> Bottega University provides accredited
          degree and certificate programs to students. All program courses are
          hosted in an online learning management system. A traditional 4.0
          grading scale is used, and a Grade Point Average is calculated. All
          faculty at Bottega University are highly qualified with relevant
          credentials and professional experience for the subjects they teach.
          See the Bottega University catalog for details.
        </Paragraph>
        <Paragraph>
          <Strong>Student Services:</Strong> Bottega University provides all
          students with Student Advising services.
        </Paragraph>
        <Paragraph>
          <Strong>Career Services:</Strong> Bottega University is available to
          assist with guidance toward employability preparation. Technical
          Support: Technical support is available to all students.
        </Paragraph>
        <Paragraph>
          <Strong>Records Management:</Strong> Students’ records are kept in a
          secure Student Information System. Redundancies and long-term storage
          are in place to protect student records. Records are kept according to
          DEAC policy. Students can request copies of transcripts through our
          transcript service, Parchment, via the Parchment website:
          https://www.parchment.com/u/registration/1135508/account.
        </Paragraph>
      </Page>
      <Page>
        <Paragraph>
          There is a $15 fee for transcripts, plus a shipping fee if a student
          elects to receive/send a physical copy of the transcript rather than
          an electronic one.
        </Paragraph>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Continuous Enrollment"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph>
            <Strong>Continuous Enrollment:</Strong> After the first term, the
            student will be auto-enrolled in his/her future classes per the
            signed degree plan which includes number of courses per term unless
            approved for an official Leave of Absence. Failure to continue
            enrollment (including unapproved absences from class) could result
            in Administrative Withdrawal from the University. To continue in the
            program after this withdrawal, a student would be required to
            reapply for admission and would be subject to the requirements
            listed in the new enrollment agreement signed at the time of
            re-admittance and new program of study requirements, if any updates
            or changes exist.
          </Paragraph>
        </InitialsMark>

        <SectionHeader>Understanding:</SectionHeader>
        <Header>Term Starts</Header>
        <Paragraph>
          Terms start on the first Monday of each month during the calendar
          year, unless a legal US holiday is on a Monday, then the term starts
          on Tuesday. Please review the catalog and the website for the official
          calendar. Students applying for the GED program, or as part-time
          students in the Career Training Division may start on any given day,
          providing all application requirements are met prior to starting.
        </Paragraph>
        <Header>Textbooks</Header>
        <Paragraph>
          Tuition and fees do not include cost of books and study materials.
          Obtaining books and study materials for courses is the responsibility
          of the student. The best estimated cost of new textbooks and other
          materials per course is currently a purchase price averaging $20-$170.{" "}
          <Strong>
            Textbooks are not mandatory, but purchase is encouraged.
          </Strong>
        </Paragraph>
        <UnsortedList>
          <ListItem>
            <Text>
              Students are responsible for making consistent academic progress
              toward their educational goals.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Students are expected to engage in the learning environment on a
              weekly basis. Students may choose to attend school on a full-time
              or part-time basis.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              For degree-seeking students, BU policy allows students to register
              for up to four courses in a term. (Exceptions to the number of
              courses taken in the term are at the discretion of the Academic
              Dean, upon written request by the student to the Dean.)
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              It is the student’s responsibility to follow the Student Code of
              Conduct and all other policies outlined in the School Catalog,
              which is provided to each student through the BU website at
              www.bottega.edu. The policies in the School Catalog are subject to
              change, and it is the student’s responsibility to check the School
              Catalog periodically for changes in policy.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Bottega University does not participate in Federal Title IV
              financial aid programs.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Bottega does participate in educational programs through the
              Veterans Administration.
            </Text>
          </ListItem>
        </UnsortedList>
      </Page>
      <Page>
        <SectionHeader>Student’s Right to Cancel</SectionHeader>
        <Paragraph>
          The student has a right to cancel this agreement. To cancel, contact
          the admissions representative, advisor, or the Registrar by email,
          telephone, or mail. If the student does not know the contact
          information for any of these individuals, the request may be submitted
          by emailing registrar@bottega.edu. Requests placed in writing are
          preferred. If the student chooses to cancel this agreement within 7
          calendar days of signing, he/she is entitled to a full refund of
          tuition paid, including any registration or transcript evaluation
          fees. Please consult the Refund Policy section for details on the
          percent of refund available for any withdrawal. Agreements for
          enrolled students who do not complete any courses in which they are
          enrolled during their current term and which are not canceled by
          formal request to the university will be administratively withdrawn
          after the conclusion of the inactive term.
        </Paragraph>
        <SectionHeader>Refund/Cancellation Policy</SectionHeader>
        <Paragraph>
          <Strong>Cancellation Prior to the Commencement of Classes:</Strong>A
          student will be entitled to a full refund if the student cancels
          his/her enrollment within five days of signing the University
          Enrollment Agreement.
        </Paragraph>
        <Paragraph>
          <Strong>Cancellation After the Commencement of Classes:</Strong>
          The first day of class through Sunday of week one 11:59 p.m. MST is
          considered the trial period for all new, reentry and graduate
          reenrolled students. If a new, reentry, or graduate reenrolled student
          attends after the first day of courses but withdraws before Monday of
          week two at 12:00 a.m. MST, the student will be unregistered from
          his/her courses and will be entitled to a refund of all tuition and
          applicable fees.
        </Paragraph>
        <Paragraph>
          <Strong>
            Withdrawal from the University in the first seven weeks of the
            Semester: (12-week term)
          </Strong>
          . For all other students, the table below lists the percentage of
          tuition that will be refunded to students who cancel their enrollment
          prior to the end of a term.
        </Paragraph>
        <Table title="Table 1 - Refund Percentage Schedule">
          <Row small>
            <Strong>Cancellation Date</Strong>
            <Strong>Refunded % of Tuition</Strong>
          </Row>
          <Row small>
            <Text>Week 1</Text>
            <Text>100%</Text>
          </Row>
          <Row small>
            <Text>Week 2</Text>
            <Text>80%</Text>
          </Row>
          <Row small>
            <Text>Week 3</Text>
            <Text>70%</Text>
          </Row>
          <Row small>
            <Text>Week 4</Text>
            <Text>60%</Text>
          </Row>
          <Row small>
            <Text>Week 5</Text>
            <Text>50%</Text>
          </Row>
          <Row small>
            <Text>Week 6</Text>
            <Text>40% </Text>
          </Row>
          <Row small>
            <Text>No refund after week 6</Text>
          </Row>
        </Table>
      </Page>
      <Page>
        <Paragraph>
          <Strong>
            Withdrawal from the University for 10-month Part-time Full Stack
            Development Program:
          </Strong>
        </Paragraph>
        <Table title="Table 2 - Refund Percentage Schedule">
          <Row small>
            <Strong>Cancellation Date</Strong>
            <Strong>Refunded % of $7500 Tuition</Strong>
          </Row>
          <Row small>
            <Text>0%-25% of Coding Foundations</Text>
            <Text>100%</Text>
          </Row>
          <Row small>
            <Text>25%-100% of Coding Foundations</Text>
            <Text>93%</Text>
          </Row>
          <Row small>
            <Text>Month 2 or completion of 20% of the FSDC program</Text>
            <Text>80%</Text>
          </Row>
          <Row small>
            <Text>Month 3 or completion of 30% of the FSDC program</Text>
            <Text>70%</Text>
          </Row>
          <Row small>
            <Text>Month 4 or completion of 40% of the FSDC program</Text>
            <Text>60%</Text>
          </Row>
          <Row small>
            <Text>Month 5 or completion of 50% of the FSDC program</Text>
            <Text>50% </Text>
          </Row>
          <Row small>
            <Text>Month 6 or completion of 60% of the FSDC program</Text>
            <Text>40% </Text>
          </Row>
          <Row small>
            <Text>No refund after month 6</Text>
          </Row>
        </Table>
        <Paragraph>
          Refunds, if applicable, will be made by the Accounting Department
          within 30 days of the student’s withdrawal. The withdrawal becomes
          official when the student receives final written notification and a
          final statement from the University. Before the university will
          release any transcripts, any balance due must be paid in full by the
          student. If the student has any questions about the status of his/her
          refund or about the statement balance, please contact the Accounting
          Department at accounts@bottega.edu.
        </Paragraph>
        <Header>Sample Refund Calculation:</Header>
        <Paragraph>
          Bottega University assesses tuition as a per term charge plus a per
          term $50 non-refundable technology fee. Students seeking to cancel
          their enrollment for the term will be eligible for a percentage of
          tuition refund of the term corresponding with the week of withdrawal.
        </Paragraph>
        <Paragraph>
          <Strong>
            MONTHLY PAYMENT FOR UNDERGRADUATE AND GRADUATE STUDENTS
          </Strong>
        </Paragraph>
        <Paragraph>
          The following tables pertain to the sample refund calculation. Table 2
          denotes the institutional charges. Table 3 is a sample refund
          calculation
        </Paragraph>
        <Table title="Table 2 - Institutional Charges">
          <Row small>
            <Text>BU Institutional Charge</Text>
            <Text>Amount</Text>
          </Row>
          <Row small>
            <Text>Undergraduate Tuition</Text>
            <Text>$897</Text>
          </Row>
          <Row small>
            <Text>Technology Fee*</Text>
            <Text>$50</Text>
          </Row>
        </Table>
        <Paragraph>OR</Paragraph>
        <Table title="Table 2 - Institutional Charges">
          <Row small>
            <Text>NCU Institutional Charge</Text>
            <Text>Amount</Text>
          </Row>
          <Row small>
            <Text>Graduate Tuition</Text>
            <Text>$1,497</Text>
          </Row>
          <Row small>
            <Text>Technology Fee*</Text>
            <Text>$50</Text>
          </Row>
        </Table>
      </Page>
      <Page>
        <Table
          title="Table 3 - Refund Calculation"
          description="Student requests to drop course AFTER the second week, in Week 3."
        >
          <Row small>
            <Strong>Undergraduate</Strong>
          </Row>
          <Row small>
            <Strong>Student Statement:</Strong>
            <Strong>Debit</Strong>
            <Strong>Credit</Strong>
          </Row>
          <Row small>
            <Text>Tuition Charge Term A</Text>
            <Text>897.00</Text>
            <Text />
          </Row>
          <Row small>
            <Text>Technology Fee Term A*</Text>
            <Text>50.00</Text>
            <Text />
          </Row>
          <Row small>
            <Text>Student payment</Text>
            <Text />
            <Text>299.00</Text>
          </Row>
          <Row small>
            <Text>
              Withdrawal Week 3 (30% earned)
              <Br />
              (897x70%)
            </Text>
            <Text>-627.90</Text>
            <Text />
          </Row>
          <Row small>
            <Text>Totals</Text>
            <Text>319.10</Text>
            <Text>299.00</Text>
          </Row>
          <Row small>
            <Text>
              Balance due to <Strong>BU</Strong>
            </Text>
            <Text>$20.10</Text>
            <Text />
          </Row>
        </Table>
        <Paragraph>OR</Paragraph>
        <Table>
          <Row small>
            <Strong>Graduate</Strong>
          </Row>
          <Row small>
            <Strong>Student Statement:</Strong>
            <Strong>Debit</Strong>
            <Strong>Credit</Strong>
          </Row>
          <Row small>
            <Text>Tuition Charge Term A</Text>
            <Text>1497.00</Text>
            <Text />
          </Row>
          <Row small>
            <Text>Technology Fee Term A*</Text>
            <Text>50.00</Text>
            <Text />
          </Row>
          <Row small>
            <Text>Student payment</Text>
            <Text />
            <Text>499.00</Text>
          </Row>
          <Row small>
            <Text>
              Withdrawal Week 3 (30% earned)
              <Br />
              (1497x70%)
            </Text>
            <Text>-1047.90</Text>
            <Text />
          </Row>
          <Row small>
            <Text>Totals</Text>
            <Text>499.10</Text>
            <Text>499.00</Text>
          </Row>
          <Row small>
            <Text>
              Balance due to <Strong>BU</Strong>
            </Text>
            <Text>$0.10</Text>
            <Text />
          </Row>
        </Table>

        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Technology fees"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph style={styles.textSmall}>
            * Technology fees are assessed to the student’s account upon
            enrollment each new term. This fee covers access to the ProQuest
            Library, Originality Verification Tools, Proctoring Services, and
            other learning resources. This fee is{" "}
            <Strong>NON-REFUNDABLE</Strong>.
          </Paragraph>
        </InitialsMark>
      </Page>
      <Page>
        <Paragraph>
          <Strong>
            SINGLE COURSE FEE NON-DEGREE SEEKING STUDENTS - GRADUATE
          </Strong>
        </Paragraph>
        <Paragraph>
          Student requests to drop a course AFTER the second week (i.e. in Week
          3 of enrollment):
        </Paragraph>
        <Table>
          <Row small>
            <Strong>Student Statement:</Strong>
            <Strong>Debit</Strong>
            <Strong>Credit</Strong>
          </Row>
          <Row small>
            <Text>Tuition Charge Term A</Text>
            <Text>1000.00</Text>
            <Text />
          </Row>
          <Row small>
            <Text>Technology Fee Term A*</Text>
            <Text>50.00</Text>
            <Text />
          </Row>
          <Row small>
            <Text>Student payment</Text>
            <Text />
            <Text>1000.00</Text>
          </Row>
          <Row small>
            <Text>
              Withdrawal Week 3 (30% earned)
              <Br />
              (1000*70%)
            </Text>
            <Text>-700</Text>
            <Text />
          </Row>
          <Row small>
            <Text>Totals</Text>
            <Text>350.00</Text>
            <Text>1000.00</Text>
          </Row>
          <Row small>
            <Text>
              Balance due to <Strong>Student</Strong>
            </Text>
            <Text />
            <Text>$650.00</Text>
          </Row>
        </Table>
        <Paragraph style={styles.textSmall}>
          * Technology fees are assessed to the student’s account upon
          enrollment each new term. This fee covers access to the ProQuest
          Library, Originality Verification Tools, Proctoring Services, and
          other learning resources. This fee is <Strong>NON-REFUNDABLE</Strong>.
        </Paragraph>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Refund/Cancellation Policy"
          onInitialsSign={onInitialsSign}
        >
          <Text />
        </InitialsMark>
        <Paragraph style={styles.textSmall}>
          California: The State of California established the Student Tuition
          Recovery Fund (STRF) to relieve or mitigate economic loss suffered by
          a student in an educational program at a qualifying institution, who
          is or was a California resident while enrolled, or was enrolled in a
          residency program, if the student enrolled in the institution, prepaid
          tuition, and suffered an economic loss. Unless relieved of the
          obligation to do so, you must pay the state-imposed assessment for the
          STRF, or it must be paid on your behalf, if you are a student in an
          educational program, who is a California resident, or are enrolled in
          a residency program, and prepay all or part of your tuition. You are
          not eligible for protection from the STRF and you are not required to
          pay the STRF assessment if you are not a California resident, or are
          not enrolled in a residency program. "It is important that you keep
          copies of your enrollment agreement, financial aid documents,
          receipts, or any other information that documents the amount paid to
          the school.” Questions regarding the STRF may be directed to the
          Bureau for Private Post-secondary Education, 2535 Capitol Oaks Drive,
          Suite 400, Sacramento, CA 95833, (916) 431-6959 or (888) 370-7589. To
          be eligible for STRF, you must be a California resident or enrolled in
          a residency program, prepaid tuition, paid or deemed to have paid- the
          STRF assessment, and suffered an economic loss as a result of any of
          the following:
        </Paragraph>
        <IndexedList style={styles.textSmall}>
          <ListItem>
            <Text>
              The institution, a location of the institution, or an educational
              program offered by the institution was closed or discontinued, and
              you did not choose to participate in a teach-out plan approved by
              the Bureau or did not complete a chosen teach-out plan approved by
              the Bureau.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              You were enrolled at an institution or a location of the
              institution within the 120-day period before the closure of the
              institution or location of the institution, or were enrolled in an
              educational program within the 120-day period before the program
              was discontinued.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              You were enrolled at an institution or a location of the
              institution more than 120 days before the closure of the
              institution or location of the institution, in an educational
              program offered by the institution as to which the Bureau
              determined there was a significant decline in the quality or value
              of the program more than 120 days before closure.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              The institution has been ordered to pay a refund by the Bureau but
              has failed to do so.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              The institution has failed to pay or reimburse loan proceeds under
              a federal student loan program as required by law, or has failed
              to pay or reimburse proceeds received by the institution in excess
              of tuition and other costs.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              You have been awarded restitution, a refund, or other monetary
              award by an arbitrator or court-based on a violation of this
              chapter by an institution or representative of an institution, but
              have been unable to collect the award from the institution.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              You sought legal counsel that resulted in the cancellation of one
              or more of your student loans and have an invoice for services
              rendered and evidence of the cancellation of the student loan or
              loans.
            </Text>
          </ListItem>
        </IndexedList>
      </Page>
      <Page>
        <Paragraph style={styles.textSmall}>
          To qualify for STRF reimbursement, the application must be received
          within four (4) years from the date of the action or event that made
          the student eligible for recovery from STRF. A student whose loan is
          revived by a loan holder or debt collector after a period of non-
          collection may, at any time, file a written application for recovery
          from STRF for the debt that would have otherwise been eligible for
          recovery. If it has been more than four (4) years since the action or
          event that made the student eligible, the student must have filed a
          written application for recovery within the original four (4) year
          period, unless the period has been extended by another act of law.
          However, no claim can-be paid to any student without a social security
          number or a taxpayer identification number.
        </Paragraph>
        <SectionHeader>Extensions</SectionHeader>
        <Paragraph>
          If extenuating circumstances arise where the student is unable to
          progress, the student must meet with the instructor and/or the
          academic team to complete an extension request form. The instructor or
          academic team member must present the form for approval to the
          Academic Dean, Chief of Staff, or the President/Chief Academic
          Officer, who approves or disapproves the request.
        </Paragraph>
        <Paragraph>
          Students may extend their program{" "}
          <Strong>30 days per extension</Strong> (with a limit of two) for full
          time students and <Strong>30 days per extension</Strong> (with a limit
          of four) for part time students. Failure to complete the program
          within the enrollment agreement contracted time frame or apply for an
          approved extension will result in an incomplete for the program and
          termination of the enrollment.
        </Paragraph>
      </Page>
      <Page>
        <SectionHeader>Financial Assistance</SectionHeader>
        <Header>Payments</Header>
        <Paragraph>
          Tuition and Fee payment must be in U.S. funds only. Students in
          non-United States countries will be responsible for payment of any
          applicable customs duties. If you are making monthly payments, your
          first monthly payment is due upon the start date of your term, and
          subsequent payments will be due on the first of every month
          thereafter. Your credit/debit card will be charged every month until
          your tuition is paid in full. You are responsible for updating account
          information should the card expire or close to avoid late or returned
          payment penalties. If there are insufficient funds in your account,
          Bottega University will resubmit your payment, and a collection fee of
          $50.00 for insufficient funds may be imposed. Late payments may incur
          a $20.00 per month late payment fee. Should accounts become
          delinquent, Bottega University may pursue collection procedures
          allowable under DEAC regulations and allowable by law. Students will
          not be eligible to enroll in their next term if the account is
          delinquent. Bottega University will not provide transcripts for
          students whose accounts are delinquent.{" "}
          <Strong>
            University Degree-seeking students have two attempts to successfully
            complete a course.
          </Strong>{" "}
          Retaking a course has a specific cost for undergraduate and graduate
          courses. (Please see fee schedule.) To complete your degree program,
          you must earn a passing grade in all required courses.
        </Paragraph>
        <Header>Payment Methods</Header>
        <Paragraph>
          In addition to checks and money orders, Bottega University also
          accepts Visa®, MasterCard®, American Express®, Discover®, Diners
          Club®, and JCB International Credit Card®. Students can choose to pay
          by mail, phone, or online.
        </Paragraph>
        <UnsortedList>
          <ListItem>
            <Text>
              By Mail: Students can mail a check to Bottega University’s
              administrative office at the following address: Bottega
              University, 50 W. Broadway, Ste. 300, Salt Lake City, UT 84101
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              By Phone: Credit card and debit card payments are accepted over
              the phone (by the Accounting Department). Students should contact
              the University at (385) 200-9292 during normal business hours, and
              request to speak with the Accounting Department.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Online: Students can make online payments through their Diamond
              Student Portal Account at any time by credit card or debit card.
              Students should log into their account, click “Make a Payment” on
              the left side menu and follow the payment prompts.
            </Text>
          </ListItem>
        </UnsortedList>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Payment Methods"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph>
            Any questions a student may have regarding this enrollment agreement
            that have not been satisfactorily answered by the institution may be
            directed to the Utah Division of Consumer Protection at 160 E. 300
            S., Salt Lake City, UT 84111, http://consumerprotection.utah.gov/,
            toll-free telephone number (800) 721-7233 or by fax (801) 530-6001.
          </Paragraph>
        </InitialsMark>
        <Header>Scholarships</Header>
        <Paragraph>
          Students who have been awarded a scholarship will receive the reduced
          tuition amount as outlined in the scholarship offer. Students who fail
          to successfully pass any course must retake it at their own expense
          and this may impact their eligibility for continued payment of the
          scholarship.
        </Paragraph>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Scholarships"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph>
            Scholarship offered: {applicant.scholarship.title || "-"}
            <Br />
            Scholarship amount: {applicant.scholarship.amount || "0"} USD
          </Paragraph>
        </InitialsMark>
      </Page>
      <Page>
        <Header>Discounts</Header>
        <Paragraph>
          Bottega University does not offer any form of discounts to students.
        </Paragraph>
        <Header>Collections Policy</Header>
        <Paragraph>
          Students are encouraged to stay in contact with the institution to
          remain current with their payments. A review of all student accounts
          is completed by the Accounting Department every 30 days. Students are
          notified if their tuition account becomes past due. If a student’s
          account remains past due, multiple attempts will be made to notify the
          student of his/her outstanding balance, through email or phone
          contact.
        </Paragraph>
        <Paragraph>
          Students are prevented from enrolling in additional terms if they have
          a past due balance on their tuition account. In the event a past due
          account remains outstanding for more than 7 days, the student will be
          notified that he/she is at risk for Financial Probation. If the
          student fails to make a payment within the 15 days, from the prior
          past due notice, the student will receive a notice that he/she has
          been placed on Financial Probation. This designation will be entered
          in the financial system and on the student’s record in the Student
          Information System. Students will be deactivated in each course and
          not allowed to proceed until payment is made. Once the student has
          been notified, he/she has been placed on Financial Probation, the
          student has an additional 15 days to make a payment or he/she will be
          administratively withdrawn from the University.
        </Paragraph>
        <Paragraph>
          Should a student decide to re-enroll at the University, the student
          will be required to pay his/her prior outstanding balance in full
          before he/she is permitted to re-enroll and register for courses.
        </Paragraph>
        <Paragraph>
          Failure to pay after reasonable attempts have been made may result in
          the account being sent to a collection agency. Students are
          responsible for all fees and other costs as associated with account
          collections. Upon the account being referred to a collection agency,
          the student agrees to pay:
        </Paragraph>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Collections Policy"
          onInitialsSign={onInitialsSign}
        >
          <IndexedList>
            <ListItem>
              <Text>The account balance</Text>
            </ListItem>
            <ListItem>
              <Text>
                A collection fee (based on a percentage up to 30% of the total
                debt owed to BU.)
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                Any other costs and expenses, including attorneys’ fees,
                incurred in such collection efforts
              </Text>
            </ListItem>
          </IndexedList>
        </InitialsMark>
        <Header>Privacy and Terms of Use Policies</Header>
        <Paragraph>
          Bottega University has established policies for Privacy and Terms of
          Use. These policies are attached as supporting documentation to this
          Enrollment Agreement and can also be found on the BU website.
        </Paragraph>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Privacy and Terms of Use Policies"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph>
            Initial here to acknowledge receipt and accept the terms of BU’s
            Privacy Policy and Terms of Use Policy.
          </Paragraph>
        </InitialsMark>
        <Header>Bottega University Catalog</Header>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Bottega University Catalog"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph>
            The Bottega University Catalog provides full detail of all of the
            university’s policies, educational offerings, and other required
            disclosures. Each student is required to read and acknowledge
            receipt of the catalog, which can be found on the Bottega University
            website. Initial here to acknowledge location of the Bottega
            University Catalog found at www.bottega.edu.
          </Paragraph>
        </InitialsMark>
      </Page>
      <Page>
        <SectionHeader>
          Attendance Policy: Full Stack Development Certificate Only
        </SectionHeader>
        <Paragraph>
          In order to ensure the students’ progress meets completion
          requirements, the following attendance is required.
        </Paragraph>
        <Header>Full Time Progression</Header>
        <Paragraph>
          Each student’s term is 12 weeks in length and determined by the start
          and end dates signed on the enrollment agreement. Students must not
          miss more than 5 days during the 12-week term to prevent being
          dismissed directly due to attendance. Students must also have
          completed 50% of the work required by the end of the fifth week.
          Students who fall behind must participate in remediation. Failure to
          complete 50% of the program at the sixth week or fall up to 10% behind
          will place the student under Unsatisfactory Progress and result in
          communications (phone call, virtual meeting call, email, text) from
          the instructor or a member of the academic team (Chief of Staff and
          lead instructor). Students are encouraged to work with their
          instructor and their academic team to return to Satisfactory Progress
          academic standing. Students who fall more than 10% behind in their
          studies at any time will immediately be placed in Unacceptable
          Progress academic standing.{" "}
          <Strong>
            (Any academic change to a student’s status will be documented in the
            student’s academic file.)
          </Strong>
        </Paragraph>
        <Paragraph>
          Students remaining in Unacceptable Progress academic standing for more
          than two weeks must meet with their instructor or academic team to
          develop a plan for returning to Satisfactory Progress. Failure to do
          so and/or return to Satisfactory Progress may result in disciplinary
          measures up to dismissal.
        </Paragraph>
        <Table>
          <Row>
            <Text>Status</Text>
            <Text>Academic Standing</Text>
          </Row>
          <Row>
            <Text>On-time Progression</Text>
            <Text>Satisfactory Progress</Text>
          </Row>
          <Row>
            <Text>Less than 50% Progression by the end of week 6</Text>
            <Text>Unsatisfactory Progress</Text>
          </Row>
          <Row>
            <Text>10% or greater behind On-time Progression</Text>
            <Text>Unacceptable Progress</Text>
          </Row>
        </Table>
        <Header>Part Time Progression</Header>
        <Header>Full Stack Development Program</Header>
        <Paragraph>
          The Full Stack Development program term length is ten months
          (including Coding Foundations) for part time students and determined
          by the start and end dates signed on the enrollment agreement.
          Students must complete Coding Foundations in the first month to be
          able to proceed to the Full Stack Development Certificate program in
          the second month. The following progression should be achieved monthly
          through to the end of the program at month ten.{" "}
          <Strong>
            The measurement of completion (attainment of benchmark) will be
            recorded at the end of each month.
          </Strong>
        </Paragraph>
      </Page>
      <Page>
        <Table>
          <Row>
            <Text>Month 1</Text>
            <Text>100% of Coding Foundations by the EOM</Text>
          </Row>
          <Row>
            <Text>Month 2</Text>
            <Text>10% completion by the EOM</Text>
          </Row>
          <Row>
            <Text>Month 3</Text>
            <Text>20% completion by the EOM</Text>
          </Row>
          <Row>
            <Text>Month 4</Text>
            <Text>35% completion by the EOM</Text>
          </Row>
          <Row>
            <Text>Month 5</Text>
            <Text>45% completion by the EOM</Text>
          </Row>
          <Row>
            <Text>Month 6</Text>
            <Text>55% completion by the EOM</Text>
          </Row>
          <Row>
            <Text>Month 7</Text>
            <Text>70% completion by the EOM</Text>
          </Row>
          <Row>
            <Text>Month 8</Text>
            <Text>85% completion by the EOM</Text>
          </Row>
          <Row>
            <Text>Month 9</Text>
            <Text>95% completion by the EOM</Text>
          </Row>
          <Row>
            <Text>Month 10 (including Capstone)</Text>
            <Text>100% completion by the EOM</Text>
          </Row>
        </Table>
        <Table>
          <Row>
            <Text>Status</Text>
            <Text>Academic Standing</Text>
          </Row>
          <Row>
            <Text>On-time Progression</Text>
            <Text>Satisfactory Progress</Text>
          </Row>
          <Row>
            <Text>&lt;10% of monthly benchmark</Text>
            <Text>Unsatisfactory Progress</Text>
          </Row>
          <Row>
            <Text>10% or greater of monthly benchmark</Text>
            <Text>Unacceptable Progress</Text>
          </Row>
        </Table>
        <Paragraph>
          Students must have successfully reached the benchmark required by the
          end of each month. Students who fall behind must participate in
          remediation. Students are required to meet with mentors weekly.
          Failure to meet with a mentor three times or failure to meet the
          percentage completion will place the student under Unsatisfactory
          Progress if the student is &lt;10% below required and result in
          communications (phone call, virtual meeting call, email, text) from
          the instructor, mentor, or a member of the academic team. Students are
          encouraged to work with their instructor and their academic team to
          return to Satisfactory Progress academic standing. Students who fall
          greater than 10% behind in their studies at any time will immediately
          be placed in Unacceptable Progress academic standing. (Any academic
          change to a student’s status will be documented in the student’s
          academic file.)
        </Paragraph>
        <Paragraph>
          Students remaining in Unacceptable Progress academic standing for more
          than two weeks must meet with their instructor or academic team to
          develop a plan for returning to Satisfactory Progress. Failure to do
          so and/or return to Satisfactory Progress may result in disciplinary
          measures up to dismissal.
        </Paragraph>
      </Page>
      <Page>
        <SectionHeader>Bottega University Acknowledgments</SectionHeader>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Bottega University Acknowledgments"
          onInitialsSign={onInitialsSign}
        >
          <IndexedList>
            <ListItem>
              <Text>
                Do not sign this agreement before you have read it or if it
                contains any blank spaces.
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                This agreement is a legally binding instrument. To be binding,
                all signatures of agreement must be attached and dated.
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                The student is entitled to an exact copy of this agreement and
                any disclosure pages the student signs.
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                BU reserves the right to terminate a student’s enrollment for
                unsatisfactory progress, non-payment of tuition, or failure to
                abide by established standards of conduct.
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                Under no circumstances shall BU be liable for any damages,
                including direct, incidental, special, consequential, or
                punitive damages, that result from this agreement, or your
                enrollment in its programs or from other BU services, even if
                both entities have been advised of the possibility of such
                damages. In no event shall BU’s total liability to the student
                for all damages, losses, and causes of action, whether in
                contract, tort or otherwise, exceed the amounts paid by the
                student to either entity.
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                This Agreement shall be governed by the laws of the State of
                Utah. The parties agree that venue shall be in Utah courts for
                any disputes arising between the parties and both parties agree
                to the personal jurisdiction and venue of such courts and waive
                any right to assert otherwise. In any action between the
                parties, which arises out of or relates to this Agreement, the
                prevailing party, in addition to all other rights or remedies,
                shall be entitled to an award of its costs incurred in
                connection with the action, including reasonable attorney’s fees
                and costs.
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                This Agreement may not be modified or amended except in writing
                signed by the parties hereto and specifically referring to this
                Agreement. If any provision of this Agreement is found to be
                void or unenforceable, such provision shall be modified to the
                minimum extent necessary to make it valid and enforceable; or,
                if it cannot be so modified, it shall be severed and the other
                provisions of this Agreement shall remain in full force and
                effect.
              </Text>
            </ListItem>
          </IndexedList>
        </InitialsMark>
      </Page>
      <Page>
        <SectionHeader>Student Acknowledgements:</SectionHeader>
        <IndexedList>
          <ListItem>
            <Text>
              I have carefully read and will download my own personal copy of
              this enrollment agreement after signing it.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              I understand the school may terminate my enrollment if I fail to
              comply with attendance, academic, and financial requirements or if
              I fail to abide by established standards of conduct. While
              enrolled in the school, I understand that I must maintain
              satisfactory academic progress as described in the school catalog
              and that my financial obligation to the school must be paid in
              full before a certificate or degree may be awarded.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              The application and enrollment agreement are true and correct to
              the best of my knowledge and may be relied upon by BU. I hereby
              authorize BU and its designated agents and representatives to
              conduct comprehensive reviews of my credit history, causing such
              consumer reports to be generated as BU deems necessary in
              connection with the review of the application and/or the
              collection of any credit extended in reliance on the application.
              I further authorize any person, consumer reporting agency, firm,
              corporation, or public agency to divulge to BU or its designated
              agents or representatives any and all information pertaining to me
              that it may have or obtain in response to such inquiries, whether
              verbal or written.
            </Text>
          </ListItem>
          <ListItem>
            <InitialsMark
              applicant={applicant}
              initials={initials}
              label="Student Acknowledgements (1)"
              onInitialsSign={onInitialsSign}
            >
              <Text>
                By my initialing this statement and with my final signature at
                the end, I acknowledge that I grant my permission to BU to use
                my image (photograph and/or video) for use in media publications
                including: videos, email blasts, recruiting brochures,
                newsletters, magazines, general publications, and website and/or
                affiliates. I hereby waive any right to inspect or approve the
                finished photographs or electronic written matter that may be
                used in conjunction with them now or in the future, whether that
                use is known to me or unknown, and I waive any right to
                royalties or other compensation arising from or related to the
                use of the image.
              </Text>
            </InitialsMark>
          </ListItem>
          <ListItem>
            <InitialsMark
              applicant={applicant}
              initials={initials}
              label="Student Acknowledgements (2)"
              onInitialsSign={onInitialsSign}
            >
              <Text>
                Finally, I hereby release BU and its officers, directors,
                shareholders, agents, and representatives from any and all
                liability for damages of any kind, which may result to me from
                the actions authorized by this release. A photocopy or facsimile
                of this form shall be treated as an original.
              </Text>
            </InitialsMark>
          </ListItem>
        </IndexedList>
      </Page>
      <Page>
        <DocAcceptance
          applicant={applicant}
          initials={initials}
          onSign={onSign}
        />
      </Page>
      <Page>
        <View style={{ textAlign: "center" }}>
          <SectionHeader style={{ marginBottom: 0 }}>Addendum</SectionHeader>
          <SectionHeader style={{ marginTop: 0, marginBottom: 0 }}>
            Bottega University - Career Training Division
          </SectionHeader>
        </View>
        <Header>Licensure and Accreditation</Header>
        <Paragraph>
          REGISTERED UNDER THE UTAH POST-SECONDARY PROPRIETARY SCHOOL ACT (Title
          13, Chapter 34, Utah Code). Registration under the Utah Post-secondary
          Proprietary School Act does not mean that the State of Utah
          supervises, recommends, nor accredits the institution. It is the
          student’s responsibility to determine whether credits, degrees, or
          certificates from the institution will transfer to other institutions
          or meet employers’ training requirements. This may be done by calling
          the prospective school or employer.
        </Paragraph>
        <Paragraph>
          <Strong>Bottega LLC</Strong> is accredited by the Northwest
          Accreditation Commission | Cognia (AdvancED) as a post- secondary
          non-degree granting school. Cognia (AdvancED) is a private
          accreditation body and not recognized by the U.S. Department of
          Education. Additionally, Bottega LLC received CREDIT recommendation
          recognition through the American Council on Education (ACE) to provide
          Computer Science credit and transcript service through ACE.
          www.acenet.edu
        </Paragraph>
        <Paragraph>
          <Strong>
            The Career Training Division - Bottega LLC is NOT accredited by
            DEAC.
          </Strong>
        </Paragraph>

        <TrackSelection
          title="Mobile Development with React Native Certificate Programs"
          description="The student will learn to build a functional mobile app from scratch that runs on Android and iOS. A mobile app that is dynamic, full of modern features, and connected to an API provided by Bottega."
        />

        <TrackSelection
          isSelected={determineSelection(
            applicant.selectedTrack!,
            CheckoutableFormatTitle.online,
            "Mobile Development Certificate"
          )}
          title="No Prerequisites • Full-Time Online • Tuition 4,500 USD"
          description="Estimated at 220 hours (6** weeks Full-Time)"
        />

        <TrackSelection
          isSelected={determineSelection(
            applicant.selectedTrack!,
            CheckoutableFormatTitle.partTime,
            "Mobile Development Certificate"
          )}
          title="No Prerequisites • Part-Time Online • Tuition 3,500 USD"
          description="Estimated at 220 hours (15* weeks Part-Time)"
        />

        <TrackSelection
          isSelected={determineSelection(
            applicant.selectedTrack!,
            CheckoutableFormatTitle.partTimeForDevelopers,
            "Mobile Development Certificate"
          )}
          title="For Developers • Part-Time Online • Tuition 2,500 USD"
          description="Estimated at 160 hours (11* weeks Part-Time)"
        />

        <TrackSelection
          isSelected={determineSelection(
            applicant.selectedTrack!,
            CheckoutableFormatTitle.partTimeForReactDevelopers,
            "Mobile Development Certificate"
          )}
          title="For React Developers • Part-Time Online • Tuition 1,500 USD"
          description="Estimated at 105 hours (7* weeks Part-Time)"
        />

        <Paragraph
          style={{ ...styles.textSmall, ...styles.margins.top["1/2"] }}
        >
          * Part time schedule estimated time to completion based on a required
          minimum 15 hours per week for online study, test and quiz prep, time
          allotted to take tests and quizzes, course work, homework and time to
          build capstone. If a student dedicates more than 15 hours per week,
          completion time will be shorter.
          <Br />
          ** Time zone will vary. For a full-time remote student, be aware that
          all classes are in the Mountain Time Zone.
        </Paragraph>

        <TrackSelection
          title="Machine Learning and Data Science"
          description="Emphasis will be on hands-on development, going through exercises and workshops together. Prerequisites for the course: working knowledge of Python programming, undergraduate mathematics, and CLI experience."
        />

        <TrackSelection
          isSelected={determineSelection(
            applicant.selectedTrack!,
            FormatTitle.partTime,
            "Machine Learning and Data Science"
          )}
          title="Part-Time • Tuition 7,500 USD"
          description="Estimated at 120 hours"
        />
      </Page>
      <Page>
        <Separator text="* PART-TIME *" />

        <TrackSelection
          isSelected={determineSelection(
            applicant.selectedTrack!,
            FormatTitle.partTime,
            "Front End Development"
          )}
          title="Front End Development - Vue.js + React.js • Tuition 7,500 USD"
          subtitle="The Vue.js + React.js curriculum."
          description="The student will learn the foundation level of the main programming languages and completes industry equivalent entry-level software programming projects. Estimated at 600 hours (10 months, 40 weeks Part-Time)"
        />
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Coding Foundations"
          onInitialsSign={onInitialsSign}
        >
          <TrackSelection
            isSelected={true}
            title="Coding Foundations • Full-Time. Tuition 500 USD"
            subtitle="This tuition is included if the student chooses any of the programs listed above."
            description="The student will learn the basics of coding foundations. Estimated at 60 hours."
            courseCode="CS-100 (1 semester credit)"
          />
        </InitialsMark>

        <SectionHeader>Tuition Options</SectionHeader>
        <Paragraph>
          Students are encouraged to consult a financial advisor or secure their
          own financing to discover the best possible interest rate available
          based on individual credit. For questions, please contact Bottega LLC
          at 801.980.3664 for assistance.
        </Paragraph>
        <Paragraph>
          Bottega LLC accepts checks, credit cards, debit cards, and third-party
          financing by arrangement with Skillsfund, MACU, Leif, Climb, and
          possibly other partners from time-to-time.
        </Paragraph>
        <SectionHeader>Statement of Intent to Finance</SectionHeader>
        <View style={styles.margins.bottom}>
          <Option
            isSelected={
              applicant.selectedPaymentOption.category === "direct" &&
              applicant.selectedPaymentOption.title === "Pay-In-Full" &&
              !applicant.selectedPaymentOption.stripePaymentIntentId
            }
            title="I will pay the full tuition within 30 days (Full-Time Only*)."
          />
          <Option
            isSelected={
              applicant.selectedPaymentOption.category === "direct" &&
              applicant.selectedPaymentOption.title === "Installment Plan" &&
              !applicant.selectedPaymentOption.stripePaymentIntentId
            }
            title="I will pay monthly installments or 3 equal payments with full tuition paid by the end of the program. (Part-Time Only**)"
          />
          <Option
            isSelected={
              applicant.selectedPaymentOption.category !== "direct" &&
              !applicant.selectedPaymentOption.stripePaymentIntentId
            }
            title="I plan to finance the education program."
          />
        </View>
        <Paragraph>
          * For full-time students, the full tuition amount is due prior to
          starting the program unless further arrangements have been made
          between the student and Bottega LLC.
        </Paragraph>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Statement of Intent to Finance"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph>
            ** For part-time students, the full tuition amount is due by the end
            date of the program, paid in monthly installments or three (3) equal
            payments.
          </Paragraph>
        </InitialsMark>
      </Page>
      <Page>
        <SectionHeader>Statement of Tuition</SectionHeader>
        <Info
          label={
            <Text>
              Type of Financing
              <Br />
              (to be completed by Enrollment Specialist)
            </Text>
          }
          value={applicant.selectedPaymentOption.title}
        />
        <Info
          label="TUITION:"
          value={determineTuition(applicant.selectedTrack!)}
        />
        <Info label="APPLICATION FEE:" value={`$${0.0}`} />
        <Info label="DOWN PAYMENT:" value={`$${0.0}`} />
        <Info label="SCHOLARSHIP:" value={`$${0.0}`} />
        <Info
          label="TOTAL DUE:"
          value={determineTuition(applicant.selectedTrack!)}
        />
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Statement of Tuition"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph>I understand the total due for this program. </Paragraph>
        </InitialsMark>
      </Page>
      <Page>
        <SectionHeader>Terms of Use</SectionHeader>
        <Paragraph>
          Welcome to bottega.edu, the website and online service of Bottega
          University ("BU," "we," or "us"). This page explains the terms by
          which you may use our online and/or mobile services, web site, and
          software provided on or in connection with the service (collectively
          the "Service"). By accessing or using the Service, you signify that
          you have read, understood, and agree to be bound by this Terms of Use
          Agreement ("Agreement") and to the collection, use and disclosure of
          your personally identifiable information as set forth in the BU
          Privacy Policy, whether or not you are a registered user of our
          Service.
        </Paragraph>
        <Paragraph>
          We reserve the right to amend this Agreement at any time by notifying
          you as provided in this Agreement, provided that no notice shall be
          required for non-substantive changes to the Agreement. Your continued
          use of the Service after any such change constitutes your acceptance
          of the new Terms of Use. If you do not agree to any of these terms or
          any future Terms of Use, do not use or access (or continue to access)
          the Service. This Agreement applies to all visitors, users, and others
          who access the Service ("Users").
        </Paragraph>
        <Header>1. Use of Our Service</Header>
        <Paragraph>
          BU is an accredited United States university with online functionality
          and access. Our Service allow you to access our content and courses
          and interact with our faculty and other Students and Members via our
          adaptive online learning platform.
        </Paragraph>
        <Header>A. ELIGIBILITY</Header>
        <Paragraph>
          This Service is intended solely for Users who are thirteen (13) years
          of age or older, and any registration, use or access to the Service by
          anyone under thirteen (13) years or age is strictly prohibited and in
          violation of this Agreement. If you are undereighteen (18) years of
          age but at least thirteen (13) years of age you may use the Service
          only if you either are an emancipated minor or possess legal parental
          or guardian consent, and are fully able and competent to enter into
          the terms, conditions, obligations, affirmations, representations, and
          warranties set forth in this Agreement, and to abide by and comply
          with this Agreement. If you are a parent or guardian subscribing to
          the Service for the benefit of your child, please be aware that you
          are fully responsible for his or her use and conduct on the Service,
          including financial charges and legal liability that he or she may
          incur. The Service is not available to any Users previously removed
          from the Service by BU.
        </Paragraph>
        <Paragraph>
          Privacy Policy. Users cannot subscribe to or use the Service without
          accepting the terms of this Agreement and our Privacy Policy. The
          checking of the “I Agree” box at registration means you accept the
          terms of this Agreement and any documents which are referenced herein.
          This Agreement is a legal agreement between you on behalf of yourself
          and the minors to whom you grant usage privileges and BU. By checking
          the "I Agree" box, you are consenting to (a) BU’s use of the
          registration information to set- up your children’s logins and
          learning paths, (b) the collection and use of your child’s performance
          data to manage their learning path as described in this Agreement, (c)
          this Agreement and any documents reference therein; and (d) the terms
          and conditions of use and payment for the Service described in this
          Agreement.
        </Paragraph>
      </Page>
      <Page>
        <Header>B. PARENTAL CONSENT</Header>
        <Paragraph>
          I certify that I am over the age of 18 and the parent or legal
          guardian of the child that I am registering to use the Service. I
          agree that BU may use the information it collects during registration
          and from performance data directly from my child’s performance on
          learning activities, assessments, or otherwise to help BU formulate
          and manage my child’s learning path. Additionally, I agree that this
          data can be used, in an anonymous aggregated form. I understand that
          BU is not obligated to provide service to all and may limit its
          membership at its sole discretion for its commercial purposes.
        </Paragraph>
        <Header>C. BU ACCOUNTS</Header>
        <Paragraph>
          Your BU account gives you access to the services and functionality
          that we may establish and maintain from time to time and in our sole
          discretion. We may maintain different types of accounts for different
          types of Users. By connecting to BU with a third-party service, such
          as Facebook, you give us permission to access and use your information
          from that service as permitted by that service, and to store your
          log-in credentials for that service.
        </Paragraph>
        <Paragraph>
          You may never use another User’s account without permission. When
          creating your account, you must provide accurate and complete
          information. You are solely responsible for the activity that occurs
          on your account, and you must keep your account password secure. We
          encourage you to use “strong” passwords (passwords that use a
          combination of upper- and lower-case letters, numbers, and symbols)
          with your account. You must notify BU immediately of any breach of
          security or unauthorized use of your account. BU will not be liable
          for any losses caused by any unauthorized use of your account.
        </Paragraph>
        <Paragraph>
          You may control your User profile and how you interact with the
          Service by changing the settings in your Settings page. By providing
          BU your email address you consent to our using the email address to
          send you Service-related notices, including any notices required by
          law, in lieu of communication by postal mail. We may also use your
          email address to send you other messages, such as changes to features
          of the Service and special offers. If you do not want to receive such
          email messages, you may opt out or use of your account. BU will not be
          liable for any losses caused by any unauthorized use of your account.
        </Paragraph>
        <Paragraph>
          You may control your User profile and how you interact with the
          Service by changing the settings in your Settings page. By providing
          BU your email address you consent to our using the email address to
          send you Service-related notices, including any notices required by
          law, in lieu of communication by postal mail. We may also use your
          email address to send you other messages, such as changes to features
          of the Service and special offers. If you do not want to receive such
          email messages, you may opt out or change your preferences in your
          Settings page. Opting out may prevent you from receiving email
          messages regarding updates, improvements, or offers.
        </Paragraph>
        <Header>D. SERVICE RULES</Header>
        <Paragraph>
          You agree not to engage in any of the following prohibited activities:
          (i) copying, distributing, or disclosing any part of the Service in
          any medium, including without limitation by any automated or
          non-automated “scraping”; (ii) using any automated system, including
          without limitation “robots,” “spiders,” “offline readers,” etc., to
          access the Service in a manner that sends more request messages to the
          BU servers than a human can reasonably produce in the same period of
          time by using a conventional on- line web browser, ); (iii)
          transmitting spam, chain letters, or other unsolicited email; (iv)
          attempting to interfere with, compromise the system integrity or
          security or decipher any transmissions to or from the servers running
          the Service; (v) taking any action that imposes, or may impose at our
          sole discretion an unreasonable or disproportionately large load on
          our infrastructure;
        </Paragraph>
      </Page>
      <Page>
        <Paragraph>
          (vi) uploading invalid data, viruses, worms, or other software agents
          through the Service; (vii) collecting or harvesting any personally
          identifiable information, including account names, from the Service;
          (viii) using the Service for any commercial solicitation purposes;
          (ix) impersonating another person or otherwise misrepresenting your
          affiliation with a person or entity, conducting fraud, hiding or
          attempting to hide your identity; (x) interfering with the proper
          working of the Service; (xi) accessing any content on the Service
          through any technology or means other than those provided or
          authorized by the Service; or (xii) bypassing the measures we may use
          to prevent or restrict access to the Service, including without
          limitation features that prevent or restrict use or copying of any
          content or enforce limitations on use of the Service or the content
          therein.
        </Paragraph>
        <Paragraph>
          Accessing the audiovisual content available on the Service for any
          purpose or in any manner other than Streaming (as defined below) is
          expressly prohibited. “Streaming” means a contemporaneous digital
          transmission of an audiovisual work via the Internet from the BU
          Service to a User’s device in such a manner that the data is intended
          for real-time viewing and not intended to be copied, stored,
          permanently downloaded, or redistributed by the User.
        </Paragraph>
        <Paragraph>
          We may, without prior notice, change the Service; stop providing the
          Service or features of the Service, to you or to users generally; or
          create usage limits for the Service. We may permanently or temporarily
          terminate or suspend your access to the Service without notice and
          liability for any reason, including if in our sole determination you
          violate any provision of this Agreement, or for no reason. Upon
          termination for any reason or no reason, you continue to be bound by
          this Agreement. You are solely responsible for your interactions with
          other BU Users. We reserve the right, but have no obligation, to
          monitor disputes between you and other Users. BU shall have no
          liability for your interactions with other Users, or for any User’s
          action or inaction.
        </Paragraph>
        <SectionHeader>2. User Content</SectionHeader>
        <Paragraph>
          Some areas of the Service allow Users to post content such as profile
          information, comments, questions, and other content or information
          (any such materials a User submits, posts, displays, or otherwise
          makes available on the Service “User Content”). You retain ownership
          of your User Content.
        </Paragraph>
        <Paragraph>
          You agree not to post User Content that: (i) may create a risk of
          harm, loss, physical or mental injury, emotional distress, death,
          disability, disfigurement, or physical or mental illness to you, to
          any other person, or to any animal; (ii) may create a risk of any
          other loss or damage to any person or property; (iii) seeks to harm or
          exploit children by exposing them to inappropriate content, asking for
          personally identifiable details or otherwise; (iv) may constitute or
          contribute to a crime or tort; (v) contains any information or content
          that we deem to be unlawful, harmful, abusive, racially or ethnically
          offensive, defamatory, infringing, invasive of personal privacy or
          publicity rights, harassing, humiliating to other people (publicly or
          otherwise), libelous, threatening, profane, or otherwise
          objectionable; (vi) contains any information or content that is
          illegal (including, without limitation, the disclosure of insider
          information under securities law or of another party’s trade secrets);
          (vii) contains any information or content that you do not have a right
          to make available under any law or under contractual or fiduciary
          relationships; or (viii) contains any information or content that you
          know is not correct and current or (ix) violates any school or other
          applicable policy, including those related to cheating or ethics. You
          agree that any User Content that you post does not and will not
          violate third-party rights of any kind, including without limitation
          any Intellectual Property Rights (as defined below) or rights of
          privacy. To the extent that your User Content contains music, you
          hereby represent that you are the owner of all the copyright rights,
          including without limitation the performance, mechanical, and sound
          recordings rights, with respect to each and every musical composition
          (including lyrics) and sound recording contained in such User Content
          and have the power to grant the license granted below.
        </Paragraph>
      </Page>
      <Page>
        <Paragraph>
          BU reserves the right, but is not obligated, to reject and/or remove
          any User Content that BU believes, in its sole discretion, violates
          these provisions. You understand that publishing your User Content on
          the Service is not a substitute for registering it with the U.S.
          Copyright Office, the Writer’s Guild of America, or any other rights
          organization.
          <Br />
          For the purposes of this Agreement, “Intellectual Property Rights”
          means all patent rights, copyright rights, mask work rights, moral
          rights, rights of publicity, trademark, trade dress and
        </Paragraph>
        <Paragraph>
          <Strong>
            A. You have the written consent of each and every identifiable
            natural person in the User Content to use such person’s name or
            likeness in the manner contemplated by the Service and this
            Agreement, and each such person has released you from any liability
            that may arise in relation to such use.
          </Strong>
        </Paragraph>
        <Paragraph>
          <Strong>
            B. Your User Content and BU’s use thereof as contemplated by this
            Agreement and the Service will not violate any law or infringe any
            rights of any third party, including but not limited to any
            Intellectual Property Rights and privacy rights.
          </Strong>
        </Paragraph>
        <Paragraph>
          <Strong>
            C. BU may exercise the rights to your User Content granted under
            this Agreement without liability for payment of any residuals,
            payments, fees, or royalties payable under any collective bargaining
            agreement or otherwise.
          </Strong>
        </Paragraph>
        <Paragraph>
          <Strong>
            D. To the best of your knowledge, all your User Content and other
            information that you provide to us is truthful and accurate.
          </Strong>
        </Paragraph>
        <Paragraph>
          BU takes no responsibility and assumes no liability for any User
          Content that you or any other User or third-party posts or sends over
          the Service. You shall be solely responsible for your User Content and
          the consequences of posting or publishing it, and you agree that we
          are only acting as a passive conduit for your online distribution and
          publication of your User Content. You understand and agree that you
          may be exposed to User Content that is inaccurate, objectionable,
          inappropriate for children, or otherwise unsuited to your purpose, and
          you agree that BU shall not be liable for any damages you allege to
          incur as a result of User Content.
        </Paragraph>
        <SectionHeader>3. User Content License Grant</SectionHeader>
        <Paragraph>
          By posting any User Content on the Service, you expressly grant, and
          you represent and warrant that you have all rights necessary to grant,
          to BU a royalty free, sublicensable, transferable, perpetual,
          irrevocable, non-exclusive, worldwide license to use, reproduce,
          modify, publish, list information regarding, edit, translate,
          distribute, syndicate, publicly perform, publicly display, and make
          derivative works of all such User Content and your name, voice, and/or
          likeness as contained in your User Content, in whole or in part, and
          in any form, media or technology, whether now known or hereafter
          developed, for use in connection with the Service and BU’s (and its
          successors’ and affiliates’) business, including without limitation
          for promoting and redistributing part or all of the Service (and
          derivative works thereof) in any media formats and through any media
          channels. You also hereby grant each User of the Service a
          non-exclusive license to access your User Content through the Service,
          and to use, reproduce, distribute, display and perform such User
          Content as permitted through the functionality of the Service and
          under this Agreement. If the features of the Service allow you to
          remove or delete User Content from the Service, the above licenses
          granted by you in your User Content terminate within a commercially
          reasonable time after you remove or delete such User Content from the
          Service. You understand and agree, however, that BU may retain, but
          not display, distribute, or perform, server copies of User Content
          that have been removed or deleted. The above licenses granted by you
          in User Content for which the Service does not provide you a means to
          delete or remove are perpetual and irrevocable.
        </Paragraph>
      </Page>
      <Page>
        <SectionHeader>4. End User License Grant</SectionHeader>
        <SectionHeader>A. BU Service</SectionHeader>
        <Paragraph>
          Subject to the terms and conditions of this Agreement, you are hereby
          granted a non- exclusive, limited, non-transferable, freely revocable
          license to use the Service for your personal, noncommercial use only
          and as permitted by the features of the Service. BU reserves all
          rights not expressly granted herein in the Service and the BU Content
          (as defined below). BU may terminate this license at any time for any
          reason or no reason.
        </Paragraph>
        <SectionHeader>B. MOBILE SOFTWARE</SectionHeader>
        <Paragraph>
          We may make available software to access the Service via a mobile
          device (“Mobile Software”). To use the Mobile Software, you must have
          a mobile device that is compatible with the Mobile Service. BU does
          not warrant that the Mobile Software will be compatible with your
          mobile device. BU hereby grants you a non-exclusive, non-
          transferable, revocable license to use a compiled code copy of the
          Mobile Software for one BU account on one mobile device owned or
          leased solely by you, for your personal use. You may not: (i) modify,
          disassemble, decompile or reverse engineer the Mobile Software, except
          to the extent that such restriction is expressly prohibited by law;
          (ii) rent, lease, loan, resell, sublicense, distribute or otherwise
          transfer the Mobile Software to any third party or use the Mobile
          Software to provide time sharing or similar services for any third
          party; (iii) make any copies of the Mobile Software; (iv) remove,
          circumvent, disable, damage or otherwise interfere with
          security-related features of the Mobile Software, features that
          prevent or restrict use or copying of any content accessible through
          the Mobile Software, or features that enforce limitations on use of
          the Mobile Software; or (v) delete the copyright and other proprietary
          rights notices on the Mobile Software. You acknowledge that BU may
          from time to time issue upgraded versions of the Mobile Software and
          may automatically electronically upgrade the version of the Mobile
          Software that you are using on your mobile device. You consent to such
          automatic upgrading on your mobile device and agree that the terms and
          conditions of this Agreement will apply to all such upgrades. Any
          third-party code that may be incorporated in the Mobile Software is
          covered by the applicable open source or third-party license EULA, if
          any, authorizing use of such code. The foregoing license grant is not
          a sale of the Mobile Software or any copy thereof, and BU or its
          third-party partners or suppliers retain all right, title, and
          interest in the Mobile Software (and any copy thereof). Any attempt by
          you to transfer any of the rights, duties or obligations hereunder,
          except as expressly provided for in this Agreement, is void. BU
          reserves all rights not expressly granted under this Agreement. If the
          Mobile Software is being acquired on behalf of the United States
          Government, then the following provision applies. Use, duplication, or
          disclosure of the Mobile Software by the U.S. Government is subject to
          restrictions set forth in this Agreement and as provided in DFARS
          227.7202-1(a) and 227.7202-3(a) (1995), DFARS 252.227-7013(c)(1)(ii)
          (OCT 1988), FAR 12.212(a) (1995), FAR 52.227-19, or FAR 52.227-14 (ALT
          III), as applicable. The Mobile Software originates in the United
          States and is subject to United States export laws and regulations.
          The Mobile Software may not be exported or re-exported to certain
          countries or those persons or entities prohibited from receiving
          exports from the United States. In addition, the Mobile Software may
          be subject to the import and export laws of other countries. You agree
          to comply with all United States and foreign laws related to use of
          the Mobile Software and the BU Service.
        </Paragraph>
      </Page>
      <Page>
        <SectionHeader>5. MOBILE SOFTWARE FROM iTUNES</SectionHeader>
        <Paragraph>
          The following applies to any Mobile Software you acquire from the
          iTunes Store (“iTunes-Sourced Software”): You acknowledge and agree
          that this Agreement is solely between you and BU, not Apple, and that
          Apple has no responsibility for the iTunes- Sourced Software or
          content thereof. Your use of the iTunes-Sourced Software must comply
          with the App Store Terms of Service. You acknowledge that Apple has no
          obligation whatsoever to furnish any maintenance and support services
          with respect to the iTunes-Sourced Software. In the event of any
          failure of the iTunes Sourced Software to conform to any applicable
          warranty, you may notify Apple, and Apple will refund the purchase
          price for the iTunes-Sourced Software to you; to the maximum extent
          permitted by applicable law, Apple will have no other warranty
          obligation whatsoever with respect to the iTunes-Sourced Software, and
          any other claims, losses, liabilities, damages, costs or expenses
          attributable to any failure to conform to any warranty will be solely
          governed by this Agreement and any law applicable to BU as provider of
          the software. You acknowledge that Apple is not responsible for
          addressing any claims of you or any third party relating to the
          iTunes-Sourced Software or your possession and/or use of the
          iTunes-Sourced Software, including, but not limited to: (i) product
          liability claims; (ii) any claim that the iTunes-Sourced Software
          fails to conform to any applicable legal or regulatory requirement;
          and (iii) claims arising under consumer protection or similar
          legislation; and all such claims are governed solely by this Agreement
          and any law applicable to BU as provider of the software. You
          acknowledge that, in the event of any third-party claim that the
          iTunes-Sourced Software or your possession and use of that
          iTunes-Sourced Software infringes that third party’s intellectual
          property rights, BU, not Apple, will be solely responsible for the
          investigation, defense, settlement and discharge of any such
          intellectual property infringement claim to the extent required by
          this Agreement. You and BU acknowledge your acceptance of the terms
          and conditions of this Agreement, Apple will have the right (and will
          be deemed to have accepted the right) to enforce this Agreement as
          relates to your license of the iTunes-Sourced Software against you as
          a third party beneficiary thereof.
        </Paragraph>
        <SectionHeader>6. Our Proprietary Rights</SectionHeader>
        <Paragraph>
          Except for your User Content, the Service and all materials therein or
          transferred thereby, including, without limitation, software, images,
          text, graphics, illustrations, logos, patents, trademarks, service
          marks, copyrights, photographs, audio, videos, music, and User Content
          belonging to other Users (the “BU Content”), and all Intellectual
          Property Rights related thereto, are the exclusive property of BU and
          its licensors (including other Users who post User Content to the
          Service). Except as explicitly provided herein, nothing in this
          Agreement shall be deemed to create a license in or under any such
          Intellectual Property Rights, and you agree not to sell, license,
          rent, modify, distribute, copy, reproduce, transmit, publicly display,
          publicly perform, publish, adapt, edit or create derivative works from
          any BU Content. Use of the BU Content for any purpose not expressly
          permitted by this Agreement is strictly prohibited. You may choose to,
          or we may invite you to submit comments or ideas about the Service,
          including without limitation about how to improve the Service or our
          products (“Ideas”). By submitting any Idea, you agree that your
          disclosure is gratuitous, unsolicited and without restriction and will
          not place BU under any fiduciary or other obligation, and that we are
          free to use the Idea without any additional compensation to you,
          and/or to disclose the Idea on a non-confidential basis or otherwise
          to anyone. You further acknowledge that, by acceptance of your
          submission, BU does not waive any rights to use similar or related
          ideas previously known to BU, or developed by its employees, or
          obtained from sources other than you. User shall promptly notify BU if
          User becomes aware of any infringement of the BU's Intellectual
          Property Rights in the BU Content and fully cooperate with BU in any
          legal action taken by BU to enforce its Intellectual Property Rights.
        </Paragraph>
      </Page>
      <Page>
        <SectionHeader>7. Paid Services</SectionHeader>
        <Header>A. BILLING POLICIES</Header>
        <Paragraph>
          Certain aspects of the Service may be provided for a fee or other
          charge. If you elect to use paid aspects of the Service, you agree to
          the pricing and payment terms, as we may update them from time to
          time. BU may add new services for additional fees and charges, or
          amend fees and charges for existing services, at any time in its sole
          discretion. If you purchase certain aspects of the Service from a
          third-party payment processor or a social networking website such as
          Facebook, you are agreeing to that website’s payment terms and BU in
          not a party to the transaction.
        </Paragraph>
        <Header>B. NO REFUNDS</Header>
        <Paragraph>
          You may cancel your BU account at any time; however, there are no
          refunds for cancellation; however, refunds for tuition for enrolled
          students are governed by the BU Catalog. In the event that BU suspends
          or terminates your account or this Agreement for your breach of this
          Agreement, you understand and agree that you shall receive no refund
          or exchange for any BU Property, any unused time on a subscription,
          any license or subscription fees for any portion of the Service, any
          content or data associated with your account, or for anything else. If
          the Service was purchased from a social networking website, the refund
          policy is subject to that social networking website’s payment terms
          and conditions.
        </Paragraph>
        <Header>C. PAYMENT INFORMATION; TAXES</Header>
        <Paragraph>
          All information that you provide in connection with a purchase or
          transaction or other monetary transaction interaction with the Service
          must be accurate, complete, and current. You agree to pay all charges
          incurred by users of your credit card, debit card, or other payment
          method used in connection with a purchase or transaction or other
          monetary transaction interaction with the Service at the prices in
          effect when such charges are incurred. You will pay any applicable
          taxes, if any, relating to any such purchases, transactions, or other
          monetary transaction interactions.
        </Paragraph>
        <Header>D. PAYMENTS</Header>
        <Paragraph>
          Unless otherwise agreed by the parties in writing, BU shall remit
          payments due to you hereunder no later than thirty (30) days after the
          end of each calendar month in which the applicable fees are received.
          Payment shall be in the form you select when you register for the
          Service, or as subsequently updated as permitted by the Service.
          Payments shall only be made in those months in which the amount due to
          you totals at least $5. Unpaid amounts due shall accrue until the next
          month in which the amount due is at least $5. BU reserves the right to
          withhold payment or charge back to your account any amounts otherwise
          due to us under this Agreement or amounts due to any breach of this
          Agreement by you, pending BU’s reasonable investigation of such
          breach. To ensure proper payment, you are solely responsible for
          providing and maintaining accurate contact and payment information
          associated with your account, which includes without limitation
          applicable tax information. If we believe that we are obligated to
          obtain tax information and you do not provide this information to us
          after we have requested it, we may withhold your payments until you
          provide this information or otherwise satisfy us that you are not a
          person or entity from whom we are required to obtain tax information.
          Any third-party fees related to returned or cancelled payments due to
          a contact or payment information error or omission may be deducted
          from the newly issued payment.
        </Paragraph>
      </Page>
      <Page>
        <Paragraph>
          You agree to pay all applicable taxes or charges imposed by any
          government entity in connection with your participation in the
          Service. If you dispute any payment made hereunder, you must notify BU
          in writing within thirty (30) days of such payment. Failure to so
          notify BU shall result in the waiver by you of any claim relating to
          such disputed payment. Payment shall be calculated solely based on
          records maintained by BU. No other measurements or statistics of any
          kind shall be accepted by BU or have any effect under this Agreement.
          We may withhold any taxes or other amounts from payments due to you as
          required by law.
        </Paragraph>

        <SectionHeader>8. Privacy</SectionHeader>
        <Paragraph>
          We care about the privacy of our Users. We collect, use, and share
          personally identifiable information and non-personally identifiable
          information as set forth in our Privacy Policy. You understand that by
          using the Services you consent to the collection of such information,
          and to have your personal data collected, used, transferred to and
          processed in the United States.
        </Paragraph>
        <SectionHeader>9. Security</SectionHeader>
        <Paragraph>
          BU uses commercially reasonable physical, managerial, and technical
          safeguards to preserve the integrity and security of your personal
          information and implement your profile settings. However, we cannot
          guarantee that unauthorized third parties will never be able to defeat
          our security measures or use your personal information for improper
          purposes. You acknowledge that you provide your personal information
          at your own risk.
        </Paragraph>
        <SectionHeader>10. DMCA Notice</SectionHeader>
        <Paragraph>
          Since we respect artist and content owner rights, it is BU’s policy to
          respond to alleged infringement notices that comply with the Digital
          Millennium Copyright Act of 1998 (“DMCA”). If you believe that your
          copyrighted work has been copied in a way that constitutes copyright
          infringement and is accessible via the Service, please notify BU’s
          copyright agent as set forth in the DMCA. For your complaint to be
          valid under the DMCA, you must provide the following information in
          writing:
        </Paragraph>
        <IndexedList>
          <ListItem>
            <Text>
              An electronic or physical signature of a person authorized to act
              on behalf of the copyright owner;
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Identification of the copyrighted work that you claim has been
              infringed;
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Identification of the material that is claimed to be infringing
              and where it is located on the Service;
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Information reasonably sufficient to permit BU to contact you,
              such as your address, telephone number, and e-mail address;
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              A statement that you have a good faith belief that use of the
              material in the manner complained of is not authorized by the
              copyright owner, its agent, or law; and
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              A statement, made under penalty of perjury, that the above
              information is accurate,and that you are the copyright owner or
              are authorized to act on behalf of the owner. The above
              information must be submitted to the following:
            </Text>
          </ListItem>
        </IndexedList>
        <Paragraph>
          Bottega University 50 W. Broadway, Suite 300, Salt Lake City, UT 84101
          +1(385) 200-9292
        </Paragraph>
        <Paragraph>
          UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT ONLINE MATERIAL
          IS INFRINGING, YOU MAY BE SUBJECT TO CRIMINAL PROSECUTION FOR PERJURY
          AND CIVIL PENALTIES, INCLUDING MONETARY DAMAGES, COURT COSTS, AND
          ATTORNEYS’ FEES.
        </Paragraph>
      </Page>
      <Page>
        <Paragraph>
          Please note that this procedure is exclusively for notifying BU and
          its affiliates that your copyrighted material has been infringed. The
          preceding requirements are intended to comply with BU’s rights and
          obligations under the DMCA, including 17 U.S.C. §512(c), but do not
          constitute legal advice. It may be advisable to contact an attorney
          regarding your rights and obligations under the DMCA and other
          applicable laws.
        </Paragraph>
        <Paragraph>
          In accordance with the DMCA and other applicable law, BU has adopted a
          policy of terminating, in appropriate circumstances, Users who are
          deemed to be repeat infringers. BU may also at its sole discretion
          limit access to the Service and/or terminate the accounts of any Users
          who infringe any intellectual property rights of others, whether there
          is any repeat infringement.
        </Paragraph>
        <SectionHeader>11. Third-Party Links</SectionHeader>
        <Paragraph>
          The Service may contain links to third-party websites, advertisers,
          services, special offers, or other events or activities that are not
          owned or controlled by BU. BU does not endorse or assume any
          responsibility for any such third-party sites, information, materials,
          products, or services. If you access a third-party website from the
          Service, you do so at your own risk, and you understand that this
          Agreement and BU’s Privacy Policy do not apply to your use of such
          sites. You expressly relieve BU from any and all liability arising
          from your use of any third-party website, service, or content.
          Additionally, your dealings with or participation in promotions of
          advertisers found on the Service, including payment and delivery of
          goods, and any other terms (such as warranties) are solely between you
          and such advertisers. You agree that BU shall not be responsible for
          any loss or damage of any sort relating to your dealings with such
          advertisers.
        </Paragraph>
        <SectionHeader>12. Indemnity</SectionHeader>
        <Paragraph>
          You agree to defend, indemnify and hold harmless BU and its
          subsidiaries, agents, licensors, managers, and other affiliated
          companies, and their employees, contractors, agents, officers and
          directors, from and against any and all claims, damages, obligations,
          losses, liabilities, costs or debt, and expenses (including but not
          limited to attorney’s fees) arising from: (i) your use of and access
          to the Service, including any data or content transmitted or received
          by you; (ii) your violation of any term of this Agreement, including
          without limitation your breach of any of the representations and
          warranties above; (iii) your violation of any third-party right,
          including without limitation any right of privacy or Intellectual
          Property Rights; (iv) your violation of any applicable law, rule or
          regulation; (v) any claim for damages that arise as a result of any of
          your User Content or any that is submitted via your account; or (vi)
          any other party’s access and use of the Service with your unique
          username, password or other appropriate security code.
        </Paragraph>
        <SectionHeader>13. No Warranty</SectionHeader>
        <Paragraph>
          THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF
          THE SERVICE IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY
          APPLICABLE LAW, THE SERVICE IS PROVIDED WITHOUT WARRANTIES OF ANY
          KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, BU, ITS
          SUBSIDIARIES, AND ITS LICENSORS DO NOT WARRANT THAT THE CONTENT IS
          ACCURATE, RELIABLE OR CORRECT;
        </Paragraph>
      </Page>
      <Page>
        <Paragraph>
          THAT THE SERVICE WILL MEET YOUR REQUIREMENTS; THAT THE SERVICE WILL BE
          AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE;
          THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE SERVICE IS
          FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR
          OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DOWNLOADED AT
          YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO
          YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS
          FROM SUCH DOWNLOAD OR YOUR USE OF THE SERVICE. BU DOES NOT WARRANT,
          ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
          SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE BU SERVICE
          OR ANY HYPERLINKED WEBSITE OR SERVICE, AND BU WILL NOT BE A PARTY TO
          OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY
          PROVIDERS OF PRODUCTS OR SERVICES.
        </Paragraph>
        <SectionHeader>14. Limitation of Liability</SectionHeader>
        <Paragraph>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
          BU, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS OR
          LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
          SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT
          LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR
          RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY RESULTING FROM HACKING,
          TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE SERVICE OR YOUR
          ACCOUNT OR THE INFORMATION CONTAINED THEREIN.
        </Paragraph>
        <Paragraph>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, BU ASSUMES NO
          LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR
          INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF
          ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF OUR
          SERVICE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
          AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY
          INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICE; (V)
          ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED
          TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY; (VI) ANY ERRORS OR
          OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE IBURRED AS A RESULT
          OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE
          MADE AVAILABLE THROUGH THE SERVICE; AND/OR (VII) USER CONTENT OR THE
          DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY. IN NO
          EVENT SHALL BU, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES,
          SUPPLIERS, OR LICENSORS BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS,
          LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT
          EXCEEDING THE AMOUNT YOU PAID TO BU HEREUNDER. THIS LIMITATION OF
          LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON
          CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN
          IF BU HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE
          FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT
          PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. SOME STATES DO NOT
          ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR THE EXCLUSION OR
          LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
          LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. THIS AGREEMENT GIVES
          YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH
          VARY FROM STATE TO STATE. THE DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS
          OF LIABILITY UNDER THIS AGREEMENT WILL NOT APPLY TO THE EXTENT
          PROHIBITED BY APPLICABLE LAW.
        </Paragraph>
      </Page>
      <Page>
        <Paragraph>
          The Service is controlled and operated from its facilities in the
          United States. BU makes no representations that the Service is
          appropriate or available for use in other locations. Those who access
          or use the Service from other jurisdictions do so at their own
          volition and are entirely responsible for compliance with all
          applicable United States and local laws and regulations, including but
          not limited to export and import regulations. You may not use the
          Service if you are a resident of a country embargoed by the United
          States, or are a foreign person or entity blocked or denied by the
          United States government. Unless otherwise explicitly stated, all
          materials found on the Service are solely directed to individuals,
          companies, or other entities located in the United States.
        </Paragraph>
        <SectionHeader>15. Term and Termination</SectionHeader>
        <Paragraph>
          This Agreement and the license granted hereunder shall remain in
          effect until terminated as set forth herein (the "Term"). User may
          terminate this Agreement by ceasing to use and destroying all copies
          of pertaining to use of BU online. BU may terminate this Agreement,
          effective upon written notice to User, if User, breaches this
          Agreement and such breach: (i) is incapable of cure; or (ii) being
          capable of cure, remains uBUred ten (10) days after BU provides
          written notice thereof. Upon expiration or termination of this
          Agreement, the license granted hereunder shall also terminate, and
          Licensee shall cease using and destroy all copies pertaining to use of
          BU online related Documentation. No expiration or termination shall
          affect User's obligation to pay all User Fees and Support Fees that
          may have become due before such expiration or termination, or entitle
          User to any refund, in each case except as set forth in Section 13.
        </Paragraph>
        <SectionHeader>16. Assignment</SectionHeader>
        <Paragraph>
          This Agreement, and any rights and licenses granted hereunder, may not
          be transferred or assigned by you, but may be assigned by BU without
          restriction. Any attempted transfer or assignment in violation hereof
          shall be null and void.
        </Paragraph>
        <SectionHeader>17. General</SectionHeader>
        <Header>A. GOVERNING LAW</Header>
        <Paragraph>
          You agree that: (i) the Service shall be deemed solely based in Utah;
          and (ii) the Service shall be deemed a passive one that does not give
          rise to personal jurisdiction over BU, either specific or general, in
          jurisdictions other than Utah. This Agreement shall be governed by the
          internal substantive laws of the State of Utah, without respect to its
          conflict of laws principles. The application of the United Nations
          Convention on Contracts for the International Sale of Goods is
          expressly excluded. Except as otherwise agreed between the parties or
          as described in Section 15.B. below, any claim or dispute between you
          and BU that arises in whole or in part from the Service shall be
          decided exclusively by a court of competent jurisdiction located in
          Salt Lake City, Utah, unless submitted to arbitration as set forth in
          the following paragraph.
        </Paragraph>
      </Page>
      <Page>
        <Header>B. ARBITRATION</Header>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Terms Of Use Arbitration"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph>
            For any claim (excluding claims for injunctive or other equitable
            relief) under this Agreement, the party requesting relief may elect
            to resolve the dispute through binding non-appearance-based
            arbitration. The party electing such arbitration shall initiate the
            arbitration through an established alternative dispute resolution
            (“ADR”) provider mutually agreed upon by the parties. The ADR
            provider and the parties must comply with the following rules: a)
            the arbitration shall be conducted by telephone, online and/ or be
            solely based on written submissions, as selected by the party
            initiating the arbitration; b) the arbitration shall not involve any
            personal appearance by the parties or witnesses unless otherwise
            mutually agreed by the parties; and c) any judgment on the award
            rendered by the arbitrator may be entered in any court of competent
            jurisdiction.
          </Paragraph>
        </InitialsMark>
        <Header>C. NOTIFICATION PROCEDURES</Header>
        <Paragraph>
          BU may provide notifications, whether such notifications are required
          by law or are for marketing or other business-related purposes, to you
          via email notice, written or hard copy notice, or through posting of
          such notice on our website, as determined by BU in our sole
          discretion. BU reserves the right to determine the form and means of
          providing notifications to our Users, provided that you may opt out of
          certain means of notification as described in this Agreement. BU is
          not responsible for any automatic filtering you or your network
          provider may apply to email notifications we send to the email address
          you provide us.
        </Paragraph>
        <Header>D. ENTIRE AGREEMENT / SEVERABILITY</Header>
        <Paragraph>
          This Agreement, together with any amendments and any additional
          agreements you may enter into with BU in connection with the Service,
          shall constitute the entire agreement between you and BU concerning
          the Service. If any provision of this Agreement is deemed invalid by a
          court of competent jurisdiction, the invalidity of such provision
          shall not affect the validity of the remaining provisions of this
          Agreement, which shall remain in full force and effect.
        </Paragraph>
        <Header>E. NO WAIVER</Header>
        <Paragraph>
          No waiver of any term of this Agreement shall be deemed a further or
          continuing waiver of such term or any other term, and BU’s failure to
          assert any right or provision under this Agreement shall not
          constitute a waiver of such right or provision.
        </Paragraph>
        <Header>F. CALIFORNIA USERS AND RESIDENTS</Header>

        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Terms Of Use"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph>
            If any complaint with us is not satisfactorily resolved, you and
            contact the Complaint Assistance Unit of the Division of Consumer
            Services of the California Department of Consumer Affairs in writing
            at 1625 North Market Blvd., Suite N 112, Sacramento, California
            95834 or by telephone at (800) 952-5210 or (916) 445-1254.
          </Paragraph>
        </InitialsMark>
      </Page>
      <Page>
        <SectionHeader>Privacy Policy</SectionHeader>
        <Header>Website</Header>
        <Header>What We Collect and Why</Header>
        <Paragraph>
          Bottega University collects some anonymous information each time you
          visit its website, so it can improve the overall quality of your
          online experience. It collects your IP address, referral data, and
          browser and platform type. You do not have to register with Bottega
          University before we can collect this anonymous information.
        </Paragraph>
        <Paragraph>
          Bottega University website does not require you to share information
          that identifies you personally, such as your name or e-mail address,
          for you to use the website. The Bottega University website may assign
          an anonymous ID number to your request(s) and links the following
          additional data to that number: the date and time you visited the
          website, your search terms, and the links upon which you choose to
          click. Like most standard website servers, we use log files to collect
          and store this anonymous user information. Bottega University analyzes
          the information to examine trends, administer the site, track user’s
          movement in the aggregate, and gather broad demographic information
          for aggregate use.
        </Paragraph>
        <Paragraph>
          From time-to-time the Bottega University website requests information
          from you that may identify you personally in addition to the anonymous
          information described above. The requested information typically
          includes contact information (such as name, e-mail address, shipping
          address, and zip code), and demographic information (such as age,
          gender, occupation, and household income). Bottega University will not
          use or disclose your individual user information except as described
          in this privacy policy and without first obtaining your consent.
        </Paragraph>
        <Header>Cookies</Header>
        <Paragraph>
          Bottega University may set and access Bottega University or partner
          cookies on your computer. A cookie is a small data file that a website
          may send to your browser and which may then be stored on your system.
          We may occasionally use both session cookies and persistent cookies.
          For the session cookie, once you close the browser, the cookie simply
          terminates. A persistent cookie is a small text file stored on your
          hard drive for an extended period of time. Persistent cookies can be
          removed by following Internet browser help file directions.
        </Paragraph>
        <Paragraph>
          We use cookie and web beacon technology to track which pages on the
          Bottega University websites our visitors view and which Web browsers
          our visitors use. We may also use cookies to help us provide
          personalized features and preferences to you within various Bottega
          University websites in a way that may be linked to individual user
          information that you elect to share with us. We may also use “action
          tags” to assist in delivering the cookie. These action tags allow us
          to compile statistics and other data about our customers on an
          aggregate basis, by embedding a random identifying number on each
          user’s browser. Then we track where that number shows up on our site.
        </Paragraph>
      </Page>
      <Page>
        <Header>
          Special Notification with respect to Children’s Privacy (Users under
          the age of 13)
        </Header>
        <Paragraph>
          In response to concerns about protecting children’s privacy online,
          Congress enacted the Children’s Online Privacy Protection Act of 1998
          (“COPPA”), which sets forth rules and procedures governing the ways in
          which websites may collect, use and disclose any personal information
          for children under the age of 13. In accordance with Bottega
          University policy and COPPA regulations, we DO NOT:
        </Paragraph>

        <UnsortedList>
          <ListItem>
            <Text>
              Request or knowingly collect personally identifiable information
              online or offline contact information from users under 13 years of
              age; or knowingly use or share personal information from users
              under 13 years of age with third parties.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              It is possible that by fraud or deception we may receive
              information given to us or pertaining to children under 13. If we
              are notified of this, as soon as we verify the information, we
              will immediately delete the information from our servers.
            </Text>
          </ListItem>
        </UnsortedList>

        <Header>Choice/Opt-out</Header>
        <Paragraph>
          We desire to keep you in control of the personal information you
          provide to us. Accordingly, you can review, correct, change or remove
          the personal registration information you provide to Bottega
          University and that Bottega University controls.
        </Paragraph>
        <Paragraph>
          If you do not want to receive e-mail from us in the future, please let
          us know by sending us email at: support@bottega.edu
        </Paragraph>
        <Paragraph>
          If you supply us with your postal address online, you may receive
          periodic mailings from us with information on new products and
          services or upcoming events. If you do not wish to receive such
          mailings, please let us know by calling us at (801)883-8336.
        </Paragraph>
        <Paragraph>
          Persons who supply us with their telephone numbers online will only
          receive telephone contact from us with information regarding applying
          to the University. If you would not like to be contacted, please
          provide us with your name and phone number to ensure that we remove
          the correct person from the list.
        </Paragraph>
        <Header>Sharing</Header>
        <Paragraph>
          Bottega University will make every effort to preserve your privacy.
          Bottega University may need to disclose personal information when
          required by law wherein we have a good-faith belief that such action
          is necessary to comply with a current judicial proceeding, a criminal
          investigation, a court order, or legal process served on our website.
          We may also share your personal information if we believe it is
          necessary in order to investigate, prevent, or take action regarding
          illegal activities, suspected fraud, situations involving potential
          threats to the physical safety of any person, violations of Bottega
          University or such Bottega University websites’ terms of use, or as
          otherwise required by law.
        </Paragraph>
      </Page>
      <Page>
        <Header>Legal Notice</Header>
        <Paragraph>
          Notwithstanding any language to the contrary, nothing contained herein
          constitutes nor is intended to constitute an offer, inducement,
          promise, or contract of any kind. The data contained herein is for
          informational purposes only and is not represented to be error free.
          Any links to information not owned by Bottega University and/or its
          affiliates are provided as a courtesy. They are not intended to, nor
          do they constitute an endorsement by Bottega University and/or its
          affiliates of the linked materials, products, services, or information
          contained therein. Bottega University and/or its affiliates do not
          have any control over the privacy practices of these websites or
          information, and all visitors of these websites should read and
          understand the policies and privacy practices for external sites.
        </Paragraph>
        <Header>Copyright Infringement</Header>
        <Paragraph>
          Bottega University is committed to complying with US copyright law and
          respecting the rights of copyright holders, while simultaneously
          defending the academic freedom of faculty and providing the optimal
          learning experience for students. Faculty and staff will make a good
          faith effort to only use or distribute third-party copyrighted
          materials as allowed by purchase, license, fair use, or other legal
          provision. Anyone who participates in the unauthorized distribution of
          copyrighted material may be subject to civil and criminal liabilities,
          in addition to sanctions imposed by Bottega University. For more
          information, please see the website of the US Copyright Office at
          www.copyright.gov, and the related frequently asked questions at
          www.copyright.gov/help/faq. Pursuant to 17 U.S.C. Sec. 512(c)(2),
          notice of claims of copyright infringement should be directed to
          support@bottega.edu.
        </Paragraph>
        <Header>
          Lawful Basis for Collecting and Processing of Personal Data
        </Header>
        <Paragraph>
          In order for educational institutions to educate their students both
          in class and on-line, it is essential and necessary that they have
          lawful bases to collect, process, use, and maintain data for its
          student, employees, applicants, and others involved in its educational
          programs. The lawful bases include, without limitation, admission,
          registration, delivery of classroom, on-line, and study abroad
          education, grades, communications, employment, program analysis for
          improvements, and records retention. Examples of data that Bottega
          University and/or its affiliates may need to collect in connection
          with the lawful bases are name, email address, IP address, physical
          address or other location identifier, photos, as well as some
          sensitive personal data obtained with prior consent.
        </Paragraph>
        <Paragraph>
          Most of the collection and processing of personal data will include:
        </Paragraph>
        <UnsortedList>
          <ListItem>
            <Text>
              Processing necessary for the purposes of the legitimate interests
              pursued by Bottega University and/or its affiliates or third
              parties in providing educational opportunities and employment.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Processing necessary for the performance of a contract to which
              the subject is party or in order to take steps at the request of
              the data subject prior to entering into a contract.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Processing necessary for compliance with a legal obligation to
              which Bottega University and/or any of its affiliates is subject.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              The data subject has given consent to the processing of his or her
              personal data for one or more specific purposes. This lawful basis
              pertains primarily but not exclusively to the protection of
              research subjects.
            </Text>
          </ListItem>
        </UnsortedList>
        <Paragraph>
          There will be some instances where the collection and processing of
          personal data will be pursuant to other lawful bases.
        </Paragraph>
      </Page>
      <Page>
        <Header>Types of Personal Data Collected and Why</Header>
        <Paragraph>
          Bottega University and/or its affiliates collect a variety of personal
          and sensitive data to meet one of its lawful bases, as referenced
          above. Most often the data is used for admission, registration,
          delivery of classroom, on-line, and study abroad education, grades,
          communications, employment, program analysis for improvements, and
          records retention. If you have specific questions regarding the
          collection and use of your personal data, please contact
          support@bottega.edu.
        </Paragraph>
        <Paragraph>
          If a data subject refuses to provide personal data that is required,
          such refusal may make it impossible for Bottega University and/or its
          affiliates to provide education, employment, research or other
          requested services.
        </Paragraph>
        <Paragraph>
          Personal data typically includes, but is not limited to:
        </Paragraph>
        <Table>
          <Row small>
            <Text>
              <Strong>Data Type</Strong>
            </Text>
            <Text>
              <Strong>Source</Strong>
            </Text>
          </Row>
          <Row small>
            <Text>
              First name, last name, address, and any other contact information
              that can directly or indirectly identify the data subject.
            </Text>
            <Text>
              Collected directly from you, the data subject, through:
              application forms, online forms, emails, phone calls, chats, SMS,
              shared files.
            </Text>
          </Row>
        </Table>

        <Header>Individual Rights of the Data Subject under the EU GDPR</Header>
        <Paragraph>
          Individual data subjects covered by Bottega University Privacy Policy
          will be afforded the following rights:
        </Paragraph>
        <UnsortedList>
          <ListItem>
            <Text>
              Right of access – you have the right to request a copy of the
              information that we hold about you.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Right of rectification – you have a right to correct data that we
              hold about you that is inaccurate or incomplete.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Right to be forgotten – in certain circumstances you can ask for
              the data we hold about you to be erased from our records.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Right to restriction of processing – where certain conditions
              apply to have a right to restrict the processing.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Right of portability – you have the right to have the data we hold
              about you transferred to another organization.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Right to object – you have the right to object to certain types of
              processing such as direct marketing.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Right to object to automated processing, including profiling – you
              also have the right to be subject to the legal effects of
              automated processing or profiling.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Right to judicial review – if Bottega University refuses your
              request under rights of access, we will provide you with a reason
              as to why.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Right to complain – if you wish to make a complaint about how your
              personal data is being processed by Bottega University.
            </Text>
          </ListItem>
        </UnsortedList>
        <Paragraph>
          All personal data and sensitive personal data collected or processed
          by Bottega University and/or its affiliates under the scope of the
          Bottega University Privacy Policy must comply with the security
          controls and safeguards of FERPA, GLBA, and PCI as set forth in the
          Bottega University Privacy Policy.
        </Paragraph>
        <Header>Retention Period</Header>
        <Paragraph>
          Bottega University and/or its affiliates will process and store
          personal data for no longer than it is necessary for the specified
          above purpose(s).
        </Paragraph>
      </Page>
      <Page>
        <Header>Policy Updates</Header>
        <Paragraph>
          Bottega University and/or its affiliates reserves the right to modify
          this privacy policy at any time. We encourage visitors to frequently
          check this page for any changes to this Privacy Policy. If we make
          changes, we will post an updated effective date below. Your continued
          use of this site after any change in this Privacy Policy will
          constitute your acceptance of such change. This policy has been
          updated on April 9, 2019.
        </Paragraph>

        <Header>Contact:</Header>
        <InitialsMark
          applicant={applicant}
          initials={initials}
          label="Privacy Policy"
          onInitialsSign={onInitialsSign}
        >
          <Paragraph>
            Bottega University
            <Br />
            50 W. Broadway, Suite 300
            <Br />
            Salt Lake City, UT 84101
            <Br />
            support@bottega.edu
          </Paragraph>
        </InitialsMark>
      </Page>
    </Document>
  )

  return Agreement
}
