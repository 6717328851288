import React from "react"
import { omitBy, isNull } from "lodash"
import { connect } from "react-redux"
import { FunctionKeys, NonFunctionKeys } from "utility-types"

import { submitCommitment } from "@src/actions"
import { Props, CommitmentAssessmentForm } from "./commitmentAssessmentForm"
import { applicant, commitment } from "@src/selectors"
import { SelectedTrackOption } from "@components/applicationForm/trackSelection/trackOptions"

type StateProps = Pick<Props, Exclude<NonFunctionKeys<Props>, undefined>>
type DispatchProps = Pick<Props, Exclude<FunctionKeys<Props>, undefined>>

const CommitmentAssessment = connect<StateProps, DispatchProps>(
  (state) => {
    const track: SelectedTrackOption = applicant(state).selectedTrack
    const commitmentState = commitment(state)

    return {
      initialValues: {
        hoursPerWeek: 40,
        ...omitBy(commitmentState, isNull),
      },
      track,
    }
  },
  {
    onSubmit: submitCommitment,
  }
)(CommitmentAssessmentForm)

export default CommitmentAssessment
