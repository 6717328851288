import React from "react"

import BrandedHeader from "@components/helpers/brandedHeader"

export default (WrappedComponent) => (
  <div className="account-layout">
    <BrandedHeader className="account-layout__header" />
    <WrappedComponent className="account-layout__content" />
  </div>
)
