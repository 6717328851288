import React, { Component } from "react"
import { connect } from "react-redux"
import { scrollElementTo } from "@src/helpers"

import {
  nextPage,
  previousPage,
  paymentOptionSelected,
  retakeFinancialSurvey,
  saveFinancialSurvey,
  saveFinancialPaymentOption,
} from "@src/actions"
import * as selectors from "@src/selectors"
import TitledContainer from "@components/TitledContainer"
import FinancialGuidanceForm from "./financialGuidanceForm"
import LoanForm from "./loanForm"
import {
  ApplicantFinancialInfo,
  FinancingOption,
  SelectedOption,
  financingOptions,
  groupedFinancialOptions,
} from "./financingOptions"
import {
  PaymentOptionRequest,
  FinancialSurvey,
} from "@common/types/financialGuidance"
import { State } from "@src/reducers"

interface StateProps {
  financialInfo: ApplicantFinancialInfo
  selectedOption: SelectedOption | undefined
  surveyTaken: boolean
}

interface DispatchProps {
  nextPage: typeof nextPage
  paymentOptionSelected: typeof paymentOptionSelected
  previousPage: typeof previousPage
  retakeFinancialSurvey: typeof retakeFinancialSurvey
  saveFinancialPaymentOption: (
    option: SelectedOption,
    payload: PaymentOptionRequest
  ) => void
  saveFinancialSurvey: (survey: FinancialSurvey) => void
}

interface Fields extends FinancialSurvey {
  notSure?: boolean
}

interface Props extends StateProps, DispatchProps {}

const FinancialGuidancePledge = (props: {
  survey: FinancialSurvey
  onSubmit: (fields: any) => void
  previousPage: () => void
}) => {
  return (
    <>
      <TitledContainer
        title="Our Pledge"
        className="financial-guidance__pledge"
      >
        Bottega is dedicated to your success—not your money—and we want you to
        be able to focus on your education. That said, we feel it’s necessary to
        provide you with quality options that will best cator to your
        circumstances and allow you to invest in a brighter future for yourself
        as a software developer.
      </TitledContainer>
      {
        <FinancialGuidanceForm
          initialValues={props.survey}
          // @ts-ignore
          previousPage={props.previousPage}
          onSubmit={props.onSubmit}
        />
      }
    </>
  )
}

class FinancialGuidance extends Component<Props> {
  onSubmitSurvey = (fields: Fields) => {
    if (fields.notSure === true) {
      this.props.nextPage()
    } else {
      this.props.saveFinancialSurvey(fields)
    }
  }

  toggleSurveyStatusAndScroll = () => {
    this.props.retakeFinancialSurvey()
    scrollElementTo(window, 0, -100)
  }

  toFinancingOptionPayload = (option: FinancingOption) => {
    if (option.emailImage) {
      return {
        emailImage: option.emailImage,
        url: option.url,
      }
    } else {
      return {
        url: option.url,
      }
    }
  }

  onSubmitApplication = () => {
    const { financialInfo, selectedOption } = this.props
    if (selectedOption) {
      const financingOption = financingOptions(
        selectedOption.category,
        financialInfo
      ).find((option) => option.title === selectedOption.title)

      if (financingOption) {
        const payload = this.toFinancingOptionPayload(financingOption)
        this.props.saveFinancialPaymentOption(selectedOption, payload)
      }
    }
  }

  render() {
    return (
      <div className="financial-guidance layout__content">
        {this.props.surveyTaken ? (
          <LoanForm
            availableOptions={groupedFinancialOptions(this.props.financialInfo)}
            onSelect={this.props.paymentOptionSelected}
            onSubmit={this.onSubmitApplication}
            onRetakeSurvey={this.toggleSurveyStatusAndScroll}
            selectedOption={this.props.selectedOption}
          />
        ) : (
          <FinancialGuidancePledge
            survey={this.props.financialInfo.survey}
            previousPage={this.props.previousPage}
            onSubmit={this.onSubmitSurvey}
          />
        )}
      </div>
    )
  }
}

function financialInfoSelector(state: State): ApplicantFinancialInfo {
  const applicantState = selectors.applicantInfo(state).state
  const commitment = selectors.commitment(state)
  const formatTitle = selectors.applicant(state).selectedTrack.selectedFormat
    .title
  const partnerName = state.partner.name
  const survey = selectors.financialSurveyAnswers(state)

  return {
    applicantState,
    commitment,
    formatTitle,
    partnerName,
    survey,
  }
}

function selectedOptionSelector(state: State): SelectedOption | undefined {
  const financialInfo = financialInfoSelector(state)
  const selectedOption: unknown =
    state.financialGuidance.selectedOption ||
    selectors.applicant(state).selectedPaymentOption

  // TODO: move this check to the app boundary (API call results)
  // when the Redux state is fully typed and selectors can be relied upon
  return SelectedOption.is(selectedOption) &&
    financingOptions(selectedOption.category, financialInfo).some(
      (option) => option.title === selectedOption.title
    )
    ? selectedOption
    : undefined
}

function mapStateToProps(state: State) {
  const { surveyTaken } = selectors.financialGuidance(state)
  const financialInfo = financialInfoSelector(state)
  const selectedOption = selectedOptionSelector(state)
  return { financialInfo, selectedOption, surveyTaken }
}

const FinancialGuidanceContainer = connect(mapStateToProps, {
  nextPage,
  previousPage,
  paymentOptionSelected,
  retakeFinancialSurvey,
  saveFinancialSurvey,
  saveFinancialPaymentOption,
})(FinancialGuidance)

export default FinancialGuidanceContainer
