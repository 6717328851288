import {
  DATA_POINTS_LOADED,
  FORMAT_DATES_LOADED,
  SET_ACCOUNT_CREATED_INFO_SEEN,
  SET_AND_AUTHENTICATE_APPLICANT,
  SET_SELECTED_TRACK,
  SET_SELECTED_TRACK_FORMAT,
  SET_SELECTED_TRACK_START_DATE,
} from "@src/actions/types"
import { UserBaseParams } from "@src/actions/applicant"
import {
  SelectedTrackFormat,
  SelectedTrackOption,
  TrackFormatDatesPerSlug,
} from "@components/applicationForm/trackSelection/trackOptions"
import {
  Applicant,
  withMappedDataPoints,
  EnrollmentAdvisor,
} from "@common/applicant"
import { DataPoints } from "@common/types/dataPoints"
import { Partner } from "@common/partner"

const toInitialApplicant = (applicant: Applicant) => ({
  accountCreatedInfoSeen: false,
  applicantInfo: {
    firstName: applicant.applicantInfo.firstName,
    lastName: applicant.applicantInfo.lastName,
  },
  orientationMeetingAt: null,
  commitment: {},
  email: applicant.email,
})

export interface InitialApplicant {
  accountCreatedInfoSeen?: boolean
  applicantInfo: {
    firstName: string
    lastName: string
  }
  orientationMeetingAt?: string | null
  commitment?: {}
  email: string
}

export interface ApplicantState {
  applicant: InitialApplicant | Applicant
  authenticated: boolean
  calendlyLink: string
  formatDates: TrackFormatDatesPerSlug
}

const INITIAL_STATE: ApplicantState = {
  applicant: {
    accountCreatedInfoSeen: false,
    applicantInfo: {
      firstName: "",
      lastName: "",
    },
    orientationMeetingAt: null,
    commitment: {},
    email: "",
  },
  authenticated: false,
  calendlyLink: "",
  formatDates: {},
}

interface FormatDatesLoadedAction {
  type: "FORMAT_DATES_LOADED"
  payload: TrackFormatDatesPerSlug
}

interface AuthenticateApplicantAction {
  type: "SET_AND_AUTHENTICATE_APPLICANT"
  payload: UserBaseParams
}

interface DataPointsLoadedAction {
  type: "DATA_POINTS_LOADED"
  payload: {
    dataPoints: DataPoints
    enrollmentAdvisor: EnrollmentAdvisor
    partner: Partner
  }
}

interface SetAccountCreatedInfoSeen {
  type: "SET_ACCOUNT_CREATED_INFO_SEEN"
}

interface SetSelectedTrackAction {
  type: "SET_SELECTED_TRACK"
  payload?: SelectedTrackOption
}

interface SetSelectedTrackFormat {
  type: "SET_SELECTED_TRACK_FORMAT"
  payload?: SelectedTrackFormat
}

interface SetSelectedTrackStartDate {
  type: "SET_SELECTED_TRACK_START_DATE"
  payload?: string
}

export type Action =
  | AuthenticateApplicantAction
  | DataPointsLoadedAction
  | FormatDatesLoadedAction
  | SetAccountCreatedInfoSeen
  | SetSelectedTrackAction
  | SetSelectedTrackFormat
  | SetSelectedTrackStartDate

export default function (
  state = INITIAL_STATE,
  action: Action
): ApplicantState {
  switch (action.type) {
    case FORMAT_DATES_LOADED: {
      return {
        ...state,
        formatDates: action.payload,
      }
    }
    case SET_AND_AUTHENTICATE_APPLICANT: {
      return {
        ...state,
        applicant: {
          ...state.applicant,
          applicantInfo: {
            ...state.applicant.applicantInfo,
            firstName: action.payload.first_name,
            lastName: action.payload.last_name,
          },
          email: action.payload.email,
        },
        authenticated: true,
      }
    }
    case DATA_POINTS_LOADED: {
      return {
        ...state,
        applicant: withMappedDataPoints(
          toInitialApplicant(state.applicant as Applicant),
          action.payload.dataPoints,
          action.payload.partner,
          state.formatDates,
          action.payload.enrollmentAdvisor
        ),
      }
    }
    case SET_ACCOUNT_CREATED_INFO_SEEN: {
      return {
        ...state,
        applicant: {
          ...state.applicant,
          accountCreatedInfoSeen: true,
        },
      }
    }
    case SET_SELECTED_TRACK: {
      return {
        ...state,
        applicant: {
          ...state.applicant,
          selectedTrack: action.payload && {
            // @ts-ignore: Ignore because format might be missing when changing
            // the track selection.
            selectedFormat: action.payload.formats[0],
            ...action.payload,
          },
        } as Applicant,
      }
    }
    case SET_SELECTED_TRACK_FORMAT: {
      return {
        ...state,
        applicant: {
          ...state.applicant,
          selectedTrack: {
            ...(state.applicant as Applicant).selectedTrack,
            selectedFormat: action.payload,
          } as SelectedTrackOption,
        } as Applicant,
      }
    }
    case SET_SELECTED_TRACK_START_DATE: {
      return {
        ...state,
        applicant: {
          ...state.applicant,
          selectedTrack: {
            ...(state.applicant as Applicant).selectedTrack,
            selectedFormat: {
              ...(state.applicant as Applicant).selectedTrack!.selectedFormat,
              selectedStartDate: action.payload,
            },
          } as SelectedTrackOption,
        } as Applicant,
      }
    }

    default:
      return state
  }
}
