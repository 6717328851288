import { FormButton } from "@components/formFields"
import { setAccountCreatedInfoSeen } from "@src/actions"
import React, { Component } from "react"
import { connect } from "react-redux"

class AccountCreated extends Component<{
  setAccountCreatedInfoSeen: () => void
}> {
  onContinue = () => {
    this.props.setAccountCreatedInfoSeen()
  }

  render() {
    return (
      <div className="account-created">
        <img className="account-created__image" src="/assets/partyIcon.svg" />
        <div className="account-created__title">devCamp account created</div>
        <div className="account-created__message">
          Your account on devCamp was created and you have gained access to the
          Coding Foundations course!{" "}
          <a href="https://devcamp.com/users/sign_in" target="_blank">
            HERE
          </a>{" "}
          you can get started on the Coding Foundations challenge!
        </div>
        <div className="account-created__message_with_image">
          <img
            className="account-created__image_timer"
            src="/assets/codingFoundationTimer.jpg"
          />

          <div className="account-created__message2">
            Once you start the challenge you will have one week to complete at
            least 25% of the course. This is the time for you to showcase your
            motivation levels, not smarts or skills necessarily.
          </div>
        </div>
        <div className="account-created__message3">
          Be sure to get back here and complete your enrollment application.
        </div>
        <FormButton
          className="account-created__button"
          title="Continue"
          onClick={() => this.onContinue()}
        />
      </div>
    )
  }
}

const AccountCreatedContainer = connect(null, { setAccountCreatedInfoSeen })(
  AccountCreated
)

export default AccountCreatedContainer
