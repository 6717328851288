import axios from "axios"

import {
  DATA_POINTS_LOADED,
  FORMAT_DATES_LOADED,
  SET_PARTNER_INFO,
} from "@src/actions/types"
import { setAndAuthenticateApplicant } from "@src/actions/applicant"
import { popupError } from "@src/actions/popupManager"
import { getFormatDates } from "@components/applicationForm/trackSelection/trackOptions"
import { wrapWithDataLoader } from "@components/dataLoader"

const checkLoggedIn = wrapWithDataLoader(
  (dispatch) =>
    axios
      .get(`/api/applicant`)
      .then(
        ({
          data: { dataPoints, enrollmentAdvisor, partner, status, user },
        }) => {
          if (status === "logged_in" && user) {
            return getFormatDates(axios.create({ baseURL: "/api" }))
              .then((formatDates) => {
                setAndAuthenticateApplicant(dispatch, user)
                dispatch({
                  type: FORMAT_DATES_LOADED,
                  payload: formatDates,
                })
                dispatch({
                  type: SET_PARTNER_INFO,
                  payload: partner,
                })
                dispatch({
                  type: DATA_POINTS_LOADED,
                  payload: {
                    dataPoints,
                    enrollmentAdvisor,
                    partner,
                  },
                })
              })
              .catch((err) => dispatch(popupError(err)))
          }
        }
      )
      .catch((_error) => {}),
  "CheckLoggedIn"
)

export default checkLoggedIn
