import React from "react"
import { Field, InjectedFormProps, reduxForm } from "redux-form"

import TrackSelectionCard from "./trackSelectionCard"
import { FormButton } from "@components/formFields"
import {
  SelectedTrackFormat,
  SelectedTrackOption,
  TrackOption,
} from "@components/applicationForm/trackSelection/trackOptions"
import {
  setSelectedTrack,
  setSelectedTrackFormat,
  setSelectedTrackStartDate,
} from "@src/actions"

interface TrackSelectionTrackProps {
  className: string
  cancel: () => void
  tracks: TrackOption[]
  selectedTrack: SelectedTrackOption
  setSelectedTrack: typeof setSelectedTrack
  setSelectedTrackFormat: typeof setSelectedTrackFormat
  setSelectedTrackStartDate: typeof setSelectedTrackStartDate
  save: (track: SelectedTrackOption) => void
}

type TrackSelectionProps = TrackSelectionTrackProps &
  InjectedFormProps<{}, TrackSelectionTrackProps>

const TrackSelectionForm = reduxForm<{}, TrackSelectionTrackProps>({
  form: "TrackSelection",
})(
  class extends React.Component<TrackSelectionProps, { submitted: boolean }> {
    constructor(props) {
      super(props)
      this.state = {
        submitted: false,
      }
    }

    isStartDateSelected(track: SelectedTrackOption): boolean {
      return track.selectedFormat.dates.length > 0
        ? !!track.selectedFormat.selectedStartDate
        : true
    }

    onSubmit = () => {
      this.setState({
        submitted: true,
      })
      if (this.isStartDateSelected(this.props.selectedTrack)) {
        this.props.save(this.props.selectedTrack)
      } else {
        return false
      }
    }

    setSelectedTrackFormat = (trackFormat?: SelectedTrackFormat) => {
      this.setState({
        submitted: false,
      })

      return this.props.setSelectedTrackFormat(trackFormat)
    }

    render() {
      const { handleSubmit, cancel } = this.props
      const startDateSelected = this.isStartDateSelected(
        this.props.selectedTrack
      )
      return (
        <form
          onSubmit={handleSubmit(this.onSubmit)}
          className="track-selection-form"
        >
          <TrackSelectionCard
            className="track-selection-form__card"
            selectedTrack={this.props.selectedTrack}
            setSelectedTrack={this.props.setSelectedTrack}
            setSelectedTrackFormat={this.setSelectedTrackFormat}
            setSelectedTrackStartDate={this.props.setSelectedTrackStartDate}
            trackStartDateRequired={this.state.submitted && !startDateSelected}
            tracks={this.props.tracks}
          />
          <div className="track-selection-form__buttons">
            <Field
              className="track-selection-form__cancel"
              title="Cancel"
              type="button"
              onClick={cancel}
              gray={true}
              name="cancel"
              component={FormButton}
            />
            <Field
              className="track-selection-form__save-and-close"
              title="Save & Close"
              type="submit"
              name="saveandclose"
              component={FormButton}
            />
          </div>
        </form>
      )
    }
  }
)

export default TrackSelectionForm
