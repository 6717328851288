import React, { FunctionComponent } from "react"
import { WrappedFieldProps } from "redux-form"

interface FormReadOnlyProps extends WrappedFieldProps {
  className: string
  title: string
  type: string
}

export const FormReadonly: FunctionComponent<FormReadOnlyProps> = (props) => {
  const { className, input, title, type } = props
  return (
    <div className={`${className} form-input`}>
      <div className="form-input__title">{title}</div>
      <input className="form-input__input" type={type} {...input} readOnly />
    </div>
  )
}
