import React from "react"

function GreenTitledList({ className = "", title, children }) {
  return (
    <ol className={`${className} green-titled-list`}>
      <div className="green-titled-list__title">{title}</div>
      <div className="green-titled-list__items">{children}</div>
    </ol>
  )
}

export default GreenTitledList
