import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { WrappedFieldProps } from "redux-form"
import "./formButton.scss"

interface FormButtonProps extends Partial<WrappedFieldProps> {
  className?: string
  onClick: () => any
  id?: string
  type?: "button" | "submit" | "reset"
  title: string
  gray?: boolean
}

export const FormButton: FunctionComponent<FormButtonProps> = (props) => (
  <div
    className={classNames(props.className, "form-button", {
      gray: props.gray,
    })}
  >
    <button
      id={props.id}
      onClick={props.onClick}
      className="form-button__button"
      {...props.input}
      type={props.type}
    >
      {props.title}
    </button>
  </div>
)
