import React from "react"
import { reduxForm, Field, InjectedFormProps } from "redux-form"

import DocumentCollectionCard from "./documentCollectionCard"
import withTopView from "@components/topView"
import { FormFileInput, FormButton } from "@components/formFields"
import {
  InputFile,
  isInputFilePresent,
} from "@components/formFields/formFileInput/formFileInput"

export interface DocumentCollectionValues {
  governmentId: InputFile
  officialHighSchoolTranscript: InputFile
}

export type DocumentCollectionFormValues = Partial<DocumentCollectionValues>

interface DocumentCollectionFormProps {
  initialValues?: DocumentCollectionFormValues
  onSubmit: (data: DocumentCollectionValues) => void
}

type DocumentCollectionFormErrors = {
  [key in keyof DocumentCollectionFormValues]?: string
}

const DocumentCollectionForm = (
  props: DocumentCollectionFormProps &
    InjectedFormProps<DocumentCollectionValues, DocumentCollectionFormProps>
) => (
  <form onSubmit={props.handleSubmit} className="document-collection-form">
    <DocumentCollectionCard className="document-collection-form__card">
      <Field
        name="governmentId"
        title="Government ID"
        component={FormFileInput}
      />
      <Field
        name="officialHighSchoolTranscript"
        title="Official high school transcript / GED"
        component={FormFileInput}
      />
    </DocumentCollectionCard>

    <div className="document-collection-form__buttons">
      <Field
        className="document-collection-form__continue"
        title="Continue"
        type="submit"
        name="continue"
        component={FormButton}
      />
    </div>
  </form>
)

const validate = (
  values: DocumentCollectionFormValues
): DocumentCollectionFormErrors => {
  const errors: DocumentCollectionFormErrors = {}

  if (!values.governmentId || !isInputFilePresent(values.governmentId)) {
    errors.governmentId = "*"
  }

  if (
    !values.officialHighSchoolTranscript ||
    !isInputFilePresent(values.officialHighSchoolTranscript)
  ) {
    errors.officialHighSchoolTranscript = "*"
  }

  return errors
}

const WrappedForm = reduxForm<
  DocumentCollectionValues,
  DocumentCollectionFormProps
>({
  form: "DocumentCollectionForm",
  validate,
})(withTopView(DocumentCollectionForm))
;(WrappedForm as any).displayName = `reduxForm(${DocumentCollectionForm.name})`

export { WrappedForm as DocumentCollectionForm, validate }
