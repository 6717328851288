import React, { Component } from "react"

class SelectProgram extends Component {
  render() {
    const { onChange } = this.props
    return (
      <div className="select-program">
        <img
          className="select-program__img"
          src="/assets/bottega-white-logo.png"
        />
        <div className="select-program__title">Enrollment Application</div>
        <div className="select-program__get-started">To Get Started</div>
        <a onClick={onChange} className="select-program__select">
          Select A Program
        </a>
      </div>
    )
  }
}

export default SelectProgram
