import {
  SIGNUP_ERROR,
  CLEAR_ERROR_MESSAGE,
  STORE_USERS,
  SET_USER,
} from "./types"

import axios from "axios"

export function fetchUser(id, done) {
  return function (dispatch) {
    axios
      .get(`/api/user/${id}`)
      .then((res) => {
        const user = res.data.user
        dispatch({
          type: SET_USER,
          payload: user,
        })
        done(user.lastKnownPage)
      })
      .catch(() => {})
  }
}

export function signUp(fields, selectedTrack, done) {
  return function (dispatch) {
    axios
      .post(`/api/signup`, { ...fields, selectedTrack })
      .then((res) => {
        done()
        dispatch({
          type: SET_USER,
          payload: res.data.user,
        })
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: SIGNUP_ERROR,
            payload: err.response.data,
          })
        }
      })
  }
}

export function fetchUsers() {
  return function (dispatch) {
    axios
      .get(`/api/admin/users`)
      .then((res) => {
        dispatch({
          type: STORE_USERS,
          payload: res.data,
        })
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: STORE_USERS,
            payload: "No Users",
          })
        }
      })
  }
}

export function clearErrorMessage() {
  return {
    type: CLEAR_ERROR_MESSAGE,
  }
}
