import React, { FunctionComponent } from "react"
import { WrappedFieldProps } from "redux-form"

import { toYesNo } from "@src/helpers"

interface FormCheckProps extends WrappedFieldProps {
  className: string
  title: string
}

export const FormCheck: FunctionComponent<FormCheckProps> = (props) => (
  <div className={`${props.className || ""} form-input form-input__checkbox`}>
    <div className={"form-input__title"}>{props.title}</div>
    <label>
      <input type="checkbox" {...props.input} checked={props.input.value} />
      <span>{toYesNo(props.input.value) || "No"}</span>
    </label>
  </div>
)
