import React from "react"

function GreenTitledInfo({ className, title, children }) {
  return (
    <div className={`${className} green-titled-info`}>
      <div className="green-titled-info__title">{title}</div>
      <div className="green-titled-info__value">{children}</div>
    </div>
  )
}

export default GreenTitledInfo
