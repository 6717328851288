import Loader from "react-loader-spinner"
import React, { Component } from "react"
import { connect } from "react-redux"

import { State } from "@src/reducers/index"

interface Props {
  loading: boolean
}

class LoaderContainer extends Component<Props> {
  render() {
    return (
      this.props.loading && (
        <div className="loader">
          <Loader type="Watch" color="#00CB79" height="50" width="50" />
        </div>
      )
    )
  }
}

function mapStateToProps(state: State) {
  const { counter } = state.requestsLoader
  return { loading: counter > 0 }
}

export default connect(mapStateToProps)(LoaderContainer)
