import React from "react"

const items = [
  {
    _id: 2,
    URL: "https://www.facebook.com/bottegaschool/?ref=br_rs",
    icon: "fab fa-facebook-square",
  },
  {
    _id: 1,
    URL: "https://www.linkedin.com/school/bottega-tech/",
    icon: "fab fa-linkedin-in",
  },
  {
    _id: 3,
    URL: "https://twitter.com/Bottega_Tech",
    icon: "fab fa-twitter",
  },
  {
    _id: 0,
    URL: "http://instagram.com/bottega.tech",
    icon: "fab fa-instagram",
  },
]

export default function QuickFALinks() {
  return (
    <div className="social-media">
      {items
        ? items.map((item) => {
            return (
              <a
                key={item._id}
                target="_blank"
                className={`social-media__item ${item.icon}`}
                href={item.URL}
              />
            )
          })
        : ""}
    </div>
  )
}
