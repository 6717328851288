import React, { Component } from "react"
import moment from "moment"
import { connect } from "react-redux"

import * as actions from "@src/actions"

import GreenTitledList from "@components/greenTitledList"
import NumberedList from "@components/numberedList"
import ProcessComplete from "./reviewProcessComplete"
import SocialMedia from "./socialMedia"
import TitledContainer from "@components/TitledContainer"
import withTopView from "@components/topView"
import { DateFormat } from "@src/helpers"
import { State } from "@src/reducers"
import { Applicant } from "@common/applicant"
import { SelectedTrackFormat } from "@components/applicationForm/trackSelection/trackOptions"

interface StateProps {
  selectedFormat: SelectedTrackFormat
}

class Review extends Component<StateProps> {
  render() {
    const partTime = this.props.selectedFormat.title.includes("Part-Time")
    const startDate = moment(this.props.selectedFormat.selectedStartDate)

    const startDateText = startDate.isValid()
      ? startDate.format(DateFormat)
      : ""

    const reviewListItems = [
      {
        title: "Coding Foundations Challenge",
        description:
          "Now that you have officially submitted an application, it’s up to you " +
          "to prove the level of your ambition that you indicated you have " +
          "within the Commitment Assessment section of your application. " +
          "Check your email for full instructions and review below!",
        contentComponent: (
          <GreenTitledList title="Next Steps">
            <li>
              We want to see what you can do with your new devCamp account!{" "}
              <a href="https://devcamp.com/users/sign_in" target="_blank">
                HERE
              </a>{" "}
              you can get started on the Coding Foundations challenge!
            </li>
            <li>
              If you can complete at least 25% of the preliminary course within
              one week of today, you’ll be eligible for acceptance into Bottega!
              This is the time for you to showcase your motivation levels, not
              smarts or skills necessarily.
            </li>
            <li>
              Be sure to take advantage the Coding Foundations Challenge chat
              where you will be randomly paired with other individuals around
              the world aiming to complete the same challenge for acceptance and
              scholarships!
            </li>
            <li>
              If you’re successful, you’ll have an assigned advisor contact you
              within the next weekday.
            </li>
            <li>
              In addition to the Coding Foundations course, we strongly
              recommend that you get going on the next steps toward securing
              financing for the option you qualified for.
            </li>
            {!partTime && (
              <li>
                IMPORTANT: Our programs fill up quickly. In order to reserve
                your seat for the {startDateText} start date, secure your
                financing as soon as possible.
              </li>
            )}
          </GreenTitledList>
        ),
      },
    ]

    return (
      <div className="financial-guidance layout__content">
        <TitledContainer
          title="Application Submitted"
          className="financial-guidance__process-complete"
        >
          <ProcessComplete />
        </TitledContainer>
        <TitledContainer
          title="Now What?"
          className="financial-guidance__now-what"
        >
          <NumberedList listItems={reviewListItems} />
        </TitledContainer>
        <TitledContainer
          title="Follow Us On Social Media"
          className="financial-guidance__social-media"
        >
          <SocialMedia />
        </TitledContainer>
      </div>
    )
  }
}

function mapStateToProps(state: State) {
  const { selectedFormat } = (state.applicant
    .applicant as Applicant).selectedTrack!
  return { selectedFormat }
}

const ReviewContainer = connect(mapStateToProps, actions)(withTopView(Review))

export default ReviewContainer
