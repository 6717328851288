import { Documents } from "@common/applicant"
import { FinancialSurvey } from "@common/types/financialGuidance"

export function applicant(state) {
  return state.applicant.applicant
}

export function commitment(state) {
  return applicant(state).commitment
}

export function documents(state): Documents {
  return applicant(state).documents
}

export function applicantInfo(state) {
  return applicant(state).applicantInfo
}

export function financialGuidance(state) {
  return state.financialGuidance
}

export function financialSurveyAnswers(state): FinancialSurvey {
  return applicant(state).financialSurveyAnswers
}
