import {
  SET_USERS,
  SIGNUP_ERROR,
  CLEAR_ERROR_MESSAGE,
  STORE_USERS,
  SET_USER,
} from "@src/actions/types"

const INITIAL_STATE = {
  users: [],
  user: {
    _id: -1,
  },
  errorMessage: "",
  existingUser: false,
  lastKnownPage: 1,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "AUTHENTICATE": // TODO: remove
      return {
        ...state,
        existingUser: true,
        lastKnownPage: action.payload.lastKnownPage,
        user: action.payload.user,
      }
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      }
    case SIGNUP_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      }
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: "",
      }
    case STORE_USERS:
      return {
        ...state,
        users: action.payload,
      }
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      }

    default:
      return state
  }
}
