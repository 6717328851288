import React from "react"
import classNames from "classnames"
import {
  SelectedTrackOption,
  TrackOption,
} from "@components/applicationForm/trackSelection/trackOptions"
import { setSelectedTrack } from "@src/actions"

interface TrackProps {
  selected: boolean
  setSelectedTrack: typeof setSelectedTrack
  track: TrackOption
}

const Track = (props: TrackProps) => {
  const { selected, setSelectedTrack, track } = props
  const className = classNames("track", {
    "track-option-selected": selected,
    "track-option-unselected": !selected,
  })
  const onClick = () => {
    setSelectedTrack(track as SelectedTrackOption)
  }

  return (
    <a onClick={onClick} className={className}>
      <img src={`/assets${track.logoUrl}`} />
      <div className="track__title">{track.title}</div>
    </a>
  )
}

export default Track
