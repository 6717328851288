import { AxiosError } from "axios"

import { SET_POPUP_MESSAGE, CLEAR_POPUP_MESSAGE } from "./types"
import * as React from "react"
import { flatMap, startCase, camelCase } from "lodash"

type Error = string | JSX.Element | AxiosError

interface APIErrors {
  [key: string]: Array<string>
}

export function clearPopupMessage() {
  return { type: CLEAR_POPUP_MESSAGE }
}

export function popupError(error: Error) {
  if (error) {
    return {
      type: SET_POPUP_MESSAGE,
      payload: formatError(error),
    }
  } else {
    return {
      type: undefined,
    }
  }
}

function isAxiosError(error: object): error is AxiosError {
  return "response" in error
}

function formatError(error: Error) {
  if (typeof error === "string") {
    return error
  } else if (React.isValidElement(error)) {
    return error
  } else if (isAxiosError(error) && error.response && error.response.status) {
    switch (error.response.status) {
      case 401:
        return "Invalid email or password."
      case 422:
        return error.response.data.errors
          ? fullErrorMessages(error.response.data.errors)
          : error.response.data.message ||
              "Something went wrong, please try again."
      default:
        return "Something went wrong, please try again."
    }
  } else {
    return "Something went wrong, please try again."
  }
}

function fullErrorMessages(apiErrors: APIErrors) {
  return flatMap(apiErrors, (errors, key) => {
    return [errors].map((error, index) => {
      return (
        <div key={index}>
          {startCase(camelCase(key))} {error}
        </div>
      )
    })
  })
}
