import Popup from "reactjs-popup"
import React from "react"

const HelpIcon = () => (
  <div className="help-icon">
    <Popup
      trigger={
        <div className="help-icon__trigger">
          <a
            href="https://calendly.com/bottega-advisors/phonecall3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-phone" />
          </a>
        </div>
      }
      position="left center"
      on="hover"
      offsetX={10}
      offsetY={-20}
      contentStyle={{
        borderLeft: "5px solid #01CB79",
      }}
    >
      <div className="help-icon__content">
        Need help before submitting? Schedule a call with one of our Student
        Success Advisors by clicking the icon.
      </div>
    </Popup>
  </div>
)

export default HelpIcon
