import React from "react"
import classNames from "classnames"

import { FormButton } from "@components/formFields"
import { FinancingOption } from "./financingOptions"

interface LoanFormOptionProps {
  option: FinancingOption
  selected: boolean
  onSelect: () => void
}

const LoanFormOption = ({
  option,
  selected,
  onSelect,
}: LoanFormOptionProps): JSX.Element => {
  const buttonClassNames = classNames("loan-form-option__select", {
    "loan-form-option__selected": selected,
  })
  const containerClassNames = classNames("loan-form-option", {
    "loan-form-option-container__selected": selected,
  })
  const logoClassNames = classNames("loan-form-option__logo", {
    "loan-form-option__logo-inverted": option.logoURL.endsWith(
      "bottega-white-logo.png"
    ),
  })
  return (
    <div className={containerClassNames}>
      <img className={logoClassNames} src={option.logoURL} />
      <div className="loan-form-option__title">{option.title}</div>
      <div className="loan-form-option__price">{option.price}</div>
      <div className="loan-form-option__frequency">{option.frequency}</div>
      <div className="loan-form-option__details">{option.details}</div>
      <FormButton
        className={buttonClassNames}
        title={selected ? "Selected" : "Select Option"}
        type="button"
        onClick={onSelect}
      />
    </div>
  )
}

export default LoanFormOption
