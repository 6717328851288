import React from "react"
import { FormButton } from "@components/formFields"

interface Props {
  isVisible: boolean
  message: string
  noLabel?: string
  onNo?: () => void
  onYes?: () => void
  yesLabel?: string
}

const Popup = (props: Props) => (
  <div className={`popup ${props.isVisible ? "" : "hide"}`}>
    <div className="popup__overlay" />
    <div className="popup__card">
      <div className="popup__card__message">{props.message}</div>
      <div className="popup__card__buttons">
        {props.onYes && (
          <FormButton
            className="popup__card__button"
            title={props.yesLabel || "Yes"}
            onClick={props.onYes}
          />
        )}
        {props.onNo && (
          <FormButton
            className="popup__card__button"
            title={props.noLabel || "No"}
            onClick={props.onNo}
          />
        )}
      </div>
    </div>
  </div>
)

export default Popup
