const initialsSet = [
  "Admissions Requirements (1)",
  "Admissions Requirements (2)",
  "Bottega University Acknowledgments",
  "Bottega University Catalog",
  "Bottega University Programs and Information (1)",
  "Bottega University Programs and Information (2)",
  "Bottega University Programs and Information (3)",
  "Coding Foundations",
  "Collections Policy",
  "Continuous Enrollment",
  "Enrollment Agreement",
  "Payment Methods",
  "Privacy and Terms of Use Policies",
  "Privacy Policy",
  "Refund/Cancellation Policy",
  "Scholarships",
  "Statement of Intent to Finance",
  "Statement of Tuition",
  "Student Acknowledgements (1)",
  "Student Acknowledgements (2)",
  "Technology fees",
  "Termination",
  "Terms Of Use",
  "Terms Of Use Arbitration",
  "Time-Frame to Complete the Program",
]

const toInitialsMap = (keys: string[], value: boolean) =>
  keys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: value,
    }),
    {}
  )

export const initials = (
  value: boolean
): {
  [key: string]: boolean
} => {
  return toInitialsMap(initialsSet, value)
}
