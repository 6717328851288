import React, { Component } from "react"

import withTitleCard from "@components/titleCard"

class ApplicantInformationCard extends Component {
  render() {
    return (
      <div className={`${this.props.className} applicant-information-card`}>
        {this.props.children}
      </div>
    )
  }
}

const ApplicantInformationCardWithTitle = withTitleCard(
  "Applicant Information"
)(ApplicantInformationCard)

export default ApplicantInformationCardWithTitle
