import React, { Component } from "react"
import moment from "moment"
import { connect } from "react-redux"

import GreenTitledInfo from "@components/greenTitledInfo"
import { DateFormat } from "@src/helpers"

class CourseSelection extends Component {
  renderStartDate = (selectedFormat) => {
    if (selectedFormat && !selectedFormat.selectedStartDate) {
      return "To Be Determined"
    }
    if (selectedFormat && selectedFormat.selectedStartDate) {
      const parsedDate = moment(selectedFormat.selectedStartDate)
      return parsedDate.format(DateFormat)
    }
  }

  render() {
    const { onChange, title, selectedFormat, logoUrl } = this.props
    return (
      <div className="course-selection">
        <div className="course-selection__change">
          <div className="course-selection__change__title">Not Correct?</div>
          <a onClick={onChange} className="course-selection__change__button">
            Change
          </a>
        </div>
        <img className="course-selection__img" src={`/assets/${logoUrl}`} />
        <div className="course-selection__title">{title}</div>
        <div className="course-selection__format">{selectedFormat.title}</div>
        <GreenTitledInfo
          className="course-selection__course-duration"
          title="Program Duration"
        >
          {selectedFormat.lengthInWeeks} weeks
        </GreenTitledInfo>
        <GreenTitledInfo
          className="course-selection__start-date"
          title="Cohort Start Date"
        >
          {this.renderStartDate(selectedFormat)}
        </GreenTitledInfo>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { selectedTrack } = state.applicant.applicant
  return { ...selectedTrack }
}

const CourseSelectionContainer = connect(mapStateToProps)(CourseSelection)

export default CourseSelectionContainer
