import React from "react"

export const homemadeApple = {
  fontFamily: "Homemade Apple",
}

export const nunitoBold = {
  fontFamily: "Nunito Sans",
  fontWeight: "bold",
}

export const nunitoItalic = {
  fontFamily: "Nunito Sans",
  fontStyle: "italic",
}

export const mkDocument = ({ BaseDocument }) => (props) => (
  <BaseDocument {...props}>
    {React.Children.map(
      props.children.filter((c) => c),
      (child, index) => React.cloneElement(child, { page: index + 1 })
    )}
  </BaseDocument>
)

export const mkInfo = ({ Text, View }) => ({ label, value }) => (
  <View
    style={{
      display: "flex",
      flexDirection: "row",
      marginBottom: "15pt",
    }}
  >
    <Text style={{ width: "50%" }}>{label}</Text>
    <View
      style={{
        width: "50%",
        borderBottomStyle: "solid",
        borderBottomWidth: "0.8pt",
        borderBottomColor: "black",
      }}
    >
      <Text>{value}</Text>
    </View>
  </View>
)

export const mkSignature = ({ Info, Text, View }) => ({
  label,
  value,
  onClick = () => {},
}) => (
  <Info
    label={label}
    value={
      value ? (
        <Text style={{ ...homemadeApple, fontSize: "9pt" }}>{value}</Text>
      ) : (
        <View style={{ height: "17pt" }} onClick={onClick}>
          <Text style={{ ...nunitoBold, color: "#00CB79", fontSize: "9pt" }}>
            Sign Here
          </Text>
        </View>
      )
    }
  />
)
