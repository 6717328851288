import React from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"

import PageLayout from "@components/PageLayout/pageLayout"
import checkLoggedIn from "@components/applicantAuth/checkLoggedIn"
import { State } from "@src/reducers"

interface WrappedProps {
  componentFromPath?: string
}

interface StateProps {
  authenticated: boolean
}

type Props = StateProps & WrappedProps

const Authentication = (props: Props) => {
  let location = useLocation()

  return props.authenticated ? (
    <PageLayout location={location} {...props} />
  ) : (
    <Navigate to="/applicant/signin" replace />
  )
}

const mapStateToProps = (state: State, props: WrappedProps) => ({
  authenticated: state.applicant.authenticated,
  componentFromPath: props.componentFromPath,
})

export default compose(
  connect(mapStateToProps),
  checkLoggedIn
)(Authentication) as React.FunctionComponent<WrappedProps>
