import React from "react"
import { reduxForm, Field, InjectedFormProps } from "redux-form"

import { FormButton } from "@components/formFields"

import TitledContainer from "@components/TitledContainer"
import LoanFormOption from "./loanFormOption"
import {
  categoryProperties,
  CategoryOptions,
  SelectedOption,
  isFinancialOptionsAvailable,
} from "./financingOptions"

interface LoanFormProps {
  availableOptions: CategoryOptions[]
  onRetakeSurvey: () => void
  onSelect: (option: SelectedOption) => void
  onSubmit: () => void
  selectedOption: SelectedOption | undefined
}

interface CategoryContainerProps {
  option: CategoryOptions
  selectedOption: SelectedOption | undefined
  onSelect: (option: SelectedOption) => void
}

const CategoryContainer = ({
  option: { category, options },
  selectedOption,
  onSelect,
}: CategoryContainerProps): JSX.Element => (
  <div className="loan-form-options-container" key={category}>
    <div className="loan-form-options-container__title">
      {categoryProperties[category].title}
    </div>
    <div className="loan-form-options-container__description">
      {categoryProperties[category].description}
    </div>
    <div className="loan-form-options-container__options">
      {options.map((option) => (
        <LoanFormOption
          key={option.title}
          option={option}
          selected={
            selectedOption !== undefined &&
            selectedOption.category === category &&
            selectedOption.title === option.title
          }
          onSelect={() => onSelect({ category, title: option.title })}
        />
      ))}
    </div>
  </div>
)

const LoanForm = ({
  availableOptions,
  handleSubmit,
  onRetakeSurvey,
  onSubmit,
  onSelect,
  selectedOption,
}: LoanFormProps & InjectedFormProps<{}, LoanFormProps>): JSX.Element => {
  const isAnyOptionAvailable = isFinancialOptionsAvailable(availableOptions)
  return (
    <TitledContainer
      title="Select an Option"
      className="financial-guidance__loans"
    >
      <form
        onSubmit={handleSubmit(() => {
          if (selectedOption) {
            onSubmit()
          }
        })}
        className="loan-form"
      >
        {!isAnyOptionAvailable && (
          <div className="loan-form__message">
            We are sorry, but there are no financial options that we can offer
            you at this time.
          </div>
        )}
        {isAnyOptionAvailable && (
          <>
            <div className="loan-form__message">
              Please select an option below that you may be interested in to
              finance your education.
              <div className="mb_20" />
              The option you select is not final and is only used to send more
              information as an aid to you in securing financing.
              <div className="mb_20" />
              Pick the option that fits your needs and an Enrolment Advisor will
              be in touch with you to help clarify the option you chose or any
              other that will best fit your needs.
            </div>
            <div className="loan-form__options">
              {availableOptions.map((option) => (
                <CategoryContainer
                  key={option.category}
                  option={option}
                  selectedOption={selectedOption}
                  onSelect={onSelect}
                />
              ))}
            </div>
          </>
        )}
        <div className="loan-form__buttons">
          <Field
            className="loan-form__retake"
            name="retake"
            type="button"
            title="Retake Survey"
            component={FormButton}
            onClick={onRetakeSurvey}
          />
          <Field
            className={
              "loan-form__submit" +
              (selectedOption ? "" : " loan-form__submit-disabled")
            }
            name="submit"
            type="submit"
            title="Continue"
            component={FormButton}
          />
        </div>
      </form>
    </TitledContainer>
  )
}

const LoanFormContainer = reduxForm<{}, LoanFormProps>({
  form: "LoanForm",
})(LoanForm)

export default LoanFormContainer
