import React from "react"
import { DispatchProp, connect } from "react-redux"
import Loader from "./loader"

export const wrapWithDataLoader = (
  onLoad: (dispatch: DispatchProp["dispatch"]) => Promise<any>,
  name: string
) => {
  return function (
    WrappedComponent: React.ComponentType | React.FunctionComponent<any>
  ) {
    class DataLoader extends React.Component<
      DispatchProp,
      { loading: boolean }
    > {
      constructor(props: DispatchProp) {
        super(props)
        this.state = {
          loading: true,
        }

        onLoad(props.dispatch).finally(() => {
          setTimeout(() => this.setState({ loading: false }))
        })
      }

      render() {
        return this.state.loading ? (
          <div
            style={{
              height: "100px",
              gridColumn: "s/e",
              gridRow: "content-s/content-e",
            }}
          >
            <Loader />
          </div>
        ) : (
          <WrappedComponent {...this.props} />
        )
      }
    }

    ;(DataLoader as any).displayName = `${name}(${WrappedComponent.displayName})`

    return connect()(DataLoader)
  }
}
