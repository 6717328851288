import {
  Pace,
  TrackFormat,
  TrackFormatDatesPerSlug,
  TrackOption,
  devcampSyllabusSlugToTrackKey,
  isOnCampusPace,
  mobileSyllabusSlugToFormat,
  tracksForPartner,
  isCheckoutableTrackOption,
} from "@components/applicationForm/trackSelection/trackOptions"

export const DateFormat = "LL"

function hasPaceDependantSite(partnerName: string): boolean {
  return (
    partnerName === "acu" ||
    partnerName === "bottega" ||
    partnerName === "rails" ||
    partnerName === "uvu"
  )
}

export function findFormat(
  track: TrackOption,
  title: string | null,
  slug: string | null
): TrackFormat | undefined {
  if (slug && isCheckoutableTrackOption(track)) {
    return findFormatByTitle(track, mobileSyllabusSlugToFormat[slug])
  } else {
    return findFormatByTitle(track, title)
  }
}

export function findFormatByTitle(
  track: TrackOption,
  title: string | null
): TrackFormat | undefined {
  return track.formats.find((format) => format.title === title)
}

export function findTrackBySlug(
  partnerName: string,
  slug: string | null,
  formatDatesPerSlug: TrackFormatDatesPerSlug
): TrackOption | undefined {
  const key = devcampSyllabusSlugToTrackKey(slug)
  const tracks = tracksForPartner(partnerName, formatDatesPerSlug)
  return tracks.find((track) => track.trackKey === key)
}

export function formatCalendlyLink(
  calendlyLink: string,
  applicantInfo: any
): string {
  const url = new URL(calendlyLink)
  const name = `${applicantInfo.firstName} ${applicantInfo.lastName}`

  url.searchParams.set("email", applicantInfo.email)
  url.searchParams.set("a1", applicantInfo.phoneNumber)
  url.searchParams.set("a2", applicantInfo.phoneNumber)

  return `${url.toString()}&name=${encodeURIComponent(name)}`
}

export function scrollElementTo(el, px, py) {
  if (el.scrollTo) {
    el.scrollTo(px, py)
  } else {
    if (el === window) {
      el = window.document.documentElement
    }
    el.scrollLeft = px
    el.scrollTop = py
  }
}

export function toYesNo(
  value: undefined | null | boolean
): "Yes" | "No" | undefined {
  if (value === true) {
    return "Yes"
  } else if (value === false) {
    return "No"
  } else {
    return undefined
  }
}

export function trackToSite(partnerName: string, pace: Pace): string {
  if (hasPaceDependantSite(partnerName)) {
    if (isOnCampusPace(pace)) {
      if (partnerName === "rails") {
        return "bottega-campus"
      } else {
        return `${partnerName}-campus`
      }
    } else {
      if (partnerName === "rails") {
        return "bottega-remote"
      } else {
        return `${partnerName}-remote`
      }
    }
  } else {
    return pace
  }
}
