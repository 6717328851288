import React from "react"
import { withContentRect } from "react-measure"

const baseWidth = 900.0
const baseHeight = (900.0 * 297.0) / 210.0

const Document = withContentRect("client")(
  // eslint-disable-next-line no-unused-vars
  ({ contentRect, measure, measureRef, ...props }) => {
    const clientWidth = contentRect.client.width || 0
    const scale = clientWidth / baseWidth
    const contentHeight = React.Children.count(props.children) * baseHeight
    const containerHeight = contentHeight * scale
    return (
      <div ref={measureRef} style={{ height: containerHeight }}>
        <div
          {...props}
          style={{
            ...props.style,
            backgroundColor: "white",
            position: "relative",
            width: `${baseWidth}px`,
            height: `${contentHeight}px`,
            transform: `scale(${scale})`,
            transformOrigin: "top left",
          }}
        >
          {props.children}
        </div>
      </div>
    )
  }
)

const Page = (props) => (
  <div
    {...props}
    style={{
      ...props.style,
      height: baseHeight,
      position: "relative",
      borderBottom: "1px solid gray",
    }}
  >
    {props.children}
  </div>
)

const PageNumber = (props) => (
  <div
    {...props}
    style={{
      ...props.style,
      position: "absolute",
    }}
  >
    {props.children}
  </div>
)

export default {
  BaseDocument: Document,
  BasePage: Page,
  View: (p) => <div {...p}>{p.children}</div>,
  Text: (p) => <span {...p}>{p.children}</span>,
  Br: () => <br />,
  PageNumber,
  Image: (p) => <img {...p} />,
  imagePath: (path) => `/assets/agreement/${path}`,
  baseSpacing: 12,
  fontSize: "11pt",
}
