import moment from "moment"
import React, { Component } from "react"

import { DateFormat } from "@src/helpers"

class OrientationReminder extends Component {
  renderMeetingFragment() {
    if (this.props.isScheduled) {
      const orientationTime = moment(this.props.startTime)

      return (
        <>
          <p>
            The student orientation meeting you scheduled during enrollment is
            set for:
          </p>
          <div className="orientation-reminder__date">
            <div className="orientation-reminder__date__date">
              {orientationTime.format(DateFormat)}
            </div>
            <div className="orientation-reminder__date__time">
              at {orientationTime.format("h:mm a")}
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <p>
            Check your inbox for a calendly email that lists date and time of
            the orientation meeting you scheduled during enrollment.
          </p>
        </>
      )
    }
  }

  render() {
    return (
      <div className="orientation-reminder">
        <h1 className="orientation-reminder__title">Student Orientation</h1>
        <p>
          Be ready for your student orientation meeting with our dedicated
          Mentor staff. They'll go through expectations and get you started on
          DevCamp.
        </p>
        {this.renderMeetingFragment()}
        <p>
          Should you need to reschedule, follow the rescheduling instructions in
          the email sent to you or call our support line at: 801.980.3664.
        </p>
      </div>
    )
  }
}

export default OrientationReminder
