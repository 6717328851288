export const SET_USERS = "SET_USERS"
export const SET_PROGRESS = "SET_PROGRESS"
export const STORE_USERS = "STORE_USERS"

// TRACK SELECTION
export const SET_SELECTED_TRACK = "SET_SELECTED_TRACK"
export const SET_SELECTED_TRACK_FORMAT = "SET_SELECTED_TRACK_FORMAT"
export const SET_SELECTED_TRACK_START_DATE = "SET_SELECTED_TRACK_START_DATE"

// CONTRACT AGREEMENT
export const SIGNUP_ERROR = "SIGNUP_ERROR"

export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE"

export const PAYMENT_OPTION_SELECTED = "PAYMENT_OPTION_SELECTED"
export const RETAKE_FINANCIAL_SURVEY = "RETAKE_FINANCIAL_SURVEY"
export const FINANCIAL_SURVEY_SUBMITTED = "FINANCIAL_SURVEY_SUBMITTED"

export const STORE_EVENT_DETAILS_AND_USER = "STORE_EVENT_DETAILS_AND_USER"

// PAGE LAYOUT
export const SET_PAGES = "SET_PAGES"
export const SET_PAGE = "SET_PAGE"

export const NEXT_PAGE = "NEXT_PAGE"
export const PREVIOUS_PAGE = "PREVIOUS_PAGE"

export const SET_USER = "SET_USER"

export const VIEW_DOC = "VIEW_DOC"

// APPLICANT
export const DATA_POINTS_LOADED = "DATA_POINTS_LOADED"
export const SET_ACCOUNT_CREATED_INFO_SEEN = "SET_ACCOUNT_CREATED_INFO_SEEN"
export const SET_AND_AUTHENTICATE_APPLICANT = "SET_AND_AUTHENTICATE_APPLICANT"

// POPUP MANAGER REDUCER
export const SET_POPUP_MESSAGE = "SET_POPUP_MESSAGE"
export const CLEAR_POPUP_MESSAGE = "CLEAR_POPUP_MESSAGE"

// PARTNER
export const SET_PARTNER_INFO = "SET_PARTNER_INFO"

// REQUESTS LOADER
export const DECREMENT_REQUESTS_COUNTER = "DECREMENT_REQUESTS_COUNTER"
export const INCREMENT_REQUESTS_COUNTER = "INCREMENT_REQUESTS_COUNTER"

// FORMAT DATES
export const FORMAT_DATES_LOADED = "FORMAT_DATES_LOADED"
