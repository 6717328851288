import React, { FunctionComponent } from "react"
import { FormPopup } from "@components/formFields"
import { WrappedFieldProps } from "redux-form"
import { FormPopupProps } from "@components/formFields/formPopup/formPopup"

interface FormInputProps extends WrappedFieldProps {
  className: string
  textError: string
  title: string
  type: string
  placeholder: string
  popup: FormPopupProps
  disabled: boolean
}

export const FormInput: FunctionComponent<FormInputProps> = (props) => {
  const {
    className = "",
    input,
    placeholder,
    title,
    textError,
    type,
    popup,
  } = props
  const { touched, error } = props.meta
  return (
    <div
      className={`${className} ${
        textError ? "form-input-text-error" : ""
      } form-input`}
    >
      <div className="form-input__title">{title}</div>
      {touched && error && (
        <span className="form-input__error form-field-error">{error}</span>
      )}
      {popup && (
        <FormPopup
          width={popup.width}
          height={popup.height}
          trigger={
            <div className="form-input__hover-icon">
              <i className="far fa-question-circle" />
            </div>
          }
          content={popup.content}
        />
      )}
      <input
        className="form-input__input"
        type={type}
        {...input}
        disabled={props.disabled}
        placeholder={placeholder}
      />
    </div>
  )
}
