import axios from "axios"
import { AnyAction, Dispatch } from "redux"
import { Stripe } from "@stripe/stripe-js"

import { Applicant } from "@common/applicant"
import { SelectedCheckoutableTrackOption } from "@components/applicationForm/trackSelection/trackOptions"
import { popupError } from "@src/actions/popupManager"
import {
  decrementRequestsCounter,
  incrementRequestsCounter,
} from "@src/actions/requestsLoader"

export interface StripeCheckoutSessionParams {
  amount: number
  title: string
  email: string
}

export interface StripeSessionId {
  sessionId: string
}

const fetchCheckoutSession = async (session: StripeCheckoutSessionParams) =>
  axios
    .post<{ sessionId: string }>("/api/payments/create-session", {
      session,
    })
    .then(({ data }) => data)

export const handleCheckout = (
  stripePromise: Promise<Stripe | null>,
  applicant: Applicant,
  track: SelectedCheckoutableTrackOption
) => {
  return (dispatch: Dispatch<AnyAction>) =>
    fetchCheckoutSession({
      amount: track.selectedFormat.price * 100,
      title: track.trackKey,
      email: applicant.email,
    }).then(async ({ sessionId }) => {
      incrementRequestsCounter(dispatch)

      const stripe = await stripePromise
      const { error } = await stripe!.redirectToCheckout({
        sessionId,
      })

      if (error && error.message) {
        decrementRequestsCounter(dispatch)
        dispatch(popupError(error.message))
      }
    })
}
