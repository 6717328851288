import React from "react"
import axios from "axios"
import { AnyAction, Dispatch } from "redux"

import appsignal from "@src/appsignal"
import { Applicant } from "@common/applicant"
import { ApplicantCreateAccountFormValues } from "@components/applicantAuth/applicantCreateAccount"
import { ApplicantResumeApplicationLoginFormValues } from "@components/applicantAuth/applicantResumeApplicationLogin"
import { DataPoints } from "@common/types/dataPoints"
import { FormProps } from "@components/commitmentAssessment/commitmentAssessmentForm"
import { Partner } from "@common/partner"
import { PartnerSite } from "@common/partnerSite"
import {
  DATA_POINTS_LOADED,
  SET_AND_AUTHENTICATE_APPLICANT,
  SET_ACCOUNT_CREATED_INFO_SEEN,
} from "./types"
import {
  SelectedTrackOption,
  trackToDevcampSyllabusSlug,
} from "@components/applicationForm/trackSelection/trackOptions"
import { nextPage } from "./pageLayout"
import { popupError } from "./popupManager"
import { EnrollmentAdvisor } from "@common/applicant"
import { MexicoDocumentCollectionValues } from "@components/documentCollection/mexicoDocumentCollectionForm"
import { DocumentCollectionValues } from "@components/documentCollection/documentCollectionForm"

interface UpdateDataPointsData {
  dataPoints: DataPoints
  partner: Partner
}

export interface UserBaseParams {
  email: string
  first_name: string
  id: number
  last_name: string
}

interface LoadApplicantData {
  dataPoints: DataPoints
  enrollmentAdvisor: EnrollmentAdvisor
  partner: Partner
  user: UserBaseParams
}

// Saves data points to devcamp and stores new values in redux store.
export async function updateDataPoints(
  dispatch: Dispatch<AnyAction>,
  dataPoints: Partial<DataPoints> | FormData
) {
  return axios
    .put<UpdateDataPointsData>(`/api/applicant/data_points`, dataPoints)
    .then(({ data }) => {
      dispatch({
        payload: {
          dataPoints: data.dataPoints,
          partner: data.partner,
        },
        type: DATA_POINTS_LOADED,
      })
    })
}

export function setAndAuthenticateApplicant(
  dispatch: Dispatch<AnyAction>,
  user: UserBaseParams
) {
  appsignal.addDecorator((span) =>
    span.setTags({ user_id: JSON.stringify(user.id) })
  )

  dispatch({
    type: SET_AND_AUTHENTICATE_APPLICANT,
    payload: user,
  })
}

export function applicantSignUp(
  fields: ApplicantCreateAccountFormValues,
  partnerName: string,
  registrationSource: string | undefined,
  timezone: string,
  recaptchaToken: string,
  searchParams: URLSearchParams
) {
  return function (dispatch: Dispatch<AnyAction>) {
    axios
      .post<{ user: UserBaseParams }>(`/api/applicant/signup`, {
        ...fields,
        partnerName,
        recaptchaToken,
        registrationSource,
        timezone,
      })
      .then((response) => {
        setAndAuthenticateApplicant(dispatch, response.data.user)
      })
      .catch((err) => {
        const isEmailTaken =
          err.response.data.errors &&
          err.response.data.errors.email === "has already been taken"

        const error = isEmailTaken ? (
          <div>
            An account with a given email address already exists.{" "}
            <a
              className="applicant-resume-application-login__inline-link"
              href={`/applicant/signin?${searchParams.toString()}`}
            >
              Please sign in
            </a>{" "}
            to resume the application
          </div>
        ) : (
          err
        )

        dispatch(popupError(error))
      })
  }
}

export function applicantSignIn(
  fields: ApplicantResumeApplicationLoginFormValues,
  partnerName: string | undefined,
  timezone: string,
  registrationSource?: string
) {
  return function (dispatch: Dispatch<AnyAction>) {
    return axios
      .post<{ user: UserBaseParams }>(`/api/applicant/signin`, {
        ...fields,
        partnerName,
        registrationSource,
        timezone,
      })
      .then(({ data }) => setAndAuthenticateApplicant(dispatch, data.user))
      .catch((err) => dispatch(popupError(err)))
  }
}

export function loadApplicant() {
  return function (dispatch: Dispatch<AnyAction>) {
    axios
      .get<LoadApplicantData>(`/api/applicant`)
      .then((response) => {
        setAndAuthenticateApplicant(dispatch, response.data.user)
        dispatch({
          payload: {
            enrollmentAdvisor: response.data.enrollmentAdvisor,
            partner: response.data.partner,
            dataPoints: response.data.dataPoints,
          },
          type: DATA_POINTS_LOADED,
        })
      })
      .catch((err) => dispatch(popupError(err)))
  }
}

export function saveSelectedTrack(
  selectedTrack: SelectedTrackOption,
  endDate?: string
) {
  return function (dispatch: Dispatch<AnyAction>) {
    const data = new FormData()
    const devcampSyllabusSlug = trackToDevcampSyllabusSlug(selectedTrack)
    const format = selectedTrack.selectedFormat

    data.append("data_point[selected_format]", format.pace)
    data.append(
      "data_point[selected_start_date]",
      format.selectedStartDate || "null"
    )
    if (devcampSyllabusSlug)
      data.append("data_point[selected_track_slug]", devcampSyllabusSlug)

    if (endDate) data.append("data_point[end_date]", endDate)

    return updateDataPoints(dispatch, data).catch((err) =>
      dispatch(popupError(err))
    )
  }
}

export function saveApplicantInfo(
  applicantInfo: Applicant["applicantInfo"],
  site: PartnerSite | undefined
) {
  return function (dispatch: Dispatch<AnyAction>) {
    const data = new FormData()
    const dateOfBirth = applicantInfo.dateOfBirth!.format("YYYY-MM-DD")

    const fields = {
      address: applicantInfo.streetAddress,
      citizenship: applicantInfo.citizenship,
      city: applicantInfo.city,
      colleges_or_universities_attended_previously:
        applicantInfo.collegesOrUniversitiesAttendedPreviously,
      country: applicantInfo.country,
      degree_earned: applicantInfo.degreeEarned,
      education_level: applicantInfo.highestEducationLevel,
      emergency_contact: applicantInfo.emergencyContact,
      gender: applicantInfo.gender,
      high_school_of_graduation_or_equivalent:
        applicantInfo.highSchoolOfGraduationOrEquivalent,
      how_many_terms_of_college_attended:
        applicantInfo.howManyTermsOfCollegeAttended,
      mailing_address: applicantInfo.mailingStreetAddress,
      mailing_city: applicantInfo.mailingCity,
      mailing_country: applicantInfo.mailingCountry,
      mailing_state: applicantInfo.mailingState,
      mailing_zipcode: applicantInfo.mailingZipcode,
      military_service: applicantInfo.military,
      phone_number: applicantInfo.phoneNumber,
      phone_number_type: applicantInfo.phoneNumberType,
      previous_coding_experience: applicantInfo.previousCodingExperience,
      reference_1_address: applicantInfo.reference1_streetAddress,
      reference_1_city: applicantInfo.reference1_city,
      reference_1_country: applicantInfo.reference1_country,
      reference_1_name: applicantInfo.reference1_name,
      reference_1_phone_number: applicantInfo.reference1_phoneNumber,
      reference_1_state: applicantInfo.reference1_state,
      reference_1_zipcode: applicantInfo.reference1_zipcode,
      reference_2_address: applicantInfo.reference2_streetAddress,
      reference_2_city: applicantInfo.reference2_city,
      reference_2_country: applicantInfo.reference2_country,
      reference_2_name: applicantInfo.reference2_name,
      reference_2_phone_number: applicantInfo.reference2_phoneNumber,
      reference_2_state: applicantInfo.reference2_state,
      reference_2_zipcode: applicantInfo.reference2_zipcode,
      social_security_number: applicantInfo.socialSecurityNumber,
      state: applicantInfo.state,
      va_file_number_and_chapter: applicantInfo.VAFileNumberAndChapter,
      zipcode: applicantInfo.zipcode,
      site_identifier: site && site.name,
    }

    const yesNoFields = {
      has_diploma_or_ged: applicantInfo.diplomaOrGed,
      has_high_school_diploma: applicantInfo.highSchoolDiploma,
      us_citizen: applicantInfo.usCitizen,
    }

    data.append("data_point[date_of_birth]", dateOfBirth)

    for (const key in fields) {
      if (fields[key]) {
        data.append(`data_point[${key}]`, fields[key])
      }
    }

    for (const key in yesNoFields) {
      if (yesNoFields[key] != undefined) {
        data.append(
          `data_point[${key}]`,
          JSON.stringify(yesNoFields[key] == "Yes")
        )
      }
    }

    updateDataPoints(dispatch, data)
      .then(() => nextPage(dispatch))
      .catch((err) => dispatch(popupError(err)))
  }
}

export function saveMexicoDocuments(values: MexicoDocumentCollectionValues) {
  return function (dispatch: Dispatch<AnyAction>) {
    const data = new FormData()

    data.append("data_point[city_of_birth]", values.cityOfBirth)
    data.append("data_point[country_of_birth]", values.countryOfBirth)
    data.append("data_point[official_id_number]", values.officialIDNumber)
    data.append("data_point[official_id_type]", values.officialIDType)
    data.append("data_point[photo_id]", values.photoId as File)
    data.append(
      "data_point[proof_of_residency]",
      values.proofOfResidency as File
    )

    if (values.nss) {
      data.append("data_point[numero_de_seguridad_social]", values.nss)
    }

    if (values.rfc) {
      data.append("data_point[registro_federal_de_contribuyentes]", values.rfc)
    }

    if (values.curp) {
      data.append(
        "data_point[clave_unica_de_registro_de_poblacion]",
        values.curp
      )
    }

    updateDataPoints(dispatch, data)
      .then(() => nextPage(dispatch))
      .catch((err) => dispatch(popupError(err)))
  }
}

export function saveDocuments(values: DocumentCollectionValues) {
  return function (dispatch: Dispatch<AnyAction>) {
    const data = new FormData()

    data.append("data_point[government_id]", values.governmentId as File)
    data.append(
      "data_point[official_high_school_transcript]",
      values.officialHighSchoolTranscript as File
    )

    updateDataPoints(dispatch, data)
      .then(() => nextPage(dispatch))
      .catch((err) => dispatch(popupError(err)))
  }
}

function storeSignature(
  type: "enrollment" | "bottega_isa",
  dispatch: Dispatch<AnyAction>
) {
  const date = new Date()

  return updateDataPoints(dispatch, {
    [`${type}_agreement_signature_timestamp`]: date.toJSON(),
  }).catch((err) => dispatch(popupError(err)))
}

export function setAccountCreatedInfoSeen() {
  return function (dispatch: Dispatch<AnyAction>) {
    dispatch({ type: SET_ACCOUNT_CREATED_INFO_SEEN })
  }
}

export function storeEnrollmentAgreementSignature() {
  return function (dispatch: Dispatch<AnyAction>) {
    storeSignature("enrollment", dispatch).then(() => {
      axios
        .post("/api/applicant/send-enrollment-agreement", {})
        .catch((err) => dispatch(popupError(err)))
    })
  }
}

export function storeConcurrentEnrollmentOption(value: Boolean) {
  return function (dispatch: Dispatch<AnyAction>) {
    const data = new FormData()

    data.append(
      "data_point[wish_to_participate_in_concurrent_enrollment]",
      JSON.stringify(value)
    )

    return updateDataPoints(dispatch, data).catch((err) =>
      dispatch(popupError(err))
    )
  }
}

export function submitCommitment(values: FormProps) {
  return function (dispatch: Dispatch<AnyAction>) {
    const data = new FormData()

    data.append(
      "data_point[attendance_agreement]",
      values.attendanceAgreement.toString()
    )
    data.append(
      "data_point[graduate_commitment]",
      values.graduateCommitment.toString()
    )
    data.append("data_point[hours_per_week]", values.hoursPerWeek.toString())
    if (values.otherCommitments) {
      data.append("data_point[other_commitments]", values.otherCommitments)
    }
    data.append("data_point[reason_for_learning]", values.reasonForLearning)
    data.append(
      "data_point[weekly_standing_appointment]",
      values.weeklyStandingAppointment.toString()
    )

    updateDataPoints(dispatch, data)
      .then(() => nextPage(dispatch))
      .catch((err) => dispatch(popupError(err)))
  }
}
