import React, { Component } from "react"
import classNames from "classnames"
import { connect } from "react-redux"

import { setPageLayoutPages, setPage } from "@src/actions"

import { enrollmentSteps } from "@src/enrollmentSteps"
import { scrollElementTo } from "@src/helpers"

class PageLayout extends Component {
  componentDidMount() {
    const pages = enrollmentSteps(
      this.props.partner,
      this.props.location.pathname === "/part-two"
    )
    this.props.setPageLayoutPages(pages)

    if (this.props.componentFromPath) {
      const index = pages
        .map(({ title }) => title)
        .indexOf(this.props.componentFromPath)

      if (index != -1) this.props.setPage(index)
    }
  }

  renderActivePage = () => {
    return this.props.pages.map(({ component }, index) => {
      if (this.props.renderPageAtIndex === index) {
        return (
          <div className="page-layout__content" key={index}>
            {component}
          </div>
        )
      }
    })
  }

  render() {
    return (
      <div className="page-layout">
        <PageHeader />
        <PageBar
          renderPageAtIndex={this.props.renderPageAtIndex}
          pages={this.props.pages}
        />
        {this.renderActivePage()}
      </div>
    )
  }
}

const PageHeader = () => {
  return (
    <div className="branded-header page-layout__page-header">
      <img
        className="branded-header__logo"
        src="/assets/bottega-white-logo.png"
      />
    </div>
  )
}

class PageBar extends Component {
  constructor(props) {
    super(props)
    this.pageBar = React.createRef()
  }

  componentDidUpdate() {
    const pageBarEl = this.pageBar.current
    const currentIdx = this.props.renderPageAtIndex
    const diamondWidth = pageBarEl.scrollWidth / this.props.pages.length
    const centerOffset = (pageBarEl.clientWidth - diamondWidth) / 2
    const px = currentIdx * diamondWidth - centerOffset
    scrollElementTo(pageBarEl, px, 0)
  }

  render() {
    return (
      <div className="page-bar page-layout__page-bar" ref={this.pageBar}>
        {this.props.pages.map((page, index) => (
          <PageBarDiamond
            component={page.component}
            diamondNumber={index + 1}
            done={index <= this.props.renderPageAtIndex}
            key={index}
            stepTitle={page.stepTitle}
            title={page.title}
          />
        ))}
      </div>
    )
  }
}

class PageBarDiamond extends Component {
  render() {
    const containerClassNames = classNames("page-bar-diamond", {
      "page-bar-diamond-done": this.props.done,
    })
    const lineClassNames = classNames("page-bar-diamond__line", {
      "page-bar-diamond__line-dashed": this.props.stepTitle !== undefined,
    })
    const prependWithLine = this.props.diamondNumber > 1
    return (
      <div className={containerClassNames}>
        {prependWithLine && <div className={lineClassNames} />}
        <div className="page-bar-diamond__number">
          {this.props.diamondNumber}
        </div>
        <div className="page-bar-diamond__diamond" />
        <div className="page-bar-diamond__step-title">
          {this.props.stepTitle}
        </div>
        <div className="page-bar-diamond__title">{this.props.title}</div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { pages, renderPageAtIndex } = state.pageLayout
  const partner = state.partner
  return { pages, renderPageAtIndex, partner }
}

const PageLayoutContainer = connect(mapStateToProps, {
  setPageLayoutPages,
  setPage,
})(PageLayout)

export default PageLayoutContainer
