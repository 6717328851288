import React, { Component } from "react"

import { connect } from "react-redux"

class CohortDetails extends Component {
  render() {
    const { logoUrl, title, selectedDate } = this.props
    return (
      <div className="cohort-details">
        <img className="cohort-details__logo" src={`/assets/${logoUrl}`} />
        <div className="cohort-details__title">{title}</div>
        <div className="cohort-details__subtitle">{title}</div>
        {selectedDate != null ? (
          <div className="cohort-details__start-date">
            Cohort Start Date: {new Date(selectedDate).toDateString()}
          </div>
        ) : (
          ""
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {
    logoUrl,
    title,
    selectedFormat,
  } = state.applicant.applicant.selectedTrack
  const { selectedStartDate } = selectedFormat
  return { logoUrl, title, selectedStartDate }
}

const CohortDetailsContainer = connect(mapStateToProps)(CohortDetails)

export default CohortDetailsContainer
