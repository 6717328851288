import React, { Component } from "react"

import { connect } from "react-redux"
import { formatCalendlyLink } from "@src/helpers"
import { loadApplicant, nextPage } from "@src/actions"

import StudentOrientationForm from "@components/accountSetup/studentOrientationForm"
import config from "@src/config/keys"
import { Applicant } from "@common/applicant"
import { SelectedTrackFormat } from "@components/applicationForm/trackSelection/trackOptions"
import { State } from "@src/reducers"

interface Props {
  calendlyLink: string
  loadApplicant: () => void
  nextPage: () => void
  scheduled: boolean
}

class ScheduleOrientation extends Component<Props> {
  proceed = () => {
    this.props.loadApplicant()
    this.props.nextPage()
  }

  render() {
    return (
      <div className="schedule-orientation layout__content">
        {
          <StudentOrientationForm
            isScheduled={this.props.scheduled}
            calendlyLink={this.props.calendlyLink}
            onSubmit={this.proceed}
          />
        }
      </div>
    )
  }
}

const selectedFormatToCalendlyEventType = (
  selectedFormat: SelectedTrackFormat
) => (selectedFormat.title.includes("Part-Time") ? "part-time" : "full-time")

const applicantCalendlyLink = (applicant: Applicant) => {
  const { applicantInfo } = applicant
  const { selectedFormat } = applicant.selectedTrack!
  const selectedSite = applicant.selectedSite

  const eventType =
    selectedSite && selectedSite.calendlyEventType
      ? selectedSite.calendlyEventType
      : selectedFormatToCalendlyEventType(selectedFormat)

  const url = `https://calendly.com/${config.calendlyAccount}/${eventType}`
  return formatCalendlyLink(url, applicantInfo)
}

const mapStateToProps = (state: State) => {
  const scheduled = state.applicant.applicant.orientationMeetingAt !== null
  const calendlyLink = applicantCalendlyLink(
    state.applicant.applicant as Applicant
  )

  return { calendlyLink, scheduled }
}

const ScheduleOrientationContainer = connect(mapStateToProps, {
  loadApplicant,
  nextPage,
})(ScheduleOrientation)

export default ScheduleOrientationContainer
