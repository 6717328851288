import React from "react"

import config from "@src/config/keys"
import { FormPopup } from "@components/formFields"

export const FormPasswordInput = (props) => (
  <div
    className={`${props.className} ${
      props.textError ? "form-input-text-error" : ""
    } form-input`}
  >
    {(props.meta.touched && props.meta.error && (
      <span className="form-input__error form-field-error">
        {props.meta.error}
      </span>
    )) || (
      <div className="form-input__subtitle">
        <a href={config.devcampNewPasswordUrl}>Forgot Password?</a>
        <FormPopup
          trigger={
            <div className="form-input__hover-icon">
              <i className="far fa-question-circle" />
            </div>
          }
          content={
            <span>
              Clicking on the link will redirect you to devcamp.com which will
              instruct you how to set a new password.
            </span>
          }
        />
      </div>
    )}
    <div className="form-input__title">Password</div>
    <input className="form-input__input" type="password" {...props.input} />
  </div>
)
