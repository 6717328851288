import { every, pick } from "lodash"

import { DataPoints } from "@common/types/dataPoints"
import { SelectedOption } from "@components/financialGuidance/financingOptions"
import {
  DATA_POINTS_LOADED,
  FINANCIAL_SURVEY_SUBMITTED,
  PAYMENT_OPTION_SELECTED,
  RETAKE_FINANCIAL_SURVEY,
} from "@src/actions/types"

interface DataPointsLoaded {
  type: "DATA_POINTS_LOADED"
  payload: {
    dataPoints: DataPoints
  }
}

interface FinancialSurveySubmitted {
  type: "FINANCIAL_SURVEY_SUBMITTED"
}

interface PaymentOptionSelected {
  type: "PAYMENT_OPTION_SELECTED"
  payload: {
    selectedOption: SelectedOption
  }
}

interface RetakeFinancialSurvey {
  type: "RETAKE_FINANCIAL_SURVEY"
}

export type Action =
  | DataPointsLoaded
  | FinancialSurveySubmitted
  | PaymentOptionSelected
  | RetakeFinancialSurvey

export interface FinancialGuidanceState {
  selectedOption?: SelectedOption
  surveyTaken: boolean
}

const INITIAL_STATE: FinancialGuidanceState = {
  selectedOption: undefined,
  surveyTaken: false,
}

export default function (state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case DATA_POINTS_LOADED: {
      const data = action!.payload.dataPoints
      const survey = pick(data, [
        "has_another_income_sharing_agreement",
        "has_defaulted_loan",
        "has_loan_cosigner_available",
        "industry_employment",
        "salary_before_starting",
        "current_employment_status",
      ])
      const surveyTaken = every(
        survey,
        (value) => value !== null && value !== undefined
      )
      return { ...state, surveyTaken }
    }
    case FINANCIAL_SURVEY_SUBMITTED:
      return { ...state, surveyTaken: true }
    case PAYMENT_OPTION_SELECTED: {
      const { selectedOption } = action.payload
      return { ...state, selectedOption }
    }
    case RETAKE_FINANCIAL_SURVEY:
      return { ...state, selectedOption: undefined, surveyTaken: false }
    default:
      return state
  }
}
