import axios from "axios"
import { AnyAction, Dispatch } from "redux"

import {
  RETAKE_FINANCIAL_SURVEY,
  PAYMENT_OPTION_SELECTED,
  FINANCIAL_SURVEY_SUBMITTED,
} from "./types"
import { Action } from "@src/reducers/financialGuidanceReducer"
import {
  PaymentOptionRequest,
  FinancialSurvey,
} from "@common/types/financialGuidance"
import { SelectedOption } from "@components/financialGuidance/financingOptions"
import { nextPage } from "./pageLayout"
import { popupError } from "./popupManager"
import { updateDataPoints } from "./applicant"

export const paymentOptionSelected = (
  selectedOption: SelectedOption
): Action => ({
  type: PAYMENT_OPTION_SELECTED,
  payload: {
    selectedOption,
  },
})

export const retakeFinancialSurvey = (): Action => ({
  type: RETAKE_FINANCIAL_SURVEY,
})

export const saveFinancialSurvey = (survey: FinancialSurvey) => {
  return function (dispatch: Dispatch<AnyAction>) {
    const data = new FormData()
    const defaulted = JSON.stringify(survey.defaulted === "Yes")
    const cosigner = JSON.stringify(survey.cosigner === "Yes")

    data.append("data_point[has_defaulted_loan]", defaulted)
    data.append("data_point[has_loan_cosigner_available]", cosigner)
    data.append("data_point[industry_employment]", survey.industryEmployment)
    data.append("data_point[salary_before_starting]", survey.income)
    data.append(
      "data_point[current_employment_status]",
      survey.currentEmploymentStatus
    )

    if (survey.incomeSharingAgreement != undefined) {
      const anotherISA = JSON.stringify(survey.incomeSharingAgreement === "Yes")
      data.append(
        "data_point[has_another_income_sharing_agreement]",
        anotherISA
      )
    }

    updateDataPoints(dispatch, data)
      .then(() => {
        dispatch({
          type: FINANCIAL_SURVEY_SUBMITTED,
        })
      })
      .catch((err: any) => {
        dispatch(popupError(err))
      })
  }
}

export const saveFinancialPaymentOption = (
  selectedOption: SelectedOption,
  payload: PaymentOptionRequest
) => {
  return function (dispatch: Dispatch<AnyAction>) {
    const data = new FormData()
    data.append("data_point[financing_category]", selectedOption.category)
    data.append("data_point[financing_option]", selectedOption.title)

    updateDataPoints(dispatch, data)
      .then(() =>
        axios.post("/api/applicant/financial-guidance/payment-option", payload)
      )
      .then(() => nextPage(dispatch))
      .catch((err) => dispatch(popupError(err)))
  }
}
