import React from "react"
import * as t from "io-ts"

import config from "@src/config/keys"
import { FinancialSurvey } from "@common/types/financialGuidance"

export const Category = t.union([
  t.literal("accede"),
  t.literal("direct"),
  t.literal("macu"),
  t.literal("skillsfund"),
])
export type Category = t.TypeOf<typeof Category> // eslint-disable-line no-redeclare

export const SelectedOption = t.type({
  category: Category,
  title: t.string,
})
export type SelectedOption = t.TypeOf<typeof SelectedOption> // eslint-disable-line no-redeclare

export interface CategoryOptions {
  category: Category
  options: FinancingOption[]
}

export interface FinancingOption {
  details?: string
  emailImage?: string
  frequency: string
  logoURL: string
  price: string
  title: string
  type: "Full-Time" | "Part-Time"
  url: string
}

export const accede: FinancingOption = {
  url: "https://accedeeducacion.com/bottega/",
  type: "Part-Time",
  logoURL: "/assets/accede-logo.png",
  title: "Accede Educación",
  price: "$3,878.81+ MXN",
  frequency: "Monthly payments",
}

export const directFullTimePayInFull: FinancingOption = {
  url: `https://calendly.com/${config.calendlyAccount}/bottega-direct-pay`,
  emailImage: "/direct.jpg",
  type: "Full-Time",
  logoURL: "/assets/bottega-white-logo.png",
  title: "Pay-In-Full",
  price: "$12,000",
  frequency: "One Time Payment",
  details:
    "No Interest. No prepayment penalties. No Cosign Option, No living stipend.",
}

export const directFullTimeInstallmentPlan: FinancingOption = {
  url: `https://calendly.com/${config.calendlyAccount}/bottega-direct-pay`,
  emailImage: "/direct.jpg",
  type: "Full-Time",
  logoURL: "/assets/bottega-white-logo.png",
  title: "Installment Plan",
  price: "$4,000/mo",
  frequency: "(3 Installments Over 3 Months)",
  details:
    "No Interest. No prepayment penalties. No Cosign Option, No living stipend.",
}

export const directPartTimePayInFull: FinancingOption = {
  url: `https://calendly.com/${config.calendlyAccount}/bottega-direct-pay`,
  emailImage: "/direct.jpg",
  type: "Part-Time",
  logoURL: "/assets/bottega-white-logo.png",
  title: "Pay-In-Full",
  price: "$7,500",
  frequency: "One Time Payment",
  details:
    "Some restrictions may apply. Ask your advisor for more information.",
}

export const directPartTimeInstallmentPlan: FinancingOption = {
  url: `https://calendly.com/${config.calendlyAccount}/bottega-direct-pay`,
  emailImage: "/direct.jpg",
  type: "Part-Time",
  logoURL: "/assets/bottega-white-logo.png",
  title: "Installment Plan",
  price: "$750/mo",
  frequency: "(10 Installments Over 10 Months)",
  details:
    "No Interest. No prepayment penalties. No Cosign Option, No living stipend.",
}

export const skillsfundFullTime3YearLoan: FinancingOption = {
  url: "https://bottega.skills.fund/",
  emailImage: "/skillsfund.jpg",
  type: "Full-Time",
  logoURL: "/assets/skills-fund-logo.png",
  title: "3 Year Loan",
  price: "$93.50/mo",
  frequency: "Paid While Attending",
  details:
    "Interest Applied. No prepayment penalties, cosign option available, living stipend up to $4,000 available (Full-Time only)",
}

export const skillsfundFullTime5YearLoan: FinancingOption = {
  url: "https://bottega.skills.fund/",
  emailImage: "/skillsfund.jpg",
  type: "Full-Time",
  logoURL: "/assets/skills-fund-logo.png",
  title: "5 Year Loan",
  price: "$114.30/mo",
  frequency: "Paid While Attending",
  details:
    "Interest Applied. No prepayment penalties, cosign option available, living stipend up to $4,000 available (Full-Time only)",
}

export const skillsfundPartTime3YearLoan: FinancingOption = {
  url: "https://bottega.skills.fund/",
  emailImage: "/skillsfund.jpg",
  type: "Part-Time",
  logoURL: "/assets/skills-fund-logo.png",
  title: "3 Year Loan",
  price: "$58.44/mo",
  frequency: "Paid While Attending",
  details:
    "Interest Applied. No prepayment penalties, cosign option available, living stipend up to $4,000 available (Full-Time only)",
}

export const skillsfundPartTime5YearLoan: FinancingOption = {
  url: "https://bottega.skills.fund/",
  emailImage: "/skillsfund.jpg",
  type: "Part-Time",
  logoURL: "/assets/skills-fund-logo.png",
  title: "5 Year Loan",
  price: "$71.44/mo",
  frequency: "Paid While Attending",
  details:
    "Interest Applied. No prepayment penalties, cosign option available, living stipend up to $4,000 available (Full-Time only)",
}

export const macuFullTime3YearLoan: FinancingOption = {
  url: "https://www.macu.com/loans/personal-loans/student-loan",
  emailImage: "/macu.jpg",
  type: "Full-Time",
  logoURL: "/assets/macu-logo.png",
  title: "3 Year Loan",
  price: "$90-120/mo",
  frequency: "Paid While Attending",
  details:
    "Availability: UT, NM, AZ, ID, NV. Interest Applied. Prepayment Penalties (n/a), Cosign Opt. Available. Living stipend Opt. (n/a).",
}

export const macuFullTime5YearLoan: FinancingOption = {
  url: "https://www.macu.com/loans/personal-loans/student-loan",
  emailImage: "/macu.jpg",
  type: "Full-Time",
  logoURL: "/assets/macu-logo.png",
  title: "5 Year Loan",
  price: "$120-150/mo",
  frequency: "Paid While Attending",
  details:
    "Availability: UT, NM, AZ, ID, NV. Interest Applied. Prepayment Penalties (n/a), Cosign Opt. Available. Living stipend Opt. (n/a).",
}

export const macuPartTime3YearLoan: FinancingOption = {
  url: "https://www.macu.com/loans/personal-loans/student-loan",
  emailImage: "/macu.jpg",
  type: "Part-Time",
  logoURL: "/assets/macu-logo.png",
  title: "3 Year Loan",
  price: "$50-75/mo",
  frequency: "Paid While Attending",
  details:
    "Availability: UT, NM, AZ, ID, NV. Interest Applied. Prepayment Penalties (n/a), Cosign Opt. Available. Living stipend Opt. (n/a).",
}

export const macuPartTime5YearLoan: FinancingOption = {
  url: "https://www.macu.com/loans/personal-loans/student-loan",
  emailImage: "/macu.jpg",
  type: "Part-Time",
  logoURL: "/assets/macu-logo.png",
  title: "5 Year Loan",
  price: "$75-100/mo",
  frequency: "Paid While Attending",
  details:
    "Availability: UT, NM, AZ, ID, NV. Interest Applied. Prepayment Penalties (n/a), Cosign Opt. Available. Living stipend Opt. (n/a).",
}

const partnerFinancingOptions: {
  [key in Category]: { [partnerName: string]: FinancingOption[] }
} = {
  accede: {
    mx: [accede],
  },

  direct: {
    acu: [directFullTimePayInFull],
    bottega: [directFullTimePayInFull, directPartTimeInstallmentPlan],
    rails: [directFullTimePayInFull, directPartTimeInstallmentPlan],
  },

  macu: {
    acu: [
      macuFullTime3YearLoan,
      macuFullTime5YearLoan,
      macuPartTime3YearLoan,
      macuPartTime5YearLoan,
    ],
    bottega: [
      macuFullTime3YearLoan,
      macuFullTime5YearLoan,
      macuPartTime3YearLoan,
      macuPartTime5YearLoan,
    ],
    rails: [
      macuFullTime3YearLoan,
      macuFullTime5YearLoan,
      macuPartTime3YearLoan,
      macuPartTime5YearLoan,
    ],
    uvu: [
      macuFullTime3YearLoan,
      macuFullTime5YearLoan,
      macuPartTime3YearLoan,
      macuPartTime5YearLoan,
    ],
  },

  skillsfund: {
    acu: [
      skillsfundFullTime3YearLoan,
      skillsfundFullTime5YearLoan,
      skillsfundPartTime3YearLoan,
      skillsfundPartTime5YearLoan,
    ],
    bottega: [
      skillsfundFullTime3YearLoan,
      skillsfundFullTime5YearLoan,
      skillsfundPartTime3YearLoan,
      skillsfundPartTime5YearLoan,
    ],
    rails: [
      skillsfundFullTime3YearLoan,
      skillsfundFullTime5YearLoan,
      skillsfundPartTime3YearLoan,
      skillsfundPartTime5YearLoan,
    ],
    uvu: [
      skillsfundFullTime3YearLoan,
      skillsfundFullTime5YearLoan,
      skillsfundPartTime3YearLoan,
      skillsfundPartTime5YearLoan,
    ],
  },
}

export const categoryProperties: {
  [category in Category]: { description: React.ReactNode; title: string }
} = {
  accede: {
    title: "Bottega - Accede Educación",
    description: <p>Obtén un préstamo para tu bootcamp en Bottega</p>,
  },

  direct: {
    title: "Direct Payment Options",
    description: (
      <p>Great for securing financing on your own, or paying outright.</p>
    ),
  },
  macu: {
    title: "Traditional Student Loan Options With MACU",
    description: (
      <p>
        Great for students with fair to exceptional credit. Students make
        smaller payments while attending. Actual monthly cost varies upon
        student’s credit score. Speak with your local MACU branch for more
        information.
      </p>
    ),
  },
  skillsfund: {
    title: "Traditional Student Loan Options With Skills Fund",
    description: (
      <p>
        Great for students with fair to exceptional credit. Students make
        smaller payments while attending. Living stipend available.
      </p>
    ),
  },
}

interface ApplicantCommitment {
  attendanceAgreement?: boolean
  hoursPerWeek?: number
  graduateCommitment?: boolean
  weeklyStandingAppointment?: boolean
}

export interface ApplicantFinancialInfo {
  applicantState: string
  commitment?: ApplicantCommitment
  formatTitle: string
  partnerName: string
  survey: FinancialSurvey
}

export function financingOptions(
  category: Category,
  financialInfo: ApplicantFinancialInfo
): FinancingOption[] {
  const partnerName = financialInfo.partnerName

  if (isApplicantFinancialInfoFine(category, partnerName, financialInfo)) {
    const options = partnerFinancingOptions[category][partnerName] || []

    return filterByFormat(
      financialInfo.formatTitle,
      options as FinancingOption[]
    )
  } else {
    return []
  }
}

function isApplicantFinancialInfoFine(
  category: Category,
  partnerName: string,
  applicantFinancialInfo: ApplicantFinancialInfo
): boolean {
  const { applicantState } = applicantFinancialInfo
  return (
    (partnerName === "mx" && category === "accede") ||
    (partnerName != "mx" &&
      ((category === "macu" && isMacuState(applicantState)) ||
        category === "direct" ||
        category === "skillsfund"))
  )
}

function isMacuState(applicantState: string): boolean {
  return (
    applicantState == "Nevada" ||
    applicantState == "Arizona" ||
    applicantState == "New Mexico" ||
    applicantState == "Utah" ||
    applicantState == "Idaho"
  )
}

function filterByFormat(
  formatTitle: string,
  options: FinancingOption[]
): FinancingOption[] {
  return options.filter((option) => formatTitle.includes(option.type))
}

export function groupedFinancialOptions(
  applicantFinancialInfo: ApplicantFinancialInfo
): CategoryOptions[] {
  return Category.types
    .map(({ value: category }) => ({
      category,
      options: financingOptions(category, applicantFinancialInfo),
    }))
    .filter(({ options }) => options.length > 0)
}

export function isFinancialOptionsAvailable(
  categoryOptions: CategoryOptions[]
): boolean {
  return categoryOptions.reduce(
    (prev, curr) => prev || curr.options.length > 0,
    false as boolean
  )
}
