import React, { Component } from "react"

class ProcessComplete extends Component {
  render() {
    return (
      <div className="process-complete">
        <img className="process-complete__image" src="/assets/partyIcon.svg" />
        <div className="process-complete__title">Submission Successful</div>
        <div className="process-complete__message">
          You’re one step closer to your new career! We are very
          <br />
          excited to be a part of your life changing journey! We promise to do
          <br />
          all we can to help you succeed.
        </div>
      </div>
    )
  }
}

export default ProcessComplete
