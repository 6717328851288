import React, { Component } from "react"

class EnrollmentComplete extends Component {
  render() {
    return (
      <div className="process-complete">
        <img className="process-complete__image" src="/assets/partyIcon.svg" />
        <div className="process-complete__title">Welcome To Bottega!</div>
        <div className="process-complete__message">
          We're so excited to be a part of your life changing journey! We
          promise to do all we can to help you succeed as a software engineer.
          <div className="mb_20" />
          We can't wait to see you in class!
        </div>
      </div>
    )
  }
}

export default EnrollmentComplete
