import React from "react"
import moment from "moment"

import BaseSlider from "rc-slider/lib/Slider"
import createSliderWithTooltip from "rc-slider/lib/createSliderWithTooltip"
import Tooltip from "rc-tooltip"
import "rc-slider/assets/index.css"
import "rc-tooltip/assets/bootstrap.css"

import { Program } from "./commitmentAssessmentForm"
import {
  SelectedTrackFormat,
  SelectedTrackOption,
} from "@components/applicationForm/trackSelection/trackOptions"
import { DateFormat } from "@src/helpers"

const Slider = createSliderWithTooltip(BaseSlider)

const SliderValue = (props: { text: string; label: string }) => (
  <div className="commitment-slider-value">
    <h1>{props.text}</h1>
    <span>{props.label}</span>
  </div>
)

const roundToHalves = (n: number) => Math.round(n * 2) / 2

const warningText = (
  <div>
    <p>
      The inability to commit to a minimum of 15 hours per week may result in
      application denial and/or limited scholorship opportunities from Bottega.
    </p>
    <br />
    <p>
      You may be contacted by a Bottega advisor to discuss your specific
      circumstances.
    </p>
  </div>
)

interface CommitmentSliderProps {
  onChange: (value: number) => void
  program: Program
  track: SelectedTrackOption
  value: number
}

const programMonthsFromFormat = (format: SelectedTrackFormat): number =>
  format.lengthInWeeks / 4

export const CommitmentSlider: React.FC<CommitmentSliderProps> = (props) => {
  const { program, track, value } = props

  const format = track.selectedFormat
  const showWarning = value < 15
  const hrPerWeek = value
  const foundationsWeeks = roundToHalves((20 / value) * 3)

  const programMonths =
    program === "Full-Time"
      ? programMonthsFromFormat(format)
      : roundToHalves((20 / value) * 6)
  const programDays = (20 / value) * 180
  const selectedDate = format.dates.find(
    (d) => d.startDateString === format.selectedStartDate
  )
  const endDate =
    selectedDate && selectedDate.endDateString
      ? moment(selectedDate.endDateString)
      : moment().add(programDays, "days")

  return (
    <div className="commitment-slider-wrapper">
      <div className="commitment-slider">
        <Tooltip
          overlayClassName="commitment-slider-warning"
          overlay={warningText}
          visible={showWarning}
          placement="left"
        >
          <Slider
            className={showWarning ? " warning" : ""}
            tipProps={{
              placement: "top",
              visible: true,
            }}
            tipFormatter={(value: number) => `${value} Hr`}
            marks={{
              "5": {
                label: "5 Hr",
                style: {
                  width: "auto",
                  margin: 0,
                },
              },
              "15": {
                label: "15 Hr minimum recommended",
                style: {
                  width: "120px",
                  marginLeft: "-60px",
                  top: "-70px",
                  fontSize: "15px",
                },
              },
              "60": {
                label: "60 Hr",
                style: {
                  width: "auto",
                  left: "unset",
                  right: 0,
                },
              },
            }}
            min={5}
            max={60}
            step={1}
            value={value}
            onChange={props.onChange}
          />
        </Tooltip>
      </div>
      <div className="commitment-slider-values">
        <SliderValue text={hrPerWeek.toString()} label="hr/week" />
        <SliderValue
          text={`${foundationsWeeks} w`}
          label="Coding Foundations Completion (est)"
        />
        <SliderValue
          text={`${programMonths} m`}
          label="Full Stack Program completion (est)"
        />
        <SliderValue
          text={endDate.format(DateFormat)}
          label="Full Stack Program Graduation (est)"
        />
      </div>
    </div>
  )
}

CommitmentSlider.propTypes = {
  // TODO: use prop-types
  value: (props, key) =>
    typeof props[key] === "number" && props[key] >= 5 && props[key] <= 60
      ? null
      : new Error(`${key} must be a number between 5 and 60`),
}
