import React, { Component } from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import { reduxForm } from "redux-form"

import Calendly from "@components/calendly"
import GreenTitledList from "@components/greenTitledList"
import Popup from "@components/helpers/popup"
import TitledContainer from "@components/TitledContainer"
import withTopView from "@components/topView"
import { FormButton } from "@components/formFields"
import { nextPage } from "@src/actions"

class ScheduleNewStudentOrientation extends Component {
  render() {
    if (this.props.isScheduled) {
      return (
        <TitledContainer
          title="Schedule New Student Orientation"
          className="student-orientation-form__body"
        >
          <div className="header__black">
            It looks like you have already scheduled your orientation. Go ahead
            and continue.
          </div>
        </TitledContainer>
      )
    } else {
      return (
        <TitledContainer
          title="Schedule New Student Orientation"
          className="student-orientation-form__body"
        >
          <GreenTitledList
            className="student-orientation-form__body__list"
            title="Schedule New Student Orientation"
          >
            <div>
              A Bottega mentor will call you using Zoom, a screenshare
              conference call software.
            </div>
            <div>
              You’ll be onboarded to devCamp, our learning environment, and be
              set-up with all of the tools you need for the program
            </div>
          </GreenTitledList>
          <Calendly
            calendlyLink={this.props.calendlyLink}
            className="student-orientation-form__body__calendly"
          />
        </TitledContainer>
      )
    }
  }
}

class StudentOrientationForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPopupVisible: false,
    }
  }

  togglePopup() {
    this.setState({ isPopupVisible: !this.state.isPopupVisible })
  }

  render() {
    return (
      <div className="student-orientation-form">
        <ScheduleNewStudentOrientation
          type={this.props.type}
          isScheduled={this.props.isScheduled}
          calendlyLink={this.props.calendlyLink}
        />
        <FormButton
          className="student-orientation-form__continue"
          onClick={() => this.togglePopup()}
          title="Continue"
        />
        <Popup
          isVisible={this.state.isPopupVisible}
          message="Before continuing, be sure that you've confirmed your new student orientation appointment! Are you sure that you've finished scheduling?"
          onNo={() => this.togglePopup()}
          onYes={() => this.props.nextPage()}
        />
      </div>
    )
  }
}

const StudentOrientationFormForm = compose(
  connect(null, { nextPage }),
  reduxForm({
    form: "StudentOrientationForm",
  })
)(withTopView(StudentOrientationForm))

export default StudentOrientationFormForm
