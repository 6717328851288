import "core-js/stable"
import "regenerator-runtime/runtime"

import React from "react"
import ReactDOM from "react-dom"
import Thunk from "redux-thunk"
import { Provider } from "react-redux"
import { Route, Routes, BrowserRouter as Router } from "react-router-dom"
import { applyMiddleware, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"

import appsignal from "./appsignal"
import * as appsignalNetwork from "@appsignal/plugin-breadcrumbs-network"
import * as appsignalEvents from "@appsignal/plugin-window-events"
import * as appsignalPath from "@appsignal/plugin-path-decorator"

appsignal.use(appsignalPath.plugin())
appsignal.use(appsignalEvents.plugin())
appsignal.use(appsignalNetwork.plugin())

import reducers from "./reducers"
import ErrorReporter from "@src/errorReporter"

import Axios from "axios"
import {
  requestInterceptor,
  responseInterceptor,
  errorInterceptor,
} from "./axios"

// STYLES
import "./style/main.scss"

// APPLICANT
import ApplicantCreateAccountCard from "./components/applicantAuth/applicantCreateAccount"
import ApplicantResumeApplicationLoginCard from "./components/applicantAuth/applicantResumeApplicationLogin"

// NAV & LAYOUT
import AuthenticatedPageLayout from "@components/PageLayout/authenticatedPageLayout"
import HelpIcon from "./components/helpIcon"
import Loader from "./components/loader"
import PopupManager from "./components/popupManager"
import accountLayout from "./components/PageLayout/accountLayout"

import TermsOfUse from "@components/applicantAuth/termsOfUse"

const store = createStore(reducers, composeWithDevTools(applyMiddleware(Thunk)))

Axios.interceptors.request.use(requestInterceptor(store.dispatch))
Axios.interceptors.response.use(
  responseInterceptor(store.dispatch),
  errorInterceptor(store.dispatch)
)

ReactDOM.render(
  <ErrorReporter tracker={appsignal}>
    <Provider store={store}>
      <div>
        <Router>
          <Routes>
            <Route
              path="/applicant/signin"
              element={accountLayout(ApplicantResumeApplicationLoginCard)}
            />
            <Route
              path="/applicant/signup"
              element={accountLayout(ApplicantCreateAccountCard)}
            />
            <Route path="/terms-of-use" element={accountLayout(TermsOfUse)} />
            <Route path="/" element={<AuthenticatedPageLayout />} />
            <Route
              path="/payment"
              element={<AuthenticatedPageLayout componentFromPath="Payment" />}
            />
            <Route
              path="/payment-incomplete"
              element={<AuthenticatedPageLayout componentFromPath="Payment" />}
            />
            <Route path="/:page" element={<AuthenticatedPageLayout />} />
          </Routes>
        </Router>
        <PopupManager />
        <Loader />
        <HelpIcon />
      </div>
    </Provider>
  </ErrorReporter>,
  document.querySelector(".app-wrapper")
)
