import React, { Component } from "react"

class NumberedList extends Component {
  render() {
    const { listItems } = this.props
    return (
      <div className="numbered-list">
        {listItems
          ? listItems.map((item, index) => {
              const number = listItems.length > 1 ? `${index + 1}. ` : ""
              return (
                <div key={index} className="numbered-list__item">
                  <div className="numbered-list__item__title">
                    {number}
                    {item.title}
                  </div>
                  <div className="numbered-list__item__description">
                    {item.description}
                  </div>
                  <div className="numbered-list__item__content-component">
                    {item.contentComponent}
                  </div>
                  {item.postDescription ? (
                    <div className="numbered-list__item__description">
                      {item.postDescription}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )
            })
          : ""}
      </div>
    )
  }
}

export default NumberedList
