import moment from "moment"
import { DataPoints } from "./types/dataPoints"
import { Partner } from "./partner"
import {
  TrackFormatDatesPerSlug,
  SelectedTrackFormat,
  SelectedTrackOption,
} from "@components/applicationForm/trackSelection/trackOptions"
import { findFormat, findTrackBySlug, toYesNo } from "@src/helpers"
import { InitialApplicant } from "@src/reducers/applicantReducer"

export type Applicant = ReturnType<typeof withMappedDataPoints>
export type Documents = Applicant["documents"]

export type EnrollmentAdvisor = {
  full_name: string
} | null

export function requiredInformationSet(applicant: Applicant) {
  return (
    applicant.enrollmentAgreementSignature !== null &&
    applicant.selectedPaymentOption !== null &&
    applicant.selectedSite !== undefined &&
    applicant.selectedTrack !== undefined &&
    applicant.applicantInfo.citizenship !== null &&
    applicant.applicantInfo.city !== null &&
    applicant.applicantInfo.collegesOrUniversitiesAttendedPreviously !== null &&
    applicant.applicantInfo.dateOfBirth !== null &&
    applicant.applicantInfo.emergencyContact !== null &&
    applicant.applicantInfo.firstName !== null &&
    applicant.applicantInfo.highSchoolOfGraduationOrEquivalent !== null &&
    applicant.applicantInfo.lastName !== null &&
    applicant.applicantInfo.phoneNumber !== null &&
    applicant.applicantInfo.streetAddress !== null &&
    applicant.applicantInfo.usCitizen !== null &&
    applicant.applicantInfo.zipcode !== null &&
    applicant.applicantInfo.reference1_city !== null &&
    applicant.applicantInfo.reference1_name !== null &&
    applicant.applicantInfo.reference1_phoneNumber !== null &&
    applicant.applicantInfo.reference1_streetAddress !== null &&
    applicant.applicantInfo.reference1_zipcode !== null &&
    applicant.applicantInfo.reference2_city !== null &&
    applicant.applicantInfo.reference2_name !== null &&
    applicant.applicantInfo.reference2_phoneNumber !== null &&
    applicant.applicantInfo.reference2_streetAddress !== null &&
    applicant.applicantInfo.reference2_zipcode !== null
  )
}

export function withMappedDataPoints(
  applicant: InitialApplicant,
  dataPoints: DataPoints,
  partner: Partner,
  formatDatesPerSlug: TrackFormatDatesPerSlug,
  enrollmentAdvisor: EnrollmentAdvisor
) {
  const dateOfBirth = dataPoints.date_of_birth
    ? moment.utc(dataPoints.date_of_birth)
    : undefined

  const applicantInfo = {
    citizenship: dataPoints.citizenship,
    city: dataPoints.city,
    collegesOrUniversitiesAttendedPreviously:
      dataPoints.colleges_or_universities_attended_previously,
    country: dataPoints.country,
    dateOfBirth,
    day: dateOfBirth && dateOfBirth.format("D"),
    degreeEarned: dataPoints.degree_earned,
    diplomaOrGed: toYesNo(dataPoints.has_diploma_or_ged),
    highSchoolDiploma: toYesNo(dataPoints.has_high_school_diploma),
    emergencyContact: dataPoints.emergency_contact,
    enrollmentAgreementTerminationDate:
      dataPoints.enrollment_agreement_termination_date,
    firstName: applicant.applicantInfo.firstName,
    gender: dataPoints.gender,
    highestEducationLevel: dataPoints.education_level,
    highSchoolOfGraduationOrEquivalent:
      dataPoints.high_school_of_graduation_or_equivalent,
    howManyTermsOfCollegeAttended:
      dataPoints.how_many_terms_of_college_attended,
    lastName: applicant.applicantInfo.lastName,
    mailingCity: dataPoints.mailing_city,
    mailingCountry: dataPoints.mailing_country,
    mailingState: dataPoints.mailing_state,
    mailingStreetAddress: dataPoints.mailing_address,
    mailingZipcode: dataPoints.mailing_zipcode,
    military: dataPoints.military_service,
    month: dateOfBirth && dateOfBirth.format("MMMM"),
    optedInForNcu: dataPoints.opted_in_for_ncu,
    phoneNumber: dataPoints.phone_number,
    phoneNumberType: dataPoints.phone_number_type,
    previousCodingExperience: dataPoints.previous_coding_experience,
    reference1_city: dataPoints.reference_1_city,
    reference1_country: dataPoints.reference_1_country,
    reference1_name: dataPoints.reference_1_name,
    reference1_phoneNumber: dataPoints.reference_1_phone_number,
    reference1_state: dataPoints.reference_1_state,
    reference1_streetAddress: dataPoints.reference_1_address,
    reference1_zipcode: dataPoints.reference_1_zipcode,
    reference2_city: dataPoints.reference_2_city,
    reference2_country: dataPoints.reference_2_country,
    reference2_name: dataPoints.reference_2_name,
    reference2_phoneNumber: dataPoints.reference_2_phone_number,
    reference2_state: dataPoints.reference_2_state,
    reference2_streetAddress: dataPoints.reference_2_address,
    reference2_zipcode: dataPoints.reference_2_zipcode,
    site: dataPoints.site_identifier,
    socialSecurityNumber: dataPoints.social_security_number,
    state: dataPoints.state,
    streetAddress: dataPoints.address,
    usCitizen: toYesNo(dataPoints.us_citizen),
    VAFileNumberAndChapter: dataPoints.va_file_number_and_chapter,
    wishToParticipateInConcurrentEnrollment:
      dataPoints.wish_to_participate_in_concurrent_enrollment,
    year: dateOfBirth && dateOfBirth.format("YYYY"),
    zipcode: dataPoints.zipcode,
  }
  const commitment = {
    attendanceAgreement: dataPoints.attendance_agreement,
    hoursPerWeek: dataPoints.hours_per_week,
    graduateCommitment: dataPoints.graduate_commitment,
    otherCommitments: dataPoints.other_commitments,
    weeklyStandingAppointment: dataPoints.weekly_standing_appointment,
    reasonForLearning: dataPoints.reason_for_learning,
  }
  const documents = {
    cityOfBirth: dataPoints.city_of_birth,
    countryOfBirth: dataPoints.country_of_birth,
    curp: dataPoints.clave_unica_de_registro_de_poblacion,
    governmentId: dataPoints.government_id,
    nss: dataPoints.numero_de_seguridad_social,
    officialHighSchoolTranscript: dataPoints.official_high_school_transcript,
    officialIDNumber: dataPoints.official_id_number,
    officialIDType: dataPoints.official_id_type,
    photoId: dataPoints.photo_id,
    proofOfResidency: dataPoints.proof_of_residency,
    rfc: dataPoints.registro_federal_de_contribuyentes,
  }
  const enrollmentAgreementSignature = {
    ip: dataPoints.enrollment_agreement_signature_ip,
    timestamp: dataPoints.enrollment_agreement_signature_timestamp,
  }
  const financialSurveyAnswers = {
    incomeSharingAgreement: toYesNo(
      dataPoints.has_another_income_sharing_agreement
    ),
    defaulted: toYesNo(dataPoints.has_defaulted_loan),
    cosigner: toYesNo(dataPoints.has_loan_cosigner_available),
    industryEmployment: dataPoints.industry_employment,
    income: dataPoints.salary_before_starting,
    currentEmploymentStatus: dataPoints.current_employment_status,
  }
  const selectedPaymentOption = {
    category: dataPoints.financing_category,
    stripePaymentIntentId: dataPoints.stripe_payment_intent_id,
    title: dataPoints.financing_option,
  }
  const scholarship = {
    amount: dataPoints.scholarship_amount,
    title: dataPoints.scholarship_offered,
  }
  const track = findTrackBySlug(
    partner.name,
    dataPoints.selected_track_slug,
    formatDatesPerSlug
  )
  const format =
    track &&
    findFormat(
      track,
      dataPoints.selected_format,
      dataPoints.selected_track_slug
    )
  const selectedFormat =
    format &&
    ({
      ...format,
      selectedStartDate: dataPoints.selected_start_date,
      selectedFormatCredits: dataPoints.selected_format_credits,
    } as SelectedTrackFormat)
  const selectedTrack =
    selectedFormat &&
    ({
      ...track,
      selectedFormat,
    } as SelectedTrackOption)
  const selectedSite = partner.sites.find(
    (s) => s.name === dataPoints.site_identifier
  )

  return {
    accountCreatedInfoSeen: false,
    applicantInfo,
    commitment,
    documents,
    email: applicant.email,
    enrollmentAdvisor,
    enrollmentAgreementSignature,
    financialSurveyAnswers,
    orientationMeetingAt: dataPoints.orientation_meeting_at,
    scholarship,
    selectedPaymentOption,
    selectedSite,
    selectedTrack,
  }
}
