import React, { Component } from "react"
import { Field, change, getFormValues, reduxForm } from "redux-form"
import { connect } from "react-redux"

import moment from "moment"

import ApplicantInformationCard from "./applicantInformationCard"
import { FormButton } from "@components/formFields"
import { range } from "lodash/util"
import { rangeRight } from "lodash/util"
import {
  Address,
  CitizenshipField,
  CollegesAttendedField,
  DateOfBirthDayField,
  DateOfBirthMonthField,
  DateOfBirthYearField,
  DegreeEarnedField,
  EmergencyContactField,
  FirstNameField,
  GenderField,
  HasDiplomaOrGED,
  HasHighSchoolDiploma,
  HighSchoolOfGraduationField,
  HighestEducationLevelField,
  HowManyTermsOfCollegeAttended,
  LastNameField,
  MailingAddress,
  MilitaryField,
  PhoneNumberField,
  PhoneNumberTypeField,
  PreviousCodingExperienceField,
  ReferenceContact,
  SiteField,
  SocialSecurityNumber,
  UsCitizenField,
  VAFileNumberAndChapter,
} from "./applicantInformationFields"
import withTopView from "@src/components/topView"

class ApplicantInformationForm extends Component {
  constructor(props) {
    super(props)

    this.monthOptions = moment.months()
    this.dayOptions = range(1, 32)
    const d = new Date().getFullYear() - 14
    this.yearOptions = rangeRight(d, d - 81)
  }

  formUsCitizenValue(props) {
    const values = props.formValues || props.initialValues
    return values.usCitizen
  }

  updateCitizenshipValue() {
    return (_event, value) => {
      if (value) {
        this.props.dispatch(
          change("ApplicantInformationForm", "citizenship", "U.S.")
        )
      }
    }
  }

  render() {
    const { handleSubmit, sites, international } = this.props

    return (
      <form
        onSubmit={handleSubmit}
        className="applicant-information-fields-form"
      >
        <ApplicantInformationCard className="applicant-information-fields-form__card">
          <FirstNameField />
          <LastNameField />
          <PhoneNumberField />
          <PhoneNumberTypeField />
          <UsCitizenField onChange={this.updateCitizenshipValue()} />
          <CitizenshipField disabled={this.formUsCitizenValue(this.props)} />
          <EmergencyContactField />

          <div className="applicant-information-fields-form__separator" />

          <Address international={international} />

          <div className="applicant-information-fields-form__separator" />

          <MailingAddress international={international} />

          <div className="applicant-information-fields-form__separator" />

          <DateOfBirthMonthField months={this.monthOptions} />
          <DateOfBirthDayField days={this.dayOptions} />
          <DateOfBirthYearField years={this.yearOptions} />
          <GenderField />

          {this.props.type === "b2b" && sites.length > 1 && (
            <SiteField sites={sites.map((s) => s.name)} />
          )}
          {this.props.type === "b2c" && <MilitaryField />}
          {this.props.showEducationExperienceFields && (
            <>
              <HighestEducationLevelField />
              <PreviousCodingExperienceField />
            </>
          )}

          <HighSchoolOfGraduationField />
          <CollegesAttendedField />
          {(this.props.hasGEDField && <HasDiplomaOrGED />) || (
            <HasHighSchoolDiploma />
          )}
          <HowManyTermsOfCollegeAttended />
          <DegreeEarnedField />

          <VAFileNumberAndChapter />
          <SocialSecurityNumber />

          <div className="applicant-information-fields-form__separator" />

          <ReferenceContact international={international} number="1" />
          <ReferenceContact international={international} number="2" />
        </ApplicantInformationCard>
        <div className="applicant-information-fields-form__buttons">
          <Field
            className="applicant-information-fields-form__continue"
            title="Continue"
            type="submit"
            name="saveandclose"
            component={FormButton}
          />
        </div>
      </form>
    )
  }
}

/**
 * @param {any} values
 * @param {any} form
 * @return {any}
 */
export function validate(values, form) {
  const errors = {}
  const phoneNumberRegex = /^\+?[0-9() -]+$/
  if (!values.phoneNumberType) {
    errors.phoneNumberType = "*"
  }
  if (
    !values.phoneNumber ||
    values.phoneNumber.length < 3 ||
    !phoneNumberRegex.test(values.phoneNumber) ||
    values.phoneNumber.replace(/\D/g, "").length < 3
  ) {
    errors.phoneNumber = "*"
  }
  if (!values.citizenship || values.citizenship.length < 3) {
    errors.citizenship = "*"
  }
  if (!values.emergencyContact || values.emergencyContact.length < 3) {
    errors.emergencyContact = "*"
  }
  if (!values.streetAddress || values.streetAddress.length < 3) {
    errors.streetAddress = "*"
  }
  if (!values.city || values.city.length < 3) {
    errors.city = "*"
  }
  if (!values.state || values.state.length < 3) {
    errors.state = "*"
  }
  if (!values.country || values.country.length < 3) {
    errors.country = "*"
  }
  if (!values.zipcode || values.zipcode.length < 3) {
    errors.zipcode = "*"
  }
  if (!values.month || values.month.length < 3) {
    errors.month = "*"
  }
  if (!values.day) {
    errors.day = "*"
  }
  if (!values.year || values.year.length != 4) {
    errors.year = "*"
  }
  if (!values.gender || values.gender.length < 3) {
    errors.gender = "*"
  }
  if (!values.site || values.site.length < 3) {
    errors.site = "*"
  }
  if (!values.military || values.military.length < 3) {
    errors.military = "*"
  }
  if (
    !values.highestEducationLevel ||
    values.highestEducationLevel.length < 3
  ) {
    errors.highestEducationLevel = "*"
  }
  if (
    !values.previousCodingExperience ||
    values.previousCodingExperience.length < 3
  ) {
    errors.previousCodingExperience = "*"
  }
  if (
    !values.highSchoolOfGraduationOrEquivalent ||
    values.highSchoolOfGraduationOrEquivalent.length < 3
  ) {
    errors.highSchoolOfGraduationOrEquivalent = "*"
  }
  if (
    !values.collegesOrUniversitiesAttendedPreviously ||
    values.collegesOrUniversitiesAttendedPreviously.length < 3
  ) {
    errors.collegesOrUniversitiesAttendedPreviously = "*"
  }
  if (form.hasGEDField) {
    if (!values.diplomaOrGed) {
      errors.diplomaOrGed = "*"
    }
  } else {
    if (!values.highSchoolDiploma) {
      errors.highSchoolDiploma = "*"
    }
  }

  if (!values.reference1_name || values.reference1_name.length < 3) {
    errors.reference1_name = "*"
  }
  if (
    !values.reference1_phoneNumber ||
    values.reference1_phoneNumber.length < 3 ||
    !phoneNumberRegex.test(values.reference1_phoneNumber) ||
    values.reference1_phoneNumber.replace(/\D/g, "").length < 3
  ) {
    errors.reference1_phoneNumber = "*"
  }
  if (
    !values.reference1_streetAddress ||
    values.reference1_streetAddress.length < 3
  ) {
    errors.reference1_streetAddress = "*"
  }
  if (!values.reference1_city || values.reference1_city.length < 3) {
    errors.reference1_city = "*"
  }
  if (!values.reference1_state || values.reference1_state.length < 3) {
    errors.reference1_state = "*"
  }
  if (!values.reference1_country || values.reference1_country.length < 3) {
    errors.reference1_country = "*"
  }
  if (!values.reference1_zipcode || values.reference1_zipcode.length < 3) {
    errors.reference1_zipcode = "*"
  }

  if (!values.reference2_name || values.reference2_name.length < 3) {
    errors.reference2_name = "*"
  }
  if (
    !values.reference2_phoneNumber ||
    values.reference2_phoneNumber.length < 3 ||
    !phoneNumberRegex.test(values.reference2_phoneNumber) ||
    values.reference2_phoneNumber.replace(/\D/g, "").length < 3
  ) {
    errors.reference2_phoneNumber = "*"
  }
  if (
    !values.reference2_streetAddress ||
    values.reference2_streetAddress.length < 3
  ) {
    errors.reference2_streetAddress = "*"
  }
  if (!values.reference2_city || values.reference2_city.length < 3) {
    errors.reference2_city = "*"
  }
  if (!values.reference2_state || values.reference2_state.length < 3) {
    errors.reference2_state = "*"
  }
  if (!values.reference2_country || values.reference2_country.length < 3) {
    errors.reference2_country = "*"
  }
  if (!values.reference2_zipcode || values.reference2_zipcode.length < 3) {
    errors.reference2_zipcode = "*"
  }

  if (
    values.mailingStreetAddress ||
    values.mailingCity ||
    values.mailingState ||
    values.mailingZipcode ||
    values.mailingCountry
  ) {
    if (
      !values.mailingStreetAddress ||
      values.mailingStreetAddress.length < 3
    ) {
      errors.mailingStreetAddress = "*"
    }
    if (!values.mailingCity || values.mailingCity.length < 3) {
      errors.mailingCity = "*"
    }
    if (!values.mailingZipcode || values.mailingZipcode.length < 3) {
      errors.mailingZipcode = "*"
    }
    if (!values.mailingState || values.mailingState.length < 3) {
      errors.mailingState = "*"
    }
    if (!values.mailingCountry || values.mailingCountry.length < 3) {
      errors.mailingCountry = "*"
    }
  }

  return errors
}

function mapStateToProps(state) {
  const { applicantInfo } = state.applicant.applicant
  const { international, name, sites, type } = state.partner
  const initialValues = {
    ...applicantInfo,
    usCitizen: applicantInfo.usCitizen == "Yes",
  }

  // FIXME: Move to the Enrollment App configuration's flags
  const showEducationExperienceFields = type === "b2c" || name === "eleva"
  const hasGEDField = state.partner.flags.hasDiplomaOrGedInput

  return {
    formValues: getFormValues("ApplicantInformationForm")(state),
    hasGEDField,
    initialValues,
    international,
    showEducationExperienceFields,
    sites,
    type,
  }
}

const ApplicantInformationFormContainer = connect(mapStateToProps)(
  reduxForm({
    form: "ApplicantInformationForm",
    validate,
    enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
  })(withTopView(ApplicantInformationForm))
)

export default ApplicantInformationFormContainer
