import {
  SET_SELECTED_TRACK,
  SET_SELECTED_TRACK_FORMAT,
  SET_SELECTED_TRACK_START_DATE,
} from "./types"

import {
  SelectedTrackOption,
  SelectedTrackFormat,
} from "@components/applicationForm/trackSelection/trackOptions"

export const setSelectedTrack = (track?: SelectedTrackOption) => ({
  type: SET_SELECTED_TRACK,
  payload: track,
})

export const setSelectedTrackFormat = (format?: SelectedTrackFormat) => ({
  type: SET_SELECTED_TRACK_FORMAT,
  payload: format,
})

export const setSelectedTrackStartDate = (date?: string) => ({
  type: SET_SELECTED_TRACK_START_DATE,
  payload: date,
})
