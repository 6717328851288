import React, { Component } from "react"

import withTitleCard from "@components/titleCard"

import TrackSelected from "./trackSelected"
import Tracks from "./tracks"
import {
  SelectedTrackOption,
  TrackOption,
} from "@components/applicationForm/trackSelection/trackOptions"
import {
  setSelectedTrack,
  setSelectedTrackFormat,
  setSelectedTrackStartDate,
} from "@src/actions"

interface TrackSelectionCardProps {
  className: string
  selectedTrack: SelectedTrackOption
  tracks: TrackOption[]
  setSelectedTrack: typeof setSelectedTrack
  setSelectedTrackFormat: typeof setSelectedTrackFormat
  setSelectedTrackStartDate: typeof setSelectedTrackStartDate
  trackStartDateRequired: boolean
}

const TrackSelectionCard = (props: TrackSelectionCardProps) => (
  <div className={`${props.className} track-selection-card`}>
    <Tracks
      className="track-selection-card__tracks"
      selectedTrack={props.selectedTrack}
      setSelectedTrack={props.setSelectedTrack}
      tracks={props.tracks}
    />
    <TrackSelected
      key={props.selectedTrack.title}
      className="track-selection-card__track-selected"
      selectedTrack={props.selectedTrack}
      setSelectedTrackFormat={props.setSelectedTrackFormat}
      setSelectedTrackStartDate={props.setSelectedTrackStartDate}
      trackStartDateRequired={props.trackStartDateRequired}
    />
  </div>
)

export default withTitleCard<TrackSelectionCardProps>("Track Selection")(
  TrackSelectionCard
)
