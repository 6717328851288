import React, { FunctionComponent } from "react"
import { FormPopup } from "@components/formFields"
import classNames from "classnames"
import { WrappedFieldProps } from "redux-form"
import { FormPopupProps } from "@components/formFields/formPopup/formPopup"
import "./formDrop.scss"

type DataValue = string | number

interface KeyDataValue {
  key: string
  value: DataValue
}

type FormDropData = KeyDataValue | DataValue

interface FormDropProps extends WrappedFieldProps {
  className: string
  title: string
  data: FormDropData[]
  textError: string
  disabled: boolean
  placeholder: string
  popup?: FormPopupProps
}

export function getKeyDataValue(v: FormDropData): KeyDataValue {
  if (typeof v === "number") {
    return { key: String(v), value: v }
  } else if (typeof v === "string") {
    return { key: v, value: v }
  } else {
    return v
  }
}

export const FormDrop: FunctionComponent<FormDropProps> = (props) => {
  const {
    className = "",
    input,
    title,
    data,
    textError,
    disabled,
    placeholder,
    popup,
    meta: { touched, error },
  } = props

  return (
    <div
      className={classNames(className, "form-drop", {
        "form-input-text-error": textError,
      })}
    >
      <div className="form-drop__title">{title}</div>
      {touched && error && (
        <span className="form-drop__error form-field-error">{error}</span>
      )}
      {popup && (
        <FormPopup
          width={popup.width}
          height={popup.height}
          trigger={
            <div className="form-drop__hover-icon">
              <i className="far fa-question-circle" />
            </div>
          }
          content={popup.content}
        />
      )}
      <select disabled={disabled} {...input}>
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {data.map((option) => {
          const { key, value } = getKeyDataValue(option)
          return (
            <option key={key} value={key}>
              {value}
            </option>
          )
        })}
      </select>
      <i className="drop-down-icon" />
    </div>
  )
}
