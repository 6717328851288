import { SET_PARTNER_INFO } from "@src/actions/types"

export default function (state = null, action) {
  switch (action.type) {
    case SET_PARTNER_INFO:
      return {
        ...action.payload,
      }
    default:
      return state
  }
}
