import React, { FunctionComponent } from "react"
import { WrappedFieldProps } from "redux-form"

import { FormPopup } from "@components/formFields"
import { FormPopupProps } from "@components/formFields/formPopup/formPopup"

import "./formSelectList.scss"

interface FormSelectListProps extends WrappedFieldProps {
  className: string
  data: any[]
  popup: FormPopupProps
  title: string
  disabled: boolean
}

export const FormSelectList: FunctionComponent<FormSelectListProps> = (
  props
) => {
  const {
    className = "",
    input,
    data,
    title,
    popup,
    meta: { touched, error },
  } = props
  return (
    <div className={`${className} form-select-list`}>
      {title && <div className="form-select-list__title">{title}</div>}
      {touched && error && (
        <span className="form-select-list__error form-field-error">
          {error}
        </span>
      )}
      {popup && (
        <FormPopup
          width={popup.width}
          height={popup.height}
          trigger={
            <div className="form-select-list__hover-icon">
              <i className="far fa-question-circle" />
            </div>
          }
          content={popup.content}
        />
      )}
      <div className="form-select-list__select-list">
        <ul>
          {data.map((label) => (
            <li key={label}>
              <label>
                <input
                  disabled={props.disabled}
                  {...input}
                  value={label}
                  type="radio"
                  checked={label == input.value}
                />
                <span>{label}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
