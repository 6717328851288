import React, { Component } from "react"

import { connect } from "react-redux"

import TitledContainer from "@components/TitledContainer"

import SocialMedia from "@components/review/socialMedia"
import EnrollmentComplete from "./enrollmentComplete"

import GreenTitledList from "@components/greenTitledList"

import CohortDetails from "./cohortDetails"
import OrientationReminder from "@components/partTwo/signAgreement/orientationReminder"
import withTopView from "@components/topView"

class WelcomeToBottega extends Component {
  render() {
    return (
      <div className="welcome-to-bottega layout__content">
        <TitledContainer
          title="Enrollment Complete"
          className="welcome-to-bottega__enrollment-complete"
        >
          <EnrollmentComplete />
        </TitledContainer>
        <TitledContainer
          title="Orientation Reminder"
          className="welcome-to-bottega__orientation-reminder"
        >
          <OrientationReminder
            isScheduled={this.props.scheduled}
            startTime={this.props.startTime}
          />
        </TitledContainer>
        <TitledContainer
          title="Be Ready To Start"
          className="welcome-to-bottega__be-ready"
        >
          <CohortDetails />
          <GreenTitledList title="Items To Prepare">
            <div>
              Keep working on your Coding Foundations course, You must complete
              at least 80% by your cohort start date. If you don't meet the 80%
              requirement you'll be moved to a later cohort start date.
            </div>
            <div>
              To access Coding Foundations, navigate to DevCamp and enter the
              login credentials you created previously.
            </div>
            <div>
              Be sure your hardware is up-to-par: Your computer can be windows
              or (preferably) mac, less than 3 years old, 2.7ghz/i5 - i7
              processor, has 8 GB of RAM and 500 GB SSHD - No Exceptions.
            </div>
          </GreenTitledList>
        </TitledContainer>
        <TitledContainer
          title="Follow Us On Social Media"
          className="welcome-to-bottega__social-media"
        >
          <SocialMedia />
        </TitledContainer>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const applicant = state.applicant.applicant
  const startTime = applicant.orientationMeetingAt

  return {
    ...applicant,
    startTime,
    scheduled: startTime !== null,
  }
}

const WelcomeToBottegaContainer = connect(mapStateToProps)(
  withTopView(WelcomeToBottega)
)

export default WelcomeToBottegaContainer
