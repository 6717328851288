import { Dispatch } from "redux"

import { Action } from "@src/reducers/pageLayoutReducer"
import { Page } from "@src/enrollmentSteps"
import { SET_PAGES, SET_PAGE, NEXT_PAGE, PREVIOUS_PAGE } from "./types"

export function setPageLayoutPages(pages: Page[]) {
  return {
    type: SET_PAGES,
    payload: pages,
  }
}

export function setPage(pageIndex: number) {
  return {
    type: SET_PAGE,
    payload: pageIndex,
  }
}

export function nextPage(dispatch?: Dispatch<Action>) {
  if (dispatch) {
    return dispatch({ type: NEXT_PAGE })
  } else {
    return { type: NEXT_PAGE }
  }
}

export function previousPage() {
  return { type: PREVIOUS_PAGE }
}
