import React from "react"
import {
  Field,
  InjectedFormProps,
  reduxForm,
  WrappedFieldProps,
} from "redux-form"

import withTitleCard from "@components/titleCard"
import { CommitmentSlider } from "./commitmentSlider"
import {
  FormButton,
  FormDrop,
  FormSelectList,
  FormTextArea,
} from "@components/formFields"
import {
  SelectedTrackOption,
  isFullTimeFormat,
} from "@components/applicationForm/trackSelection/trackOptions"
import { required } from "@src/validators"
import withTopView from "@components/topView"

export type Program = "Full-Time" | "Part-Time"

const boolToYesNo = (value?: boolean) => {
  return value === true ? "Yes" : value === false ? "No" : undefined
}
const yesNoToBool = (value?: "Yes" | "No") => {
  return value === "Yes" ? true : value === "No" ? false : undefined
}

type SliderFieldProps = {
  program: Program
  track: SelectedTrackOption
} & WrappedFieldProps

const renderSlider = (field: SliderFieldProps) => (
  <CommitmentSlider
    onChange={field.input.onChange}
    program={field.program}
    track={field.track}
    value={field.input.value}
  />
)

const Card = withTitleCard(
  "Commitment Assessment"
)((props: { children?: React.ReactNode }) => <>{props.children}</>)

const Heading: React.FC = (props) => (
  <h1 className="commitment-assessment-paragraph-heading">{props.children}</h1>
)

const Description: React.FC = (props) => (
  <p className="commitment-assessment-description">{props.children}</p>
)

const Paragraph: React.FC = (props) => (
  <p className="commitment-assessment-paragraph">{props.children}</p>
)

const Section: React.FC = (props) => (
  <div className="commitment-assessment-section">{props.children}</div>
)

export interface FormProps {
  attendanceAgreement: boolean
  hoursPerWeek: number
  graduateCommitment: boolean
  otherCommitments?: string
  weeklyStandingAppointment: boolean
  reasonForLearning: string
}

export interface Props {
  initialValues?: Partial<FormProps>
  onSubmit: (data: FormProps) => void
  track: SelectedTrackOption
}

const reasonForLearning = [
  "Not sure, I don’t have any plans",
  "Just learning for fun",
  "I want to build my own applications",
  "I want a career in software development",
  "Currently employed in tech, I’m just brushing up or want to learn new skills",
]

const FormatContext = React.createContext<Program>("Full-Time")

const FullTime: React.FC = (props) => (
  <FormatContext.Consumer>
    {(program) => program === "Full-Time" && props.children}
  </FormatContext.Consumer>
)

const PartTime: React.FC = (props) => (
  <FormatContext.Consumer>
    {(program) => program === "Part-Time" && props.children}
  </FormatContext.Consumer>
)

class CommitmentAssessmentForm extends React.Component<
  Props & InjectedFormProps<FormProps, Props>
> {
  render() {
    const { handleSubmit, track } = this.props
    const program: Program = isFullTimeFormat(track.selectedFormat)
      ? "Full-Time"
      : "Part-Time"

    return (
      <FormatContext.Provider value={program}>
        <form
          onSubmit={handleSubmit(this.props.onSubmit)}
          className="commitment-assessment-form"
        >
          <Card>
            <Section>
              <Description>
                To help us gauge your candidacy as a Bottega student, and
                potential scholarship opportunities, please provide information
                about your schedule availability and commitment to staying
                focused, learning and graduating on-time from our programs.
              </Description>
              <hr />
            </Section>
            <Section>
              <Heading>1.</Heading>
              <FullTime>
                <Paragraph>
                  Bottega’s full-time program runs weekdays from 9am to 5pm MST
                  for 12 weeks depending on holiday schedules. You will need to
                  participate fully during our LIVE class hours.
                </Paragraph>
                <Paragraph>
                  Will you be able to attend for 95%+ of our LIVE class
                  sessions, in their entirety?
                </Paragraph>
                <Paragraph>
                  Please note that in addition to the regular full-time program
                  hours, our students generally spend 60 to 90 minutes on
                  additional review and study.
                </Paragraph>
              </FullTime>
              <PartTime>
                <Paragraph>
                  Bottega’s part-time program is an independent study format
                  with unlimited mentor support from 8am-10pm MST M-Th and
                  9am-10pm Sat-Sun. In addition to your own independent study of
                  the devCamp curriculum, you will need to participate fully
                  during your LIVE mentor sessions each week.
                </Paragraph>
                <Paragraph>
                  Will you be able to attend for 95%+ of your LIVE mentor
                  sessions, in their entirety?
                </Paragraph>
              </PartTime>
              <Field
                name="attendanceAgreement"
                component={FormSelectList}
                data={["Yes", "No"]}
                validate={[required]}
                format={boolToYesNo}
                parse={yesNoToBool}
              />
            </Section>
            <Section>
              <Heading>2.</Heading>
              <Paragraph>
                Think about your current schedule. Using the slider below,
                select how many hours per week you can reasonably dedicate, if
                admitted, in order to graduate from the program on-time.{" "}
              </Paragraph>
              <Field
                component={renderSlider}
                name="hoursPerWeek"
                program={program}
                track={track}
                validate={[required]}
              />
            </Section>
            <Section>
              <Heading>3.</Heading>
              <FullTime>
                <Paragraph>
                  At the minimum of 15-20 hrs/week rate, the introductory course
                  (Coding Foundations) takes 3-4 weeks to complete, will you be
                  able to complete this course in its entirety prior to your
                  cohort start date for the Full-Time Program?
                </Paragraph>
              </FullTime>
              <PartTime>
                <Paragraph>
                  At the minimum of 15-20 hrs/week rate, the introductory course
                  (Coding Foundations) takes 3-4 weeks to complete, and your
                  track takes 6-8 months to complete, will you be able to
                  graduate from the program within 9 months?
                </Paragraph>
              </PartTime>
              <Field
                name="graduateCommitment"
                component={FormSelectList}
                data={["Yes", "No"]}
                validate={[required]}
                format={boolToYesNo}
                parse={yesNoToBool}
              />
            </Section>
            <Section>
              <Heading>4.</Heading>
              <FullTime>
                <Paragraph>
                  Do you have any other commitments that will conflict with the
                  minimum required time of 40-45 hrs/week within the Full-Time
                  Program? If yes, explain in detail. Note: Almost any time
                  conflict will disqualify you from joining Bottega.
                </Paragraph>
              </FullTime>
              <PartTime>
                <Paragraph>
                  Do you have any other commitments that will conflict with the
                  minimum required time of 15-20 hrs/week within the Part-Time
                  Program? If yes, explain in detail. Note: Almost any time
                  conflict will disqualify you from joining Bottega.
                </Paragraph>
              </PartTime>
              <Field
                name="otherCommitments"
                component={FormTextArea}
                placeholder="Enter Your Response Here"
              />
            </Section>
            <Section>
              <Heading>5.</Heading>
              <Paragraph>
                Will you commit to adhere to a weekly schedule in order to stay
                on track?
              </Paragraph>
              <Field
                name="weeklyStandingAppointment"
                component={FormSelectList}
                data={["Yes", "No"]}
                validate={[required]}
                format={boolToYesNo}
                parse={yesNoToBool}
              />
            </Section>
            <Section>
              <Heading>6.</Heading>
              <Paragraph>
                Why do you want to learn to code? What are your long-term goals
                for the skillset?
              </Paragraph>
              <Field
                name="reasonForLearning"
                component={FormDrop}
                data={reasonForLearning}
                placeholder="Select a goal"
                validate={[required]}
              />
            </Section>
          </Card>
          <Field
            className="commitment-assessment-continue"
            name="continue"
            type="submit"
            title="Continue"
            component={FormButton}
          />
        </form>
      </FormatContext.Provider>
    )
  }
}

const WrappedForm = reduxForm<FormProps, Props>({
  form: "CommitmentAssessmentForm",
  initialValues: {
    hoursPerWeek: 40,
  },
})(withTopView(CommitmentAssessmentForm))
;(WrappedForm as any).displayName = `reduxForm(${CommitmentAssessmentForm.name})`

export { WrappedForm as CommitmentAssessmentForm }
