import React, { Component } from "react"
import { scrollElementTo } from "@src/helpers"

const withTopView = function <T>(WrappedComponent: React.ComponentType<T>) {
  return class extends Component<T> {
    componentDidMount() {
      scrollElementTo(window, 0, 0)
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

export default withTopView
