import {
  DECREMENT_REQUESTS_COUNTER,
  INCREMENT_REQUESTS_COUNTER,
} from "@src/actions/types"

export interface Action {
  type: "INCREMENT_REQUESTS_COUNTER" | "DECREMENT_REQUESTS_COUNTER"
}

export interface RequestsLoaderState {
  counter: number
}

const INITIAL_STATE: RequestsLoaderState = {
  counter: 0,
}

export default function (state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case DECREMENT_REQUESTS_COUNTER: {
      return {
        counter: Math.max(0, state.counter - 1),
      }
    }
    case INCREMENT_REQUESTS_COUNTER: {
      return {
        counter: state.counter + 1,
      }
    }
    default:
      return state
  }
}
