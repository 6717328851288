import { Field } from "redux-form"
import {
  FormCheck,
  FormDrop,
  FormInput,
  FormReadonly,
} from "@components/formFields"
import React from "react"

export const FirstNameField = () => (
  <Field
    className="applicant-information-fields-form__first"
    name="firstName"
    title="First Name"
    component={FormReadonly}
  />
)

export const LastNameField = () => (
  <Field
    className="applicant-information-fields-form__last"
    name="lastName"
    title="Last Name"
    component={FormReadonly}
  />
)

export const PhoneNumberField = () => (
  <Field
    className="applicant-information-fields-form__phone"
    name="phoneNumber"
    type="phone"
    title="Phone Number"
    component={FormInput}
  />
)

export const PhoneNumberTypeField = () => (
  <Field
    className="applicant-information-fields-form__phone-number-type"
    component={FormDrop}
    data={["cell", "landline"]}
    name="phoneNumberType"
    placeholder="select one"
    title="Kind"
  />
)

export const CitizenshipField = ({ disabled }) => (
  <Field
    className="applicant-information-fields-form__citizenship"
    component={FormInput}
    disabled={disabled}
    name="citizenship"
    title="Citizenship"
    type="text"
  />
)

export const UsCitizenField = ({ onChange }) => (
  <Field
    className="applicant-information-fields-form__us-citizen"
    component={FormCheck}
    name="usCitizen"
    onChange={onChange}
    title="U.S. Citizen"
  />
)

export const EmergencyContactField = () => (
  <Field
    className="applicant-information-fields-form__emergency-contact"
    name="emergencyContact"
    type="text"
    title="Emergency Contact"
    component={FormInput}
  />
)

const StreetAddressField = ({ name, title }) => (
  <Field
    className="applicant-information-fields-form__address"
    name={name}
    type="address"
    title={title}
    component={FormInput}
  />
)

const CityField = ({ name }) => (
  <Field
    className="applicant-information-fields-form__city"
    name={name}
    type="city"
    title="City"
    component={FormInput}
  />
)

const CountryField = ({ name }) => (
  <Field
    className="applicant-information-fields-form__state"
    component={FormDrop}
    data={["mexico"]}
    name={name}
    placeholder="select one"
    title="Country"
  />
)

const StateField = ({ name }) => (
  <Field
    className="applicant-information-fields-form__state"
    component={FormDrop}
    data={[
      "Nevada",
      "Arizona",
      "New Mexico",
      "Utah",
      "Idaho",
      "Alabama",
      "Alaska",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "New Hampshire",
      "New Jersey",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Puerto Rico",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennesse",
      "Texas",
      "Vermont",
      "Virgin Islands",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ]}
    name={name}
    placeholder="select one"
    title="State"
  />
)

const ZipCodeField = ({ name }) => (
  <Field
    className="applicant-information-fields-form__zipcode"
    name={name}
    type="zipcode"
    title="Zipcode"
    component={FormInput}
  />
)

export const Address = ({ international }) => (
  <>
    <StreetAddressField name="streetAddress" title="Street Address" />
    <CityField name="city" />

    {international === true ? (
      <CountryField name="country" />
    ) : (
      <StateField name="state" />
    )}

    <ZipCodeField name="zipcode" />
  </>
)

export const MailingAddress = ({ international }) => (
  <>
    <StreetAddressField
      name="mailingStreetAddress"
      title="Mailing Address (if different)"
    />
    <CityField name="mailingCity" />

    {international === true ? (
      <CountryField name="mailingCountry" />
    ) : (
      <StateField name="mailingState" />
    )}

    <ZipCodeField name="mailingZipcode" />
  </>
)

export const DateOfBirthMonthField = ({ months }) => (
  <Field
    className="applicant-information-fields-form__month"
    component={FormDrop}
    data={months}
    name="month"
    placeholder="select one"
    title="Date of Birth"
  />
)

export const DateOfBirthDayField = ({ days }) => (
  <Field
    className="applicant-information-fields-form__day"
    component={FormDrop}
    data={days}
    name="day"
    placeholder="select one"
    title=""
  />
)

export const DateOfBirthYearField = ({ years }) => (
  <Field
    className="applicant-information-fields-form__year"
    component={FormDrop}
    data={years}
    name="year"
    placeholder="select one"
    title=""
  />
)

export const GenderField = () => (
  <Field
    className="applicant-information-fields-form__gender"
    component={FormDrop}
    data={["Male", "Female", "Other", "Rather not say"]}
    name="gender"
    placeholder="select one"
    title="Gender"
  />
)

export const SiteField = ({ sites }) => (
  <Field
    className="applicant-information-fields-form__site"
    component={FormDrop}
    data={sites}
    name="site"
    placeholder="select one"
    title="Site"
  />
)

export const MilitaryField = () => (
  <Field
    className="applicant-information-fields-form__military"
    component={FormDrop}
    data={[
      "Active Duty",
      "Active Reservist",
      "Veteran",
      "Not Applicable",
      "Veteran Spouse/Dependent",
    ]}
    name="military"
    placeholder="select one"
    title="Military Service"
  />
)

export const HighestEducationLevelField = () => (
  <Field
    className="applicant-information-fields-form__education"
    component={FormDrop}
    data={[
      "No Formal Education",
      "Some High School",
      "High School Diploma/GED",
      "Some College",
      "Associates Degree",
      "Bachelors Degree",
      "Masters Degree",
      "PhD/Doctorate",
    ]}
    name="highestEducationLevel"
    placeholder="select one"
    title="Highest Education Level"
  />
)

export const PreviousCodingExperienceField = () => (
  <Field
    className="applicant-information-fields-form__previous"
    component={FormDrop}
    data={["No Prior Experience", "Some Experience", "Experienced Developer"]}
    name="previousCodingExperience"
    placeholder="select one"
    title="Previous Coding Experience"
  />
)

export const HighSchoolOfGraduationField = () => (
  <Field
    className="applicant-information-fields-form__high-school-of-graduation"
    name="highSchoolOfGraduationOrEquivalent"
    type="text"
    title="High School of Graduation or Equivalent"
    component={FormInput}
  />
)

export const CollegesAttendedField = () => (
  <Field
    className="applicant-information-fields-form__colleges-attended"
    name="collegesOrUniversitiesAttendedPreviously"
    type="text"
    title="Colleges or Universities Attended Previously"
    component={FormInput}
  />
)

export const DegreeEarnedField = () => (
  <Field
    className="applicant-information-fields-form__degree-earned"
    name="degreeEarned"
    type="text"
    title="Degree Earned"
    component={FormInput}
  />
)

export const HasDiplomaOrGED = () => (
  <Field
    className="applicant-information-fields-form__diploma-or-ged"
    component={FormDrop}
    data={["Yes", "No"]}
    name="diplomaOrGed"
    placeholder="select one"
    title="Have Diploma or GED"
  />
)

export const HasHighSchoolDiploma = () => (
  <Field
    className="applicant-information-fields-form__high-school-diploma"
    component={FormDrop}
    data={["Yes", "No"]}
    name="highSchoolDiploma"
    placeholder="select one"
    title="Have High School Diploma"
  />
)

export const HowManyTermsOfCollegeAttended = () => (
  <Field
    className="applicant-information-fields-form__colleges-terms-attended"
    name="howManyTermsOfCollegeAttended"
    type="number"
    title="How Many Terms of College Attended"
    component={FormInput}
  />
)

export const VAFileNumberAndChapter = () => (
  <Field
    className="applicant-information-fields-form__va-file-number-and-chapter"
    name="VAFileNumberAndChapter"
    type="text"
    title="VA File Number and Chapter"
    component={FormInput}
  />
)

export const SocialSecurityNumber = () => (
  <Field
    className="applicant-information-fields-form__social-security-number"
    name="socialSecurityNumber"
    type="text"
    title="Social Security Number"
    component={FormInput}
  />
)

export const ReferenceContact = ({ international, number }) => (
  <>
    <div className="applicant-information-fields-form__reference-title">
      Reference {number}
    </div>
    <Field
      className="applicant-information-fields-form__reference-name"
      name={`reference${number}_name`}
      type="text"
      title="Name"
      component={FormInput}
    />
    <Field
      className="applicant-information-fields-form__reference-phone"
      name={`reference${number}_phoneNumber`}
      type="phone"
      title="Phone Number"
      component={FormInput}
    />
    <StreetAddressField
      name={`reference${number}_streetAddress`}
      title="Street Address"
    />
    <CityField name={`reference${number}_city`} />

    {international === true ? (
      <CountryField name={`reference${number}_country`} />
    ) : (
      <StateField name={`reference${number}_state`} />
    )}

    <ZipCodeField name={`reference${number}_zipcode`} />
  </>
)
