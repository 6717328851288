import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { loadStripe, Stripe } from "@stripe/stripe-js"

import TitledContainer from "@components/TitledContainer"
import config from "@src/config/keys"
import { FormButton } from "@components/formFields"
import {
  SelectedCheckoutableTrackOption,
  SelectedTrackOption,
  isCheckoutableSelectedTrackOption,
} from "@components/applicationForm/trackSelection/trackOptions"
import { State } from "@src/reducers"
import { handleCheckout, nextPage } from "@src/actions"
import { popupError } from "@src/actions/popupManager"
import { requiredInformationSet, Applicant } from "@common/applicant"

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(config.stripePublishableKey)

const Payment: React.FunctionComponent<{
  applicant: Applicant
  handleCheckout: (
    stripePromise: Promise<Stripe | null>,
    applicant: Applicant,
    track: SelectedCheckoutableTrackOption
  ) => Promise<void>
  nextPage: typeof nextPage
  paymentComplete: boolean
  popupError: typeof popupError
  selectedTrack?: SelectedTrackOption
}> = (props) => {
  React.useEffect(() => {
    if (props.paymentComplete) {
      props.nextPage()
    }
  })

  if (
    requiredInformationSet(props.applicant) &&
    props.selectedTrack &&
    isCheckoutableSelectedTrackOption(props.selectedTrack)
  ) {
    const selectedTrack = props.selectedTrack

    return (
      <TitledContainer title="Payment" className="payment-container">
        <div className="payment-content">
          <img
            src={`/assets${selectedTrack.logoUrl}`}
            className="payment-content__track-logo"
          />
          <div className="payment-content__track-title">
            {selectedTrack.trackKey}
          </div>

          <div className="payment-content__track-format">
            {selectedTrack.selectedFormat.title}
          </div>

          <div className="payment-content__track-price">
            ${selectedTrack.selectedFormat.price.toLocaleString()}
          </div>

          <FormButton
            className="payment-content__checkout"
            title="Checkout"
            onClick={() =>
              props.handleCheckout(
                stripePromise,
                props.applicant,
                selectedTrack
              )
            }
          />
        </div>
      </TitledContainer>
    )
  } else {
    return (
      <div>
        <div className="error-reporter__error">
          Please{" "}
          <Link to="/" reloadDocument>
            fill in the missing information
          </Link>{" "}
          in previous steps.
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: State) => {
  const applicant = state.applicant.applicant as Applicant
  const stripePaymentIntentId =
    applicant.selectedPaymentOption.stripePaymentIntentId
  const selectedTrack = applicant.selectedTrack

  return {
    applicant,
    selectedTrack,
    paymentComplete: stripePaymentIntentId !== null,
  }
}

const PaymentContainer = connect(mapStateToProps, {
  handleCheckout,
  nextPage,
  popupError,
})(Payment)

export default PaymentContainer
