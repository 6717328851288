import React from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import { reduxForm, Field, InjectedFormProps } from "redux-form"
import { useNavigate, useSearchParams, Navigate } from "react-router-dom"

import InfoCard from "@components/infoCard"
import checkLoggedIn from "./checkLoggedIn"
import withTitleCard from "@components/titleCard"
import { FormButton, FormInput } from "@components/formFields"
import { FormPasswordInput } from "@components/applicantAuth/formPasswordInput"
import { State } from "@src/reducers"
import { applicantSignIn } from "@src/actions"
import { required } from "@src/validators"

export interface ApplicantResumeApplicationLoginFormValues {
  email: string
  password: string
}

interface FormProps {
  authenticated: boolean
  applicantSignIn: (
    values: ApplicantResumeApplicationLoginFormValues,
    partnerName: string | undefined,
    timezone: string,
    registrationSource?: string
  ) => void
  searchParams: URLSearchParams
}

type Props = FormProps &
  InjectedFormProps<ApplicantResumeApplicationLoginFormValues, FormProps>

const ApplicantResumeApplicationLoginForm: React.FunctionComponent<Props> = (
  props
) => {
  const navigate = useNavigate()
  const partnerName = props.searchParams.get("name") || undefined
  const registrationSource = props.searchParams.get("source") || undefined

  return (
    <form
      onSubmit={props.handleSubmit}
      className="applicant-resume-application-login-card"
    >
      <div className="applicant-resume-application-login">
        <InfoCard
          className="applicant-resume-application-login__info-card"
          title="Login To Resume"
        />
        <Field
          className="applicant-resume-application-login__email"
          component={FormInput}
          name="email"
          textError={true}
          title="Email"
          type="email"
          validate={[required]}
        />
        <Field
          className="applicant-resume-application-login__password"
          component={FormPasswordInput}
          name="password"
          textError={true}
          validate={[required]}
        />
        <Field
          className="applicant-resume-application-login__login"
          component={FormButton}
          name="create-account"
          onClick={props.handleSubmit(
            (fields: ApplicantResumeApplicationLoginFormValues) => {
              props.applicantSignIn(
                fields,
                partnerName,
                Intl.DateTimeFormat().resolvedOptions().timeZone,
                registrationSource
              )
            }
          )}
          title="Resume"
        />
        <div className="applicant-resume-application-login__info-text">
          Need to start a new application?
        </div>
        <div className="applicant-resume-application-login__create-account-button">
          <button
            className="form-button__button applicant-resume-application-login__button"
            onClick={() =>
              navigate(`/applicant/signup?${props.searchParams.toString()}`)
            }
          >
            Create An Account Here
          </button>
        </div>
      </div>
    </form>
  )
}

const FormComponent = compose(
  reduxForm({
    form: "ApplicantResumeApplicationLoginForm",
    enableReinitialize: true,
  }),
  withTitleCard("Resume Your Application")
)(ApplicantResumeApplicationLoginForm) as React.FunctionComponent<Props>

const mapStateToProps = (state: State) => ({
  authenticated: state.applicant.authenticated,
})

const FormContainer: React.FunctionComponent<Props> = (props) => {
  const [searchParams] = useSearchParams()
  const applicantEmail = searchParams.get("applicant-email") || undefined

  if (props.authenticated) {
    return (
      <Navigate to={applicantEmail == undefined ? "/" : "/part-two"} replace />
    )
  } else {
    const initialValues = {
      email: applicantEmail,
    }

    return (
      <FormComponent
        {...props}
        initialValues={initialValues}
        searchParams={searchParams}
      />
    )
  }
}

export default compose(
  connect(mapStateToProps, { applicantSignIn }),
  checkLoggedIn
)(FormContainer) as React.FunctionComponent<FormProps>
