import React, { Component } from "react"
import moment from "moment"
import { connect } from "react-redux"

import {
  saveApplicantInfo,
  saveSelectedTrack,
  setSelectedTrack,
  setSelectedTrackFormat,
  setSelectedTrackStartDate,
} from "@src/actions"
import { toYesNo, trackToSite } from "@src/helpers"

import ApplicantInformationFieldsForm from "./applicantInformation/applicantInformationForm"
import CourseSelection from "./courseSelection"
import SelectProgram from "./selectAProgram"
import TrackSelectionForm from "./trackSelection/trackSelectionForm"
import {
  SelectedTrackOption,
  TrackFormatDatesPerSlug,
  tracksForPartner,
  isCheckoutableTrackFormat,
  SelectedNonCheckoutableTrackOption,
  SelectedCheckoutableTrackOption,
} from "./trackSelection/trackOptions"
import AccountCreated from "./accountCreated"
import { Applicant } from "@common/applicant"
import { PartnerSite } from "@common/partnerSite"
import { State } from "@src/reducers"
import { popupError } from "@src/actions/popupManager"

interface ApplicantInformationProps {
  accountCreatedInfoSeen: boolean
  defaultTrackSelection: SelectedTrackOption
  email: string
  formatDatesPerSlug: TrackFormatDatesPerSlug
  partnerName: string
  selectedTrack?: SelectedTrackOption
  sites: PartnerSite[]
  type: "b2b" | "b2c"

  popupError: typeof popupError
  saveApplicantInfo: (info: any, site?: PartnerSite) => void
  saveSelectedTrack: (track: SelectedTrackOption) => void
  setSelectedTrack: typeof setSelectedTrack
  setSelectedTrackFormat: typeof setSelectedTrackFormat
  setSelectedTrackStartDate: typeof setSelectedTrackStartDate
}

interface ApplicantInformationState {
  initialSelection?: SelectedTrackOption
  selectingTrack: boolean
}

const defaultTrack = (
  partnerName: string,
  formatDatesPerSlug: TrackFormatDatesPerSlug
): SelectedTrackOption => {
  const track = tracksForPartner(partnerName, formatDatesPerSlug)[0]
  const selectedFormat = track.formats[0]

  if (isCheckoutableTrackFormat(selectedFormat)) {
    return {
      ...track,
      selectedFormat,
    } as SelectedCheckoutableTrackOption
  } else {
    return {
      ...track,
      selectedFormat,
    } as SelectedNonCheckoutableTrackOption
  }
}

class ApplicantInformation extends Component<
  ApplicantInformationProps,
  ApplicantInformationState
> {
  constructor(props: ApplicantInformationProps) {
    super(props)

    this.state = { selectingTrack: false }
  }

  componentDidMount() {
    if (this.props.type === "b2b") {
      const track = defaultTrack(
        this.props.partnerName,
        this.props.formatDatesPerSlug
      )

      this.props.saveSelectedTrack(track)
    }
  }

  selectTrack = () => {
    this.setState({
      initialSelection: this.props.selectedTrack,
      selectingTrack: true,
    })

    // Make sure that there is a `selectedTrack` with `selectedFormat` in the
    // state so an applicant can click directly on the start date when selecting
    // a track.
    this.props.setSelectedTrack(this.props.defaultTrackSelection)
  }

  saveTrackSelection = (track: SelectedTrackOption) => {
    this.props.saveSelectedTrack(track)

    this.setState({
      initialSelection: track,
      selectingTrack: false,
    })
  }

  cancelTrackSelection = () => {
    this.props.setSelectedTrack(this.state.initialSelection)
    this.setState({ selectingTrack: false })
  }

  renderSelection = () => {
    if (this.state.selectingTrack) {
      const tracks = tracksForPartner(
        this.props.partnerName,
        this.props.formatDatesPerSlug
      )

      return (
        <TrackSelectionForm
          cancel={this.cancelTrackSelection}
          className="applicant-information__track"
          save={this.saveTrackSelection}
          selectedTrack={this.props.defaultTrackSelection}
          setSelectedTrack={this.props.setSelectedTrack}
          setSelectedTrackFormat={this.props.setSelectedTrackFormat}
          setSelectedTrackStartDate={this.props.setSelectedTrackStartDate}
          tracks={tracks}
        />
      )
    } else if (this.props.selectedTrack) {
      // @ts-ignore
      return <CourseSelection onChange={this.selectTrack} />
    } else {
      return <SelectProgram onChange={this.selectTrack} />
    }
  }

  fieldsToSite = (fields: any): PartnerSite | undefined => {
    if (this.props.sites.length === 1) {
      // SiteField is hidden when there's only a single option anyway.
      return this.props.sites[0]
    } else if (this.props.type === "b2b") {
      return this.props.sites.find((s) => s.name === fields.site)
    } else if (this.props.selectedTrack) {
      const pace = this.props.selectedTrack.selectedFormat.pace
      const siteName = trackToSite(this.props.partnerName, pace)
      return this.props.sites.find((s) => s.name === siteName)
    } else {
      return undefined
    }
  }

  onSubmit = (fields: any) => {
    const dateOfBirth = moment()
      .year(fields.year)
      .month(fields.month)
      .date(fields.day)
    const site = this.fieldsToSite(fields)
    const info = {
      ...fields,
      dateOfBirth,
      email: this.props.email,
      usCitizen: toYesNo(fields.usCitizen),
    }

    if (this.props.type !== "b2b" && !this.props.selectedTrack) {
      this.props.popupError(
        "Before continuing, make sure you select a track at the top of this page."
      )
    } else {
      this.props.saveApplicantInfo(info, site)
    }
  }

  renderForm = () => {
    return <ApplicantInformationFieldsForm onSubmit={this.onSubmit} />
  }

  renderAccountCreated = () => {
    return <AccountCreated />
  }

  render() {
    if (!this.props.selectedTrack && !this.props.accountCreatedInfoSeen) {
      return (
        <div className="applicant-information">
          {this.renderAccountCreated()}
        </div>
      )
    } else if (this.props.type == "b2b") {
      return <div className="applicant-information">{this.renderForm()}</div>
    } else {
      return (
        <div className="applicant-information">
          {this.renderSelection()}
          {!this.state.selectingTrack && this.renderForm()}
        </div>
      )
    }
  }
}

function mapStateToProps(state: State) {
  const formatDatesPerSlug = state.applicant.formatDates
  const { name: partnerName, type, sites } = state.partner
  const { accountCreatedInfoSeen, email, selectedTrack } = state.applicant
    .applicant as Applicant
  const defaultTrackSelection =
    selectedTrack || defaultTrack(partnerName, formatDatesPerSlug)

  return {
    accountCreatedInfoSeen,
    defaultTrackSelection,
    email,
    formatDatesPerSlug,
    partnerName,
    selectedTrack,
    sites,
    type,
  }
}

const ApplicantInformationContainer = connect(mapStateToProps, {
  popupError,
  saveApplicantInfo,
  saveSelectedTrack,
  setSelectedTrack,
  setSelectedTrackFormat,
  setSelectedTrackStartDate,
})(ApplicantInformation)

export default ApplicantInformationContainer
