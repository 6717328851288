import React, { Component } from "react"

class TitledContainer extends Component {
  render() {
    const { className = "", children, title } = this.props
    return (
      <div className={`${className} titled-container`}>
        <div className="titled-container__title">{title}</div>
        <div className="titled-container__content">{children}</div>
      </div>
    )
  }
}

export default TitledContainer
