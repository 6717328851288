import React, { Component } from "react"

import appsignal from "@src/appsignal"

interface Props {
  tracker: typeof appsignal
}

interface State {
  error?: Error
  hasError: boolean
}

export default class ErrorReporter extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, _info: any) {
    try {
      this.props.tracker.sendError(error)
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="accaunt-layout">
          <div className="account-layout__header branded-header">
            <img
              className="branded-header__logo"
              src="/assets/bottega-white-logo.png"
            />
          </div>
          <div className="account-layout__content title-card">
            <div className="title-card__content">
              <div className="error-reporter__error">
                Unexpected problem occurred in the application
              </div>
              <div className="error-reporter__info">
                Please refresh the page
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return this.props.children
    }
  }
}
