import React from "react"
import Recaptcha from "react-google-recaptcha"
import { compose } from "redux"
import { connect } from "react-redux"
import { reduxForm, getFormValues, Field, InjectedFormProps } from "redux-form"
import { useNavigate, useSearchParams, Navigate } from "react-router-dom"

import InfoCard from "@components/infoCard"
import checkLoggedIn from "./checkLoggedIn"
import config from "@src/config/keys"
import withTitleCard from "@components/titleCard"
import { FormInput, FormButton } from "@components/formFields"
import { State } from "@src/reducers"
import { applicantSignUp } from "@src/actions"

export interface ApplicantCreateAccountFormValues {
  firstName: string
  lastName: string
  email: string
  password: string
  phoneNumber: string
}

type ApplicantCreateAccountFormErrors = {
  [key in keyof ApplicantCreateAccountFormValues]?: string
}

const validate = (values: ApplicantCreateAccountFormValues) => {
  const errors: ApplicantCreateAccountFormErrors = {}
  if (!values.firstName) {
    errors.firstName = "Required"
  }
  if (!values.lastName) {
    errors.lastName = "Required"
  }
  if (!values.email) {
    errors.email = "Required"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address"
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "Required"
  }
  if (!values.password) {
    errors.password = "Required"
  } else if (values.password.length < 8) {
    errors.password = "Minimum length: 8" // devCamp requirement
  }
  return errors
}

interface FormProps {
  applicantSignUp: typeof applicantSignUp
  authenticated: boolean
  formValues: ApplicantCreateAccountFormValues
  recaptchaRef: Recaptcha
  searchParams: URLSearchParams
}

type Props = FormProps &
  InjectedFormProps<ApplicantCreateAccountFormValues, FormProps>

const ApplicantCreateAccountForm: React.FunctionComponent<Props> = (props) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const recaptchaRef = React.createRef<Recaptcha>()
  const onSubmit = () => recaptchaRef.current!.execute()

  const partnerName = searchParams.get("name") || "bottega"
  const registrationSource = searchParams.get("source") || undefined

  return (
    <form
      onSubmit={props.handleSubmit(onSubmit)}
      className="applicant-create-account-card"
    >
      <div className="applicant-create-account">
        <InfoCard
          className="applicant-create-account__info-card"
          title="Why Create An Account To Apply?"
          content="Creating an account keeps your personal information secure and saves your progress, allowing you to return, complete, and review your application."
        />
        <Field
          className="applicant-create-account__first-name"
          textError={true}
          name="firstName"
          title="First name"
          component={FormInput}
        />
        <Field
          className="applicant-create-account__last-name"
          textError={true}
          name="lastName"
          title="Last name"
          component={FormInput}
        />
        <Field
          className="applicant-create-account__email"
          textError={true}
          name="email"
          title="Email"
          type="email"
          component={FormInput}
        />
        <Field
          className="applicant-create-account__phone-number"
          textError={true}
          name="phoneNumber"
          title="Phone Number"
          component={FormInput}
        />
        <Field
          className="applicant-create-account__password"
          textError={true}
          type="password"
          name="password"
          title="Password"
          component={FormInput}
        />
        <div className="applicant-create-account__privacy-text">
          <div>By clicking "Create Account" you agree to the</div>
          <span>Bottega </span>
          <a
            className="applicant-create-account__link"
            onClick={() => navigate("/terms-of-use")}
          >
            Terms of Use
          </a>
          {" and "}
          <a
            target="_blank"
            className="applicant-create-account__link"
            href="https://bottega.tech/privacy-policy"
          >
            Privacy Policy
          </a>
          .
        </div>
        <Recaptcha
          ref={recaptchaRef}
          size="invisible"
          sitekey={config.recaptchaKey}
          badge="bottomleft"
          onChange={(recaptchaToken) => {
            props.applicantSignUp(
              props.formValues,
              partnerName,
              registrationSource,
              Intl.DateTimeFormat().resolvedOptions().timeZone,
              recaptchaToken!,
              searchParams
            )

            recaptchaRef.current!.reset()
          }}
        />
        <Field
          className="applicant-create-account__login"
          name="create-account"
          title="Create Account"
          type="submit"
          component={FormButton}
        />
        <div className="applicant-create-account__info-text info-text">
          <div>Have you started an application previously?</div>
          <a
            className="applicant-create-account__link"
            onClick={() =>
              navigate(`/applicant/signin?${searchParams.toString()}`)
            }
          >
            Resume Application Here
          </a>
        </div>
      </div>
    </form>
  )
}

const FormComponent = compose(
  reduxForm({
    form: "ApplicantCreateAccountForm",
    validate,
  }),
  withTitleCard("Create An Account")
)(ApplicantCreateAccountForm) as React.FunctionComponent<Props>

const mapStateToProps = (state: State) => ({
  authenticated: state.applicant.authenticated,
  formValues: getFormValues("ApplicantCreateAccountForm")(state),
})

const FormContainer: React.FunctionComponent<Props> = (props) =>
  props.authenticated ? (
    <Navigate to="/" replace />
  ) : (
    <FormComponent {...props} />
  )

export default compose(
  connect(mapStateToProps, { applicantSignUp }),
  checkLoggedIn
)(FormContainer) as React.FunctionComponent<FormProps>
