import { DispatchProp } from "react-redux"

import {
  AxiosError,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios"

import {
  incrementRequestsCounter,
  decrementRequestsCounter,
} from "./actions/requestsLoader"

import appsignal from "./appsignal"

export function requestInterceptor(dispatch: DispatchProp["dispatch"]) {
  return function (config: AxiosRequestConfig) {
    incrementRequestsCounter(dispatch)

    return config
  }
}

export function responseInterceptor(dispatch: DispatchProp["dispatch"]) {
  return function <T>(response: AxiosResponse<T>) {
    decrementRequestsCounter(dispatch)

    return response
  }
}

export function errorInterceptor(dispatch: DispatchProp["dispatch"]) {
  return function (error: AxiosError): AxiosPromise<AxiosError> {
    decrementRequestsCounter(dispatch)

    if (
      error.response === undefined ||
      (error.response.status != 401 && error.response.status != 422)
    ) {
      appsignal.sendError(error)
    }

    throw error
  }
}
