import moment from "moment"
import { AxiosInstance } from "axios"
import { values, groupBy } from "lodash"
import { DateFormat } from "@src/helpers"

// There are two kinds of tracks: the normal ones which are payable after
// picking the financing category and signing the agreement and the special
// that are payable via Stripe before signing the agreement (mobile tracks).

export enum TrackKey {
  dataScience = "Machine Learning and Data Science",
  frontend = "Front-End Development",
  python = "Full-Stack Python & React.js Development",
}

export enum CheckoutableTrackKey {
  mobile = "Mobile Development with React Native",
}

export interface TrackFormatDate {
  startDateString: string
  endDateString?: string
  trackSlug: string
}

export interface TrackFormatDatesPerSlug {
  [key: string]: TrackFormatDate[]
}

interface TrackFormatBase {
  dates: TrackFormatDate[]
  description: string
  lengthInMonths: number
  lengthInWeeks: number
  pace: Pace
}

interface NonCheckoutableTrackFormat extends TrackFormatBase {
  title: FormatTitle
}

interface CheckoutableTrackFormat extends TrackFormatBase {
  preRequisites: string
  price: number
  title: CheckoutableFormatTitle
}

export type TrackFormat = NonCheckoutableTrackFormat | CheckoutableTrackFormat

interface SelectedNonCheckoutableTrackFormat
  extends NonCheckoutableTrackFormat {
  selectedStartDate?: string
  selectedFormatCredits?: string
}

interface SelectedCheckoutableTrackFormat extends CheckoutableTrackFormat {
  selectedStartDate?: string
  selectedFormatCredits?: string
}

export type SelectedTrackFormat =
  | SelectedNonCheckoutableTrackFormat
  | SelectedCheckoutableTrackFormat

export const isCheckoutableTrackFormat = (
  format: SelectedTrackFormat
): format is SelectedCheckoutableTrackFormat => {
  return (format as SelectedCheckoutableTrackFormat).price !== undefined
}

interface TrackDetailsBase {
  academicContentCourseList: string[]
  certificateType: string
  certification: string
  description: string
  isAccredited: boolean
  logoUrl: string
  preRequisites: string
  recommendedCredits: number
  subtitle: string
  title: string
}

interface NonCheckoutableTrackDetails extends TrackDetailsBase {
  trackKey: TrackKey
}

interface CheckoutableTrackDetails extends TrackDetailsBase {
  trackKey: CheckoutableTrackKey
}

type TrackDetails = NonCheckoutableTrackDetails | CheckoutableTrackDetails

interface NonCheckoutableTrackOption extends NonCheckoutableTrackDetails {
  formats: TrackFormat[]
}

interface CheckoutableTrackOption extends CheckoutableTrackDetails {
  formats: TrackFormat[]
}

export type TrackOption = NonCheckoutableTrackOption | CheckoutableTrackOption

export const isCheckoutableTrackOption = (
  track: TrackOption
): track is CheckoutableTrackOption => {
  return track.trackKey == CheckoutableTrackKey.mobile
}

export interface SelectedNonCheckoutableTrackOption
  extends NonCheckoutableTrackOption {
  selectedFormat: SelectedNonCheckoutableTrackFormat
}

export interface SelectedCheckoutableTrackOption
  extends CheckoutableTrackOption {
  selectedFormat: SelectedCheckoutableTrackFormat
}

export type SelectedTrackOption =
  | SelectedNonCheckoutableTrackOption
  | SelectedCheckoutableTrackOption

export const isCheckoutableSelectedTrackOption = (
  track: SelectedTrackOption
): track is SelectedCheckoutableTrackOption => {
  return isCheckoutableTrackFormat(track.selectedFormat)
}

export enum Pace {
  onSite = "Full-Time On Campus",
  online = "Full-Time Online",
  partTime = "Part-Time Online",
}

export enum FormatTitle {
  onSite = "Full-Time On Campus",
  online = "Full-Time Online",
  partTime = "Part-Time Online",
}

export enum CheckoutableFormatTitle {
  online = "Full-Time Online",
  partTime = "Part-Time Online",
  partTimeForDevelopers = "For Developers - Part-Time Online",
  partTimeForReactDevelopers = "For React Developers - Part-Time Online",
}

const trackTypeSlug: { [key in TrackKey]: { [key in FormatTitle]: string } } = {
  [TrackKey.dataScience]: {
    [FormatTitle.onSite]: "machine-learning-data-science",
    [FormatTitle.online]: "machine-learning-data-science",
    [FormatTitle.partTime]: "machine-learning-data-science",
  },
  [TrackKey.frontend]: {
    [FormatTitle.onSite]: "front-end-development",
    [FormatTitle.online]: "front-end-development",
    [FormatTitle.partTime]: "front-end-development",
  },
  [TrackKey.python]: {
    [FormatTitle.onSite]: "full-stack-development-javascript-python",
    [FormatTitle.online]: "full-stack-development-javascript-python",
    [FormatTitle.partTime]: "pt-full-stack-development-javascript-python-react",
  },
}

const mobileTrackTypeSlug: {
  [key in CheckoutableTrackKey]: { [key in CheckoutableFormatTitle]: string }
} = {
  [CheckoutableTrackKey.mobile]: {
    [CheckoutableFormatTitle.online]:
      "mobile-development-with-react-native-from-scratch-ft",
    [CheckoutableFormatTitle.partTime]:
      "mobile-development-with-react-native-from-scratch-pt",
    [CheckoutableFormatTitle.partTimeForDevelopers]:
      "mobile-development-with-react-native-for-developers-pt",
    [CheckoutableFormatTitle.partTimeForReactDevelopers]:
      "mobile-development-with-react-native-for-react-developers-pt",
  },
}

export function trackToDevcampSyllabusSlug(track: SelectedTrackOption): string {
  if (track.trackKey == CheckoutableTrackKey.mobile) {
    return mobileTrackTypeSlug[track.trackKey][track.selectedFormat.title]
  } else {
    return trackTypeSlug[track.trackKey][track.selectedFormat.title]
  }
}

export function devcampSyllabusSlugToTrackKey(
  slug: string | null
): TrackKey | CheckoutableTrackKey | undefined {
  if (slug === null) {
    return undefined
  } else {
    return {
      "front-end-development": TrackKey.frontend,
      "full-stack-development-javascript-python": TrackKey.python,
      "machine-learning-data-science": TrackKey.dataScience,
      "mobile-development-with-react-native-for-developers-pt":
        CheckoutableTrackKey.mobile,
      "mobile-development-with-react-native-for-react-developers-pt":
        CheckoutableTrackKey.mobile,
      "mobile-development-with-react-native-from-scratch-ft":
        CheckoutableTrackKey.mobile,
      "mobile-development-with-react-native-from-scratch-pt":
        CheckoutableTrackKey.mobile,
      "pt-full-stack-development-javascript-python-react": TrackKey.python,
    }[slug]
  }
}

export const mobileSyllabusSlugToFormat: { [key in string]: string } = {
  "mobile-development-with-react-native-from-scratch-ft":
    CheckoutableFormatTitle.online,
  "mobile-development-with-react-native-from-scratch-pt":
    CheckoutableFormatTitle.partTime,
  "mobile-development-with-react-native-for-developers-pt":
    CheckoutableFormatTitle.partTimeForDevelopers,
  "mobile-development-with-react-native-for-react-developers-pt":
    CheckoutableFormatTitle.partTimeForReactDevelopers,
}

const defaultFormats: { [key in Pace]: NonCheckoutableTrackFormat } = {
  "Full-Time On Campus": {
    dates: [],
    description:
      "This format requires in-person attendance on the Lehi, Utah Campus. 9am-5pm MST, Mon-Fri.",
    lengthInMonths: 3,
    lengthInWeeks: 12,
    pace: Pace.onSite,
    title: FormatTitle.onSite,
  },

  "Full-Time Online": {
    dates: [],
    description:
      "This format requires virtual attendance via live stream to the instructor lead classes. 9am-5pm MST, Mon-Fri.",
    lengthInMonths: 3,
    lengthInWeeks: 12,
    pace: Pace.online,
    title: FormatTitle.online,
  },

  "Part-Time Online": {
    dates: [],
    description:
      "This format is self-paced and requires no classroom attendance. We recommend you dedicate 2-3 hours per day to learn the curriculum and graduate in 6-9 months.",
    lengthInMonths: 10,
    lengthInWeeks: 40,
    pace: Pace.partTime,
    title: FormatTitle.partTime,
  },
}

export const format = (
  format: FormatTitle,
  dates: TrackFormatDate[] = []
): TrackFormat => {
  return { ...defaultFormats[format], dates }
}

const mobileFormats: {
  [key in CheckoutableFormatTitle]: CheckoutableTrackFormat
} = {
  "Full-Time Online": {
    dates: [],
    description:
      "This format requires virtual attendance via live stream to the instructor lead classes. 9am-5pm MST, Mon-Fri.",
    lengthInMonths: 2,
    lengthInWeeks: 6,
    pace: Pace.online,
    preRequisites:
      "None. This course is perfect for people who are new to development and want to learn how to create immersive mobile apps with React Native.",
    price: 4500,
    title: CheckoutableFormatTitle.online,
  },

  "Part-Time Online": {
    dates: [],
    description: "",
    lengthInMonths: 4,
    lengthInWeeks: 15,
    pace: Pace.partTime,
    preRequisites:
      "None. This course is perfect for people who are new to development and want to learn how to create immersive mobile apps with React Native.",
    price: 3500,
    title: CheckoutableFormatTitle.partTime,
  },

  "For Developers - Part-Time Online": {
    dates: [],
    description: "",
    lengthInMonths: 3,
    lengthInWeeks: 11,
    pace: Pace.partTime,
    preRequisites:
      "This course is perfect for developers who want to learn React JS for web apps as well as React Native for creating immersive mobile apps with React Native.",
    price: 2500,
    title: CheckoutableFormatTitle.partTimeForDevelopers,
  },

  "For React Developers - Part-Time Online": {
    dates: [],
    description:
      "Those formats are self-paced and requires no classroom attendance. We recommend you dedicate 2-3 hours per day to learn the curriculum and graduate in 6-9 months.",
    lengthInMonths: 2,
    lengthInWeeks: 7,
    pace: Pace.partTime,
    preRequisites:
      "This course is designed for developers who already work in React JS for web apps, but who want to start creating immersive mobile apps with React Native.",
    price: 1500,
    title: CheckoutableFormatTitle.partTimeForReactDevelopers,
  },
}

export const mobileFormat = (
  format: CheckoutableFormatTitle,
  dates: TrackFormatDate[] = []
): TrackFormat => {
  return { ...mobileFormats[format], dates }
}

const tracks: { [key in TrackKey | CheckoutableTrackKey]: TrackDetails } = {
  "Machine Learning and Data Science": {
    academicContentCourseList: [],
    certificateType:
      "Certificate of Proficiency (Graded) or Certificate of Completion (Pass/Fail)",
    certification: "Machine Learning and Data Science",
    description:
      "Emphasis will be on hands-on development, going through exercises and workshops together.",
    isAccredited: false,
    logoUrl: "/DataScience.svg",
    preRequisites:
      "Working knowledge of Python programming, undergraduate mathematics, and CLI experience.",
    recommendedCredits: 15,
    subtitle: "",
    title: "Machine Learning and Data Science",
    trackKey: TrackKey.dataScience,
  },

  "Front-End Development": {
    academicContentCourseList: [],
    certificateType:
      "Certificate of Proficiency (Graded) or Certificate of Completion (Pass/Fail)",
    certification: "Front End Development",
    description:
      "This track empowers students to design and develop dynamic user interfaces utilizing modern, and in demand, coding technologies. Students learn essential design and development strategies,including common industry conventions, to create intuitive applications and experiences.",
    isAccredited: false,
    logoUrl: "/Frontend.svg",
    preRequisites: "None",
    recommendedCredits: 15,
    subtitle: "React.js & Vue.js",
    title: "Front-End Development",
    trackKey: TrackKey.frontend,
  },

  "Full-Stack Python & React.js Development": {
    academicContentCourseList: [
      "CS277 Intro to Programming in Python",
      "CS301 Front End Foundations - JS",
      "CS382 Modern Databases & Theory",
      "CS384 Advanced Programming in Python",
      "CS497 Advanced Web Development - React",
      "Career Placement, Interview Coaching, and Resume Writing",
    ],
    certificateType:
      "Certificate of Proficiency (Graded) or Certificate of Completion (Pass/Fail)",
    certification: "Full Stack Development Certificate",
    description:
      "This comprehensive full stack development track focuses on teaching Python and React; two of the industry’s leading languages for job demand, and projected growth. Students learn the fundamentals of computer programming, gain a greater understanding of object-oriented programming, and learn modern front-end and design principles.",
    isAccredited: true,
    logoUrl: "/Python.svg",
    preRequisites: "None",
    recommendedCredits: 15,
    subtitle: "Python & React.js",
    title: "Full-Stack Development",
    trackKey: TrackKey.python,
  },

  "Mobile Development with React Native": {
    academicContentCourseList: [],
    certificateType: "",
    certification: "Mobile Development Certificate",
    description:
      "You will learn to build a functional mobile app from scratch that runs on Android and IOS. A mobile app that is dynamic, full of modern features, and connected to an API provided by Bottega. You will learn to build for asynchronous behavior, authentication, build a full search engine, style and forms, dynamic use of images, API integration to generate records, set permissions, etc. and wrap up all of that in a way that users will be able to understand.",
    isAccredited: false,
    logoUrl: "/React.svg",
    preRequisites: "None",
    recommendedCredits: 0,
    subtitle: "React Native",
    title: "Mobile Development",
    trackKey: CheckoutableTrackKey.mobile,
  },
}

export const defaultTracks = values(tracks)

export async function getFormatDates(
  axios: AxiosInstance
): Promise<TrackFormatDatesPerSlug> {
  return axios.get("/program_timespans").then(({ data }) =>
    groupBy(
      data.program_timespans.map(
        (t: {
          end_date: string
          start_date: string
          syllabus_slug: string
        }) => ({
          endDateString: t.end_date,
          startDateString: t.start_date,
          trackSlug: t.syllabus_slug,
        })
      ),
      (t) => t.trackSlug
    )
  )
}

const defaultTrackOptions: TrackOption[] = [
  { ...tracks[TrackKey.dataScience], formats: [format(FormatTitle.partTime)] },
  { ...tracks[TrackKey.python], formats: [format(FormatTitle.partTime)] },
]

const acuTrackOptions = (
  formatDatesPerSlug: TrackFormatDatesPerSlug
): TrackOption[] => {
  return [
    {
      ...tracks[TrackKey.dataScience],
      formats: [format(FormatTitle.partTime)],
    },
    {
      ...tracks[TrackKey.python],
      formats: [
        format(
          FormatTitle.online,
          formatDatesPerSlug["full-stack-development-javascript-python"]
        ),
        format(FormatTitle.partTime),
      ],
    },
  ]
}

const bottegaTrackOptions = (
  formatDatesPerSlug: TrackFormatDatesPerSlug
): TrackOption[] => {
  return [
    {
      ...tracks[TrackKey.dataScience],
      formats: [format(FormatTitle.partTime)],
    },
    {
      ...tracks[TrackKey.frontend],
      formats: [format(FormatTitle.partTime)],
    },
    {
      ...tracks[TrackKey.python],
      formats: [
        format(
          FormatTitle.online,
          formatDatesPerSlug["full-stack-development-javascript-python"]
        ),
        format(FormatTitle.partTime),
      ],
    },
  ]
}

const mobileTrackOptions = (
  formatDatesPerSlug: TrackFormatDatesPerSlug
): TrackOption[] => {
  return [
    {
      ...tracks[CheckoutableTrackKey.mobile],
      formats: [
        mobileFormat(
          CheckoutableFormatTitle.online,
          formatDatesPerSlug[
            "mobile-development-with-react-native-from-scratch-ft"
          ]
        ),
        mobileFormat(CheckoutableFormatTitle.partTime),
        mobileFormat(CheckoutableFormatTitle.partTimeForDevelopers),
        mobileFormat(CheckoutableFormatTitle.partTimeForReactDevelopers),
      ],
    },
  ]
}

const mxTrackOptions: TrackOption[] = [
  { ...tracks[TrackKey.python], formats: [format(FormatTitle.partTime)] },
]

const inmobTrackOptions = (
  formatDatesPerSlug: TrackFormatDatesPerSlug
): TrackOption[] => {
  return [
    {
      ...tracks[TrackKey.dataScience],
      formats: [format(FormatTitle.partTime)],
    },
    {
      ...tracks[TrackKey.python],
      formats: [
        format(
          FormatTitle.partTime,
          formatDatesPerSlug["full-stack-development-javascript-python"]
        ),
      ],
    },
  ]
}

const calculateEndDate = ({ selectedFormat }: SelectedTrackOption) => {
  if (selectedFormat.selectedStartDate) {
    return moment(selectedFormat.selectedStartDate).add(
      selectedFormat.lengthInWeeks,
      "weeks"
    )
  } else if (selectedFormat.pace == Pace.partTime) {
    return moment().add(selectedFormat.lengthInMonths, "months")
  } else {
    return moment().add(selectedFormat.lengthInWeeks, "weeks")
  }
}

export const determineEndDate = (track: SelectedTrackOption) => {
  if (track.selectedFormat.selectedStartDate != null) {
    const endDate = track.selectedFormat.dates.find(
      (d) => d.startDateString == track.selectedFormat.selectedStartDate
    )

    if (endDate && endDate.endDateString) {
      return moment(endDate.endDateString).format(DateFormat)
    } else {
      return calculateEndDate(track).format(DateFormat)
    }
  } else if (track.selectedFormat.pace == Pace.partTime) {
    return calculateEndDate(track).format(DateFormat)
  }
}

export function tracksForPartner(
  partnerName: string,
  formatDatesPerSlug: TrackFormatDatesPerSlug
): TrackOption[] {
  switch (partnerName.toLowerCase()) {
    case "acu":
      return acuTrackOptions(formatDatesPerSlug)
    case "bottega":
      return bottegaTrackOptions(formatDatesPerSlug)
    case "inmob":
      return inmobTrackOptions(formatDatesPerSlug)
    case "mobile":
      return mobileTrackOptions(formatDatesPerSlug)
    case "mx":
      return mxTrackOptions
    case "rails":
      return bottegaTrackOptions(formatDatesPerSlug)
    case "uvu":
      return bottegaTrackOptions(formatDatesPerSlug)
    default:
      return defaultTrackOptions
  }
}

export const isFullTimeFormat = (f: TrackFormat): boolean =>
  f.title.includes("Full-Time")

export const isOnCampusPace = (p: Pace): boolean => p === "Full-Time On Campus"
