import React from "react"

function withTitleCard<T>(
  title: string
): (
  WrappedComponent: React.ComponentType<T>
) => React.ComponentClass<T & { className?: string }> {
  return function (
    WrappedComponent: React.ComponentType<T>
  ): React.ComponentClass<T & { className?: string }> {
    return class extends React.Component<T & { className?: string }> {
      render() {
        const { className = "" } = this.props
        return (
          <div className={`${className} title-card`}>
            <h1 className="title-card__title">{title}</h1>
            <div className="title-card__content">
              <WrappedComponent {...this.props} />
            </div>
          </div>
        )
      }
    }
  }
}

export default withTitleCard
