import React from "react"

import { Field, reduxForm, getFormValues } from "redux-form"

import {
  CosignerField,
  CurrentEmploymentStatusField,
  DefaultedLoanField,
  IncomeField,
  IncomeSharingAgreementField,
  IndustryEmploymentField,
} from "./financialGuidanceFields"
import { FormButton } from "@components/formFields"
import withTopView from "@src/components/topView"
import { connect } from "react-redux"

const FinancialGuidanceForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className="financial-guidance-form">
      <div className="financial-guidance-form__title">Tuition Assessment</div>
      <div className="financial-guidance-form__content">
        <div className="financial-guidance-form__content__message">
          Answering the following questions allows us to align you with the most
          relevant financial options we have to offer.
        </div>

        <DefaultedLoanField />
        <CosignerField />
        {props.hasAnotherIncomeSharingAgreementField && (
          <IncomeSharingAgreementField />
        )}
        <CurrentEmploymentStatusField />
        <IncomeField />
        <IndustryEmploymentField />
      </div>

      <div className="financial-guidance-form__buttons">
        <Field
          className="financial-guidance-form__back"
          name="back"
          type="button"
          title="Back"
          component={FormButton}
          onClick={props.previousPage}
        />
        <Field
          className="financial-guidance-form__continue"
          name="continue"
          type="submit"
          title="View Options"
          component={FormButton}
        />
      </div>
    </form>
  )
}

/**
 * @param {any} values
 * @param {any} form
 * @return {any}
 */
function validate(values, form) {
  let errors = {}

  if (form.hasAnotherIncomeSharingAgreementField) {
    if (!values.incomeSharingAgreement) {
      errors.incomeSharingAgreement = "*"
    }
  }

  if (!values.cosigner) {
    errors.cosigner = "*"
  }
  if (!values.defaulted) {
    errors.defaulted = "*"
  }
  if (!values.currentEmploymentStatus) {
    errors.currentEmploymentStatus = "*"
  }
  if (!values.industryEmployment) {
    errors.industryEmployment = "*"
  }
  if (!values.income) {
    errors.income = "*"
  }

  return errors
}

function mapStateToProps(state) {
  const initialValues = state.financialGuidance
  const hasAnotherIncomeSharingAgreementField =
    state.partner.flags.hasISAFinancingSurveyQuestion

  return {
    formValues: getFormValues("FinancialGuidanceForm")(state),
    hasAnotherIncomeSharingAgreementField,
    initialValues,
  }
}

const FinancialGuidanceFormContainer = connect(mapStateToProps)(
  reduxForm({
    form: "FinancialGuidanceForm",
    // @ts-ignore
    validate,
  })(withTopView(FinancialGuidanceForm))
)

export default FinancialGuidanceFormContainer
