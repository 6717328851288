import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { WrappedFieldProps } from "redux-form"
import "./formFileInput.scss"

interface FormFileInputProps extends WrappedFieldProps {
  className: string
  title: string
  textError: string
  disabled: boolean
}

export interface RemoteFile {
  url: string | null
}

export type InputFile = File | RemoteFile

export const isInputFilePresent = (value: InputFile) => {
  if ((value as RemoteFile).url) {
    return true
  } else if ((value as File).name) {
    return true
  } else {
    return false
  }
}

export const FormFileInput: FunctionComponent<FormFileInputProps> = (props) => {
  const {
    className,
    title,
    textError,
    disabled,
    meta: { touched, error },
    input,
  } = props

  const fileNameUrl = input.value && input.value.url
  const fileName = fileNameUrl && input.value.url.replace(/^.*[\\/]/, "")

  return (
    <div
      className={classNames(className, "form-file", {
        "form-input-text-error": textError,
      })}
    >
      <div className="form-file__title">
        {title}
        <br />
        {fileName && (
          <span>
            {"Previously selected "}
            <a href={fileNameUrl} target="_blank">
              <b>{fileName}</b>
            </a>
          </span>
        )}
      </div>
      {touched && error && (
        <span className="form-file__error form-field-error">{error}</span>
      )}

      <input
        type="file"
        onChange={(e) => {
          if (e.target.files) {
            props.input.onChange(e.target.files[0])
          }
        }}
        disabled={disabled}
      />
    </div>
  )
}
