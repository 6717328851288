import React, { FunctionComponent } from "react"
import { WrappedFieldProps } from "redux-form"
import "./formTextArea.scss"

interface FormTextAreaProps extends WrappedFieldProps {
  className: string
  placeholder: string
  title: string
  textError: string
}

export const FormTextArea: FunctionComponent<FormTextAreaProps> = (props) => {
  const {
    className = "",
    input,
    placeholder,
    title,
    textError,
    meta: { touched, error },
  } = props
  return (
    <div
      className={`${className} ${
        textError ? "form-input-text-error" : ""
      } form-textarea`}
    >
      <div className="form-textarea__title">{title}</div>
      {touched && error && (
        <span className="form-textarea__error form-field-error">{error}</span>
      )}
      <textarea
        className="form-textarea__textarea"
        {...input}
        placeholder={placeholder}
      />
    </div>
  )
}
