import React, { Component } from "react"

import { connect } from "react-redux"
import * as actions from "@src/actions"

import TitledContainer from "@components/TitledContainer"
import ProcessComplete from "./reviewProcessComplete"
import GreenTitledList from "@components/greenTitledList"
import SocialMedia from "./socialMedia"

import NumberedList from "@components/numberedList"
import withTopView from "@components/topView"

class PartnerReview extends Component {
  render() {
    const reviewListItems = [
      {
        title: "Start the course",
        description:
          "Go ahead and get started on the coding foundations course on devCamp. This course is designed to introduce fundamental coding principles and techniques. Completing this will allow you to go on to more advanced courses!",
        contentComponent: (
          <GreenTitledList title="Next Steps">
            <li>
              {"Navigate to "}
              <a href="https://devcamp.com/users/sign_in" target="_blank">
                <b>devCamp</b>
              </a>{" "}
              and enter your login credentials.
            </li>
            <li>
              Select "Coding Foundations" under the “Classes Attended” section
              on your devCamp dashboard and start learning!
            </li>
          </GreenTitledList>
        ),
      },
    ]
    return (
      <div className="financial-guidance layout__content">
        <TitledContainer
          title="Application Processing"
          className="financial-guidance__process-complete"
        >
          <ProcessComplete />
        </TitledContainer>
        <TitledContainer
          title="Now What?"
          className="financial-guidance__now-what"
        >
          <NumberedList listItems={reviewListItems} />
        </TitledContainer>
        <TitledContainer
          title="Follow Us On Social Media?"
          className="financial-guidance__social-media"
        >
          <SocialMedia />
        </TitledContainer>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { selectedFormat, title } = state.applicant.applicant.selectedTrack
  const partnerName = state.partner.name
  return { selectedFormat, selectedTrackTitle: title, partnerName }
}

const PartnerReviewContainer = connect(
  mapStateToProps,
  actions
)(withTopView(PartnerReview))

export default PartnerReviewContainer
