import React, { FunctionComponent } from "react"
import Popup from "reactjs-popup"

import "reactjs-popup/dist/index.css"

export interface FormPopupProps {
  content: JSX.Element
  trigger?: JSX.Element
  width?: number
  height?: number
}

export const FormPopup: FunctionComponent<FormPopupProps> = (props) => {
  const contentStyle = {
    padding: 28,
    border: "none",
    minWidth: props.width || 100,
    minHeight: props.height || 100,
    width: 180,
    fontSize: 14,
    color: "#000",
    boxShadow: "0 2px 14px 0 rgba(0,0,0,0.2)",
  }
  if (window.screen.width < 768) {
    return (
      <Popup
        trigger={props.trigger}
        on="click"
        modal={true}
        arrow={false}
        contentStyle={{
          ...contentStyle,
          borderLeft: "5px solid #01CB79",
        }}
      >
        {props.content}
      </Popup>
    )
  } else {
    return (
      <Popup
        trigger={props.trigger}
        position="right center"
        on="hover"
        offsetX={10}
        contentStyle={{
          ...contentStyle,
          borderRight: "5px solid #01CB79",
        }}
      >
        {props.content}
      </Popup>
    )
  }
}
