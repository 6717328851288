import { CLEAR_POPUP_MESSAGE, SET_POPUP_MESSAGE } from "@src/actions/types"

interface SetPopUpMessageAction {
  type: "SET_POPUP_MESSAGE"
  payload: string
}

interface ClearPopUpMessageAction {
  type: "CLEAR_POPUP_MESSAGE"
}

type Action = SetPopUpMessageAction | ClearPopUpMessageAction

export interface PopupManagerState {
  message?: string
}

const INITIAL_STATE: PopupManagerState = {
  message: undefined,
}

export default function (state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case CLEAR_POPUP_MESSAGE: {
      return { message: undefined }
    }
    case SET_POPUP_MESSAGE: {
      return { message: action.payload }
    }
    default:
      return state
  }
}
