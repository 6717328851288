import React from "react"

import ApplicantInformation from "@components/applicationForm/applicantInformation"
import CommitmentAssessment from "@components/commitmentAssessment/commitmentAssessment"
import DocumentCollection from "@components/documentCollection/documentCollection"
import FinancialGuidance from "@components/financialGuidance/financialGuidance"
import Payment from "@components/payment"
import PartnerReview from "@components/review/partnerReview"
import Review from "@components/review/review"
import ScheduleOrientation from "@components/partTwo/scheduleOrientation/scheduleOrientation"
import SignAgreement from "@components/partTwo/signAgreement/signAgreement"
import WelcomeToBottega from "@components/partTwo/welcomeToBottega/welcomeToBottega"

import { Partner } from "@common/partner"

export const pages: { [key in string]: Page } = {
  ApplicantInformation: {
    title: "Application Information",
    component: <ApplicantInformation />,
  },
  ApprovalAndAgreements: {
    title: "Approval & Agreements",
    stepTitle: "Secure Financing",
  },
  CommitmentAssessment: {
    title: "Commitment Assessment",
    component: <CommitmentAssessment />,
  },
  DocumentCollection: {
    title: "Document Collection",
    component: <DocumentCollection />,
  },
  FinancialGuidance: {
    title: "Financial Guidance",
    component: <FinancialGuidance />,
  },
  PartnerApprovalAndAgreements: {
    title: "Approval & Agreements",
    stepTitle: "Advisor Assignment",
  },
  PartnerReview: {
    title: "Review",
    component: <PartnerReview />,
  },
  Payment: {
    title: "Payment",
    component: <Payment />,
  },
  Review: {
    title: "Review",
    component: <Review />,
  },
  ScheduleOrientation: {
    title: "Schedule Orientation",
    component: <ScheduleOrientation />,
  },
  SignAgreement: {
    title: "Sign Agreement",
    component: <SignAgreement />,
  },
  WelcomeToBottega: {
    title: "Welcome To Bottega",
    component: <WelcomeToBottega />,
  },
}

export type Page = ComponentPage | PseudoPage

export interface ComponentPage {
  title: string
  component: React.ReactNode
}

export interface PseudoPage {
  title: string
  stepTitle: string
}

export function enrollmentSteps(
  partner: Partner | null,
  isPartTwo: boolean
): Page[] {
  if (partner === null) {
    return []
  } else if (isPartTwo && partner.name === "mobile") {
    return [
      pages.Payment,
      pages.SignAgreement,
      pages.ScheduleOrientation,
      pages.WelcomeToBottega,
    ]
  } else if (isPartTwo) {
    return [
      pages.SignAgreement,
      pages.ScheduleOrientation,
      pages.WelcomeToBottega,
    ]
  } else if (partner.mexico) {
    return [
      pages.ApplicantInformation,
      pages.DocumentCollection,
      pages.CommitmentAssessment,
      pages.PartnerReview,
      pages.PartnerApprovalAndAgreements,
    ]
  } else {
    return [
      pages.ApplicantInformation,
      pages.DocumentCollection,
      pages.CommitmentAssessment,
      pages.FinancialGuidance,
      pages.Review,
      pages.ApprovalAndAgreements,
    ]
  }
}
