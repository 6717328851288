import React from "react"
import { connect } from "react-redux"

import Popup from "@components/helpers/popup"
import { clearPopupMessage } from "@src/actions"
import { State } from "@src/reducers"

interface Props {
  message?: string
  clearPopupMessage: typeof clearPopupMessage
}

const PopupManager = (props: Props) => (
  <Popup
    isVisible={props.message != undefined}
    message={props.message!}
    onYes={props.clearPopupMessage}
    yesLabel="OK"
  />
)

const mapStateToProps = (state: State) => state.popupManager

export default connect(mapStateToProps, { clearPopupMessage })(PopupManager)
