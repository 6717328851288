import { Dispatch } from "redux"

import { Action } from "../reducers/requestsLoaderReducer"
import { INCREMENT_REQUESTS_COUNTER, DECREMENT_REQUESTS_COUNTER } from "./types"

export function incrementRequestsCounter(dispatch: Dispatch<Action>) {
  dispatch({ type: INCREMENT_REQUESTS_COUNTER })
}

export function decrementRequestsCounter(dispatch: Dispatch<Action>) {
  dispatch({ type: DECREMENT_REQUESTS_COUNTER })
}
