import { Field } from "redux-form"
import React from "react"
import { FormDrop, FormSelectList, FormInput } from "@components/formFields"

const yesNo = ["Yes", "No"]

export const DefaultedLoanField = () => (
  <Field
    name="defaulted"
    className="financial-guidance-form__defaulted"
    title="Have you previously defaulted on a loan?"
    data={yesNo}
    component={FormSelectList}
    popup={{
      content:
        "To default on a loan means that the debtor (you) were unable to meet the legal obligation of debt repayment on a line of credit.",
    }}
  />
)

export const CosignerField = () => (
  <Field
    name="cosigner"
    className="financial-guidance-form__cosigner"
    title="Do you have a cosigner available if needed for a loan?"
    data={yesNo}
    component={FormSelectList}
    popup={{
      content:
        "A cosigner is someone who signs cooperatively with a primary borrower to aid in securing a loan and serves as an additional repayment source.",
    }}
  />
)

export const IncomeSharingAgreementField = () => (
  <Field
    name="incomeSharingAgreement"
    className="financial-guidance-form__isa"
    title="Do you currently have an Income Sharing Agreement (ISA) that you are contributing to?"
    data={yesNo}
    component={FormSelectList}
    popup={{
      content:
        "An ISA is a financial structure in which an organization provides something of value to a recipient who, in exchange, agrees to pay back a percentage of his/her income for a fixed number of years.",
    }}
  />
)

export const CurrentEmploymentStatusField = () => (
  <Field
    name="currentEmploymentStatus"
    className="financial-guidance-form__employment"
    title="Current Employment Status"
    component={FormDrop}
    placeholder="Select Employment Field"
    data={[
      "Employed",
      "Self-Employed",
      "Out Of Work But Looking",
      "Out Of Work And Not Looking",
      "Student And Working",
      "Student And Not Working",
      "Retired",
      "Unable To Work",
    ]}
  />
)

export const IndustryEmploymentField = () => (
  <Field
    className="financial-guidance-form__industry"
    name="industryEmployment"
    title="Industry of Employment"
    placeholder="Select an Industry"
    component={FormDrop}
    data={[
      "Business consulting and management",
      "Charity and voluntary work",
      "Construction",
      "Creative arts and design",
      "Education",
      "Engineering and manufacturing",
      "Environment and agriculture",
      "Finance",
      "Healthcare & Social care",
      "Information Technology, software, data science, cybersecurity",
      "Law",
      "Law enforcement and security",
      "Marketing, advertising and PR",
      "Media and internet",
      "Public services and administration",
      "Recruitment and HR",
      "Retail & Sales",
      "Science and Pharmaceuticals",
      "Transport and logistics",
      "Other",
    ]}
  />
)

export const IncomeField = () => (
  <Field
    className="financial-guidance-form__income"
    name="income"
    type="number"
    title="Current Income"
    placeholder="Total Income"
    component={FormInput}
    popup={{
      content: (
        <div>
          Bottega does not view, store, or sell your financial information for
          profit.
          <br />
          <br />
          Its sole purpose is to allow our financing algorithms to align you
          with the most relevant financial options for your current
          circumstance.
        </div>
      ),
    }}
  />
)
