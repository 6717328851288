import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import EnrollmentAgreement from "@src/agreement/enrollment/web"
import withTopView from "@src/components/topView"
import { FormButton } from "@components/formFields"
import { State } from "@src/reducers"
import { nextPage } from "@src/actions"
import { popupError } from "@src/actions/popupManager"
import { requiredInformationSet, Applicant } from "@common/applicant"

interface Props {
  applicant: Applicant
  nextPage: () => void
  popupError: typeof popupError
  signed: Boolean
}

const SignAgreement: React.FunctionComponent<Props> = (props) => {
  if (!requiredInformationSet(props.applicant)) {
    return (
      <div>
        <div className="error-reporter__error">
          Please{" "}
          <Link to="/" reloadDocument>
            fill in the missing information
          </Link>{" "}
          in previous steps.
        </div>
      </div>
    )
  } else if (!props.applicant.applicantInfo.socialSecurityNumber) {
    return (
      <div>
        <div className="error-reporter__error">
          Please{" "}
          <Link to="/" reloadDocument>
            fill in the Social Security Number
          </Link>{" "}
          in the Application Information step.
        </div>
      </div>
    )
  } else {
    return (
      <div className="sign-agreement layout__content">
        <EnrollmentAgreement />
        <FormButton
          className="sign-agreement__continue"
          title="Continue"
          onClick={() => {
            props.signed
              ? props.nextPage()
              : props.popupError(
                  "Please sign the application agreement on page 19 of the document before continuing."
                )
          }}
        />
      </div>
    )
  }
}

function mapStateToProps(state: State) {
  const applicant = state.applicant.applicant as Applicant
  const signed = applicant.enrollmentAgreementSignature.timestamp !== null
  return { applicant, signed }
}

const SignAgreementContainer = connect(mapStateToProps, {
  popupError,
  nextPage,
})(withTopView(SignAgreement))

export default SignAgreementContainer
