import {
  SET_PAGES,
  SET_PAGE,
  NEXT_PAGE,
  PREVIOUS_PAGE,
} from "@src/actions/types"
import { Page } from "@src/enrollmentSteps"

interface SetPagesAction {
  type: "SET_PAGES"
  payload: Page[]
}

interface SetPageAction {
  type: "SET_PAGE"
  payload: number
}

interface NextPageAction {
  type: "NEXT_PAGE"
}

interface PreviousPageAction {
  type: "PREVIOUS_PAGE"
}

export type Action =
  | SetPagesAction
  | SetPageAction
  | NextPageAction
  | PreviousPageAction

const INITIAL_STATE = {
  pages: [],
  renderPageAtIndex: 0,
}

export default function (state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case SET_PAGES:
      return {
        ...state,
        pages: action.payload,
      }
    case SET_PAGE:
      return {
        ...state,
        renderPageAtIndex: action.payload,
      }
    case NEXT_PAGE:
      return {
        ...state,
        renderPageAtIndex: state.renderPageAtIndex + 1,
      }
    case PREVIOUS_PAGE:
      return {
        ...state,
        renderPageAtIndex:
          state.renderPageAtIndex > 0 ? state.renderPageAtIndex - 1 : 0,
      }
    default:
      return state
  }
}
