import React, { Component } from "react"

class BrandedHeader extends Component {
  render() {
    const { className } = this.props
    return (
      <div className={`${className} branded-header`}>
        <img
          className="branded-header__logo"
          src="/assets/bottega-white-logo.png"
        />
      </div>
    )
  }
}

export default BrandedHeader
