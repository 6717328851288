import React, { Component, Fragment } from "react"
import classNames from "classnames"
import moment from "moment"

import { DateFormat } from "@src/helpers"
import { FormPopup } from "@components/formFields"
import { FormPopupProps } from "@components/formFields/formPopup/formPopup"
import {
  SelectedTrackOption,
  SelectedTrackFormat,
} from "@components/applicationForm/trackSelection/trackOptions"
import { setSelectedTrackFormat, setSelectedTrackStartDate } from "@src/actions"

interface Option<OptionKey> {
  key: OptionKey
  value: string
}

interface OptionBoxProps<OptionKey> {
  className: string
  itemClassName?: string
  title: string
  popup?: FormPopupProps
  options: Option<OptionKey>[]
  onClick: (option: OptionKey) => void
  selectedOption: OptionKey | undefined
}

function OptionBox<OptionKey extends string | number>({
  className,
  itemClassName,
  title,
  popup = undefined,
  options,
  onClick,
  selectedOption,
}: OptionBoxProps<OptionKey>): JSX.Element {
  if (options.length > 0) {
    return (
      <div className={`${className} option-box`}>
        <div className="option-box__header">
          <div className="option-box__header__title">{title}</div>
          {popup && (
            <FormPopup
              width={popup.width}
              height={popup.height}
              trigger={
                <div className="option-box__header__hover-icon">
                  <i className="far fa-question-circle" />
                </div>
              }
              content={popup.content}
            />
          )}{" "}
        </div>
        <div className="option-box__options">
          {options.map((option) => {
            const key = option.key
            const value = option.value
            const classname = classNames("option-box-option", itemClassName, {
              "option-box-option__selected": option.key === selectedOption,
            })
            return (
              <div
                key={key.toString()}
                onClick={() => onClick(key)}
                className={classname}
              >
                {value}
              </div>
            )
          })}
        </div>
      </div>
    )
  } else {
    return <div />
  }
}

export interface TrackSelectedOptionsProps {
  className: string
  selectedTrack: SelectedTrackOption
  setSelectedTrackFormat: typeof setSelectedTrackFormat
  setSelectedTrackStartDate: typeof setSelectedTrackStartDate
  trackStartDateRequired: boolean
}

class TrackSelectedOptions extends Component<TrackSelectedOptionsProps> {
  selectFormat = (title: string) => {
    const formats = this.props.selectedTrack.formats
    const format: SelectedTrackFormat | undefined = formats.find(
      (format) => format.title === title
    )

    this.props.setSelectedTrackFormat(format)
  }

  render() {
    const { formats, selectedFormat } = this.props.selectedTrack
    const formatOptions = formats.map((format) => {
      return {
        key: format.title,
        value: format.title,
      }
    })

    const availableDates = selectedFormat.dates
      .map((date) => ({
        startDateString: date.startDateString,
        date: moment(date.startDateString),
      }))
      .filter(
        ({ date, startDateString }) =>
          date.isSameOrAfter() ||
          startDateString ===
            this.props.selectedTrack.selectedFormat.selectedStartDate
      )
      .map(({ date, startDateString }) => {
        return {
          key: startDateString,
          value: date.format(DateFormat),
        }
      })

    return (
      <div className={`${this.props.className} track-selected-options`}>
        <OptionBox
          className="track-selected-options__select-format"
          title="Select A Format"
          options={formatOptions}
          onClick={this.selectFormat}
          selectedOption={selectedFormat.title}
          popup={{
            width: 200,
            content: (
              <div className="track-format-popup">
                <p className="track-format-popup__header">Program Formats</p>
                {formats.map((f) => (
                  <Fragment key={f.title}>
                    <p className="track-format-popup__program">{f.title}</p>
                    <p>{f.description}</p>
                  </Fragment>
                ))}
              </div>
            ),
          }}
        />
        <OptionBox
          className="track-selected-options__select-start-date"
          itemClassName={
            this.props.trackStartDateRequired ? "option-box-option__error" : ""
          }
          title="Select A Start Date"
          options={availableDates}
          onClick={this.props.setSelectedTrackStartDate}
          selectedOption={selectedFormat.selectedStartDate}
        />
      </div>
    )
  }
}

export default TrackSelectedOptions
