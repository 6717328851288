import { combineReducers } from "redux"
import { reducer as form } from "redux-form"

import applicant from "./applicantReducer"
import auth from "./authReducer"
import financialGuidance from "./financialGuidanceReducer"
import pageLayout from "./pageLayoutReducer"
import partner from "./partnerReducer"
import popupManager from "./popupManagerReducer"
import requestsLoader from "./requestsLoaderReducer"

import { ApplicantState } from "./applicantReducer"
import { FinancialGuidanceState } from "./financialGuidanceReducer"
import { PopupManagerState } from "./popupManagerReducer"
import { RequestsLoaderState } from "./requestsLoaderReducer"

import { Partner } from "@common/partner"

const rootReducer = combineReducers({
  form,

  auth,
  financialGuidance,

  pageLayout,

  applicant,
  partner,

  popupManager,
  requestsLoader,
})

// Use `{}` as the base for now to avoid adding types to all state's props at once.
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BaseState {}

export interface State extends BaseState {
  applicant: ApplicantState
  financialGuidance: FinancialGuidanceState
  partner: Partner
  popupManager: PopupManagerState
  requestsLoader: RequestsLoaderState
}

export default rootReducer
