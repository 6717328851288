import React from "react"
import { connect } from "react-redux"

import { saveDocuments, saveMexicoDocuments } from "@src/actions"
import {
  DocumentCollectionForm,
  DocumentCollectionFormValues,
  DocumentCollectionValues,
} from "./documentCollectionForm"
import {
  MexicoDocumentCollectionForm,
  MexicoDocumentCollectionFormValues,
  MexicoDocumentCollectionValues,
} from "./mexicoDocumentCollectionForm"
import { documents } from "@src/selectors"
import { omitBy, isNull } from "lodash"
import { State } from "@src/reducers"
import { Partner } from "@common/partner"

type DocumentCollectionProps = {
  initialValues:
    | DocumentCollectionFormValues
    | MexicoDocumentCollectionFormValues
  partner: Partner
  saveDocuments: (values: DocumentCollectionValues) => void
  saveMexicoDocuments: (values: MexicoDocumentCollectionValues) => void
}

const DocumentCollection = (props: DocumentCollectionProps) =>
  props.partner.flags.hasMexicoDocumentCollection ? (
    <div className="document-collection layout__content">
      <MexicoDocumentCollectionForm
        initialValues={
          props.initialValues as MexicoDocumentCollectionFormValues
        }
        onSubmit={props.saveMexicoDocuments}
      />
    </div>
  ) : (
    <div className="document-collection layout__content">
      <DocumentCollectionForm
        initialValues={props.initialValues as DocumentCollectionFormValues}
        onSubmit={props.saveDocuments}
      />
    </div>
  )

export default connect(
  (state: State) => ({
    initialValues: {
      ...omitBy(documents(state), isNull),
    },
    partner: state.partner,
  }),
  { saveDocuments, saveMexicoDocuments }
)(DocumentCollection)
