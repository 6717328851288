import React, { Component } from "react"

class InfoCard extends Component {
  render() {
    const { className, title, content } = this.props
    return (
      <div className={`${className} card-info`}>
        <div className="login-form__info card-info">
          <h1>{title}</h1>
          <p>{content}</p>
        </div>
      </div>
    )
  }
}

export default InfoCard
